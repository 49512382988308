import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1e655122&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=1e655122&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e655122",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,CoreSelectTheSelect: require('/usr/src/nuxt-app/components/core/select/TheSelect.vue').default,ResultsThePaginator: require('/usr/src/nuxt-app/components/results/ThePaginator.vue').default})
