
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { BIconXLg, BIconCheck2 } from "bootstrap-vue";

@Component({ components: { BIconXLg, BIconCheck2 } })
export default class TheRangeModal extends Vue {
  @Prop() modal!: any;
  @Prop({ default: false }) isPrice!: boolean;
  @Prop({ default: false }) unit!: boolean | string;
  @Prop() value!: string[];
  @Prop() options!: number[];

  public optionsMin: Array<any> = [];
  public min: number | null = null;
  public optionsMax: Array<any> = [];
  public max: number | null = null;

  @Watch("options", { immediate: true })
  createRange() {
    const rangeObj = this.options.map((number: number) => {
      return {
        name: number,
        active: false,
        disable: false,
      };
    });
    this.optionsMin = [...rangeObj];
    this.optionsMax = [...rangeObj];

    if (this.value === undefined) return;

    if (this.value?.[0] === ">") {
      this.selectMin(Number(this.value[1]));
    } else if (this.value?.[0] === "<") {
      this.selectMax(Number(this.value[1]));
    } else {
      this.selectMin(Number(this.value[0]));
      this.selectMax(Number(this.value[1]));
    }
  }

  public resetValues() {
    this.$emit("reset");
    this.selectMin(this.getValue(this.optionsMin));
    this.selectMax(this.getValue(this.optionsMax));
  }

  public selectMin(number: number) {
    this.optionsMin = this.changeState(number, this.optionsMin);

    this.min = this.getValue(this.optionsMin);

    if (this.min) {
      this.optionsMax = this.setDisable("<=", number, this.optionsMax);
    } else {
      this.optionsMax = this.resetDisable(this.optionsMax);
    }

    if (this.max) {
      this.optionsMin = this.setDisable(">=", this.max, this.optionsMin);
    }

    this.emitUpdate();
  }

  public selectMax(number: number) {
    this.optionsMax = this.changeState(number, this.optionsMax);

    this.max = this.getValue(this.optionsMax);

    if (this.max) {
      this.optionsMin = this.setDisable(">=", number, this.optionsMin);
    } else {
      this.optionsMin = this.resetDisable(this.optionsMin);
    }

    if (this.min) {
      this.optionsMax = this.setDisable("<=", this.min, this.optionsMax);
    }

    this.emitUpdate();
  }

  public closeModal() {
    this.$emit("close-modal");
  }

  private changeState(number: number, options: any[]) {
    return options.map((obj: any) => {
      if (obj.name !== number) {
        return {
          name: obj.name,
          disabled: false,
          active: false,
        };
      } else {
        return {
          name: obj.name,
          disabled: false,
          active: !obj.active,
        };
      }
    });
  }

  private setDisable(condition: string, number: number, options: any[]) {
    if (condition === ">=") {
      return options.map((obj: any) => {
        return {
          name: obj.name,
          disabled: obj.name >= number,
          active: obj.active,
        };
      });
    } else {
      return options.map((obj: any) => {
        return {
          name: obj.name,
          disabled: obj.name <= number,
          active: obj.active,
        };
      });
    }
  }

  private resetDisable(options: any[]) {
    return options.map((obj: any) => {
      return {
        name: obj.name,
        disabled: false,
        active: obj.active,
      };
    });
  }

  private getValue(options: any[]) {
    return options.find((obj: any) => obj.active)?.name === "undefinied" ? null : options.find((obj: any) => obj.active)?.name;
  }

  private emitUpdate() {
    if (this.options.length === 0) return;

    let value: (string | number)[] = [];

    if (this.min && this.max) {
      value = [this.min, this.max];
    }

    if (this.min && !this.max) {
      value = [">", this.min];
    }

    if (!this.min && this.max) {
      value = ["<", this.max];
    }
    if (value.length) {
      this.$emit("update", value);
    } else {
      this.$emit("reset");
    }
  }
}
