var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 0.6,
    once: true,
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 0.6,\n    once: true,\n  }"}],ref:"swiper",staticClass:"swiper asta-card-swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-lazy image",class:{
          hoverable: !_vm.clickdisabled,
        },attrs:{"data-background":image[1024]},on:{"click":_vm.goToDetail}}),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})])}),_vm._v(" "),(_vm.images.length < 1)?_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-lazy",class:{ hoverable: !_vm.clickdisabled },attrs:{"data-background":"https://library.astagiudiziaria.com/tappi/tappo512.png"},on:{"click":_vm.goToDetail}}),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})]):_vm._e()],2),_vm._v(" "),(_vm.cardWithSwiper)?_c('div',[(_vm.images.length > 1)?_c('div',{staticClass:"swiper-button-prev d-none d-sm-inline",on:{"click":function($event){$event.preventDefault();return _vm.prev.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),(_vm.images.length > 1)?_c('div',{staticClass:"swiper-button-next d-none d-sm-inline",on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"swiper-pagination d-none"}),_vm._v(" "),_c('div',{staticClass:"contatore-immagini"},[_c('b-icon',{attrs:{"icon":"camera-fill","font-scale":"1.1","aria-label":"Back"}}),_vm._v("\n      "+_vm._s(_vm.current + "/" + _vm.images.length)+"\n    ")],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }