import { render, staticRenderFns } from "./pubblica-con-noi.vue?vue&type=template&id=2f350688"
import script from "./pubblica-con-noi.vue?vue&type=script&lang=ts"
export * from "./pubblica-con-noi.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,ElementsWorkWithUsForm: require('/usr/src/nuxt-app/components/elements/WorkWithUsForm.vue').default})
