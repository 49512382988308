import { render, staticRenderFns } from "./TheCorrelated.vue?vue&type=template&id=2a1ad0d0"
import script from "./TheCorrelated.vue?vue&type=script&lang=ts"
export * from "./TheCorrelated.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResultsTheCard: require('/usr/src/nuxt-app/components/results/TheCard.vue').default,CoreErrorsAlertAction: require('/usr/src/nuxt-app/components/core/errors/AlertAction.vue').default})
