const middleware = {}

middleware['default'] = require('../middleware/default.ts')
middleware['default'] = middleware['default'].default || middleware['default']

middleware['populateFilterInStore'] = require('../middleware/populateFilterInStore.ts')
middleware['populateFilterInStore'] = middleware['populateFilterInStore'].default || middleware['populateFilterInStore']

middleware['preselect_category'] = require('../middleware/preselect_category.ts')
middleware['preselect_category'] = middleware['preselect_category'].default || middleware['preselect_category']

middleware['theme'] = require('../middleware/theme.ts')
middleware['theme'] = middleware['theme'].default || middleware['theme']

export default middleware
