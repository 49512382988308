
import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";
import { BIcon, BIconChevronLeft, BIconChevronRight } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconChevronLeft,
    BIconChevronRight,
  },
})
export default class ThePaginator extends Vue {
  @Prop({ default: 25 }) perPage: number;
  @Prop({ default: 0 }) totalRows: number;
  @Prop() currentPage!: number;
  @Prop({ default: false }) showLoading!: boolean;
  @Prop({ default: "Mostra le prossime inserzioni" }) nextText!: string;
  @Prop({ default: "Torna alle precedenti inserzioni" }) prevText!: string;
  @Prop() directGo!: boolean;

  public selectPageIsOpen: boolean = false;
  public pagesValues: Array<any> = [];

  @Watch("totalRows", { immediate: true })
  fillPagesArray(newVal: string[]) {
    this.pagesValues = [];
    for (let index = 0; index < Math.ceil(this.totalRows / this.perPage); index++) {
        this.pagesValues.push({
          value: index + 1,
          alias: "<b class='d-flex justify-content-center'>" + (index + 1) + "</b>",
        });
      }
  }

  public clickEvent(pageNumber: number) {
    this.$emit("change-page", pageNumber);
  }

  public selectedPage(page: any) {
    this.selectPageIsOpen = false;
    this.$emit("change-page", page.value);
  }
}
