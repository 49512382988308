
import { Component, Prop } from "nuxt-property-decorator";
import { BIconCameraVideo } from "bootstrap-vue";
import TheWrapper from "~/components/elements/lightbox/TheWrapper.vue";

@Component({
  components: {
    BIconCameraVideo,
  },
})
export default class TheVideo extends TheWrapper {
  @Prop() videos!: any;

  mounted() {
    this.lightBoxMedia = this.videos.map((id: string) => {
      return {
        type: "youtube",
        thumb: "https://img.youtube.com/vi/" + this.youtubeIdParser(id) + "/hqdefault.jpg",
        id: this.youtubeIdParser(id),
      };
    });
  }

  public youtubeIdParser(url: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : url;
  }
}
