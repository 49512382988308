
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class TheAsta extends Vue {
  @Prop({ default: "mb-1" }) componentClass!: string;
  @Prop() value!: any;
  @Prop({ default: false }) isDate!: any;
  @Prop({ default: "" }) textClass: string;
  @Prop({ default: "text-gray" }) textColor: string;
}
