import { render, staticRenderFns } from "./privacy.vue?vue&type=template&id=2d7e5120"
import script from "./privacy.vue?vue&type=script&lang=ts"
export * from "./privacy.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default})
