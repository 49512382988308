import { Vue, Component } from "nuxt-property-decorator";
//@ts-ignore
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  sameAs,
  helpers,
  minLength, //@ts-ignore
} from "vuelidate/lib/validators";

const atLeastOneIsUpper = helpers.regex("atLeastOneIsUpper", /(?=.*[A-Z])/);
const atLeastOneIsNumeric = helpers.regex("atLeastOneIsNumeric", /(?=.*[0-9])/);
const atLeastOneSpecialChar = helpers.regex("atLeastOneSpecialChar", /(?=.*[-\#\$\.\%\&\@\!\+\=\<\>\*])/);

@Component({})
export default class SignUpValidator extends Vue {
  public errors: Array<string> = [];

  // definisco le regole di validazione
  @Validations()
  validations = {
    form: {
      nome: { required },
      cognome: { required },
      email: { required, email },
      password_reg: {
        required,
        atLeastOneIsUpper,
        atLeastOneIsNumeric,
        atLeastOneSpecialChar,
        minLength: minLength(8),
      },
      password_confirmation_reg: {
        required,
        sameAsPassword: sameAs("password_reg"),
      },
      privacy_accepted: {
        sameAs: sameAs(() => true),
      },
    },
  };
  // rendo input feedback reactive
  public validateState(name: string) {
    //@ts-ignore
    const { $dirty, $error } = this.$v.form[name];
    return $dirty ? !$error : null;
  }
}
