
import { Component, Vue } from "nuxt-property-decorator";
import { BIconPrinter } from "bootstrap-vue";
import axios from "axios";

@Component
export default class ThePrint extends Vue {
  public blob: any = null;

  public async generatePdf() {
    this.$swal({
      html:
        '<div class="d-flex flex-column align-items-center justify-content-center" style="height:270px">' +
        '<img id="spinner" class="img-fluid b-skeleton-animate-throb" style="height:4.5rem" src="https://media.tenor.com/YtAOA9y7VG0AAAAC/loading.gif" />' +
        `<h4 id="stiamo-generando" class="mb-2 mt-3">Stiamo generando la scheda</h4><h4 id="generata" class="d-none mb-3 mt-3">Generazione completata!</h4>` +
        '<p id="pochisecondi" class="mt-1 mb-0"><small>Il pdf sarà pronto fra pochi secondi</small></p>' +
        '<a id="scarica-allegato" target="_blank" class="w-50 d-none mt-3 btn btn-primary btn-lg b-skeleton b-skeleton-button b-skeleton-animate-wave text-white text-decoration-none" href="#" disabled="true">Scarica</a></div>',
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      customClass: {
        confirmButton: "w-100 btn-lg b-skeleton b-skeleton-button b-skeleton-animate-wave",
      },
    });

    // in url trick to test in local
    axios
      .post(
        this.$config.pdfEndpoint,
        {
          url:
            process.env.ENV !== "production"
              ? "https://www.ivgmodena.it/inserzioni/motociclo-harley-davidson-1127316"
              : window.location.href,
          apikey: this.$config.pdfToken,
          iubenda: true,
          wait: 0,
          pdf: true,
        },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        //@ts-ignore
        this.blob = new Blob([response.data], { type: response.data.type });
        const url = window.URL.createObjectURL(this.blob);

        // Rimuovo l'elemento spinner
        const spinner = document.getElementById("spinner");
        if (spinner) {
          spinner.remove();
        }

        // Rimuovo l'elemento con id pochi.secondi
        const pochiSecondi = document.getElementById("pochisecondi");
        if (pochiSecondi) {
          pochiSecondi.remove();
        }

        // Setto la classe d-none sull'id stiamo generando e la tolgo dall'id generata
        const stiamoGenerando = document.getElementById("stiamo-generando");
        const generata = document.getElementById("generata");
        if (stiamoGenerando && generata) {
          stiamoGenerando.classList.add("d-none");
          generata.classList.remove("d-none");
        }

        // Setto la url nell'eklemento con id scarica-allegato
        const a = document.getElementById("scarica-allegato");
        if (a) {
          a.classList.remove("d-none");
          a.setAttribute("href", url);
          a.addEventListener("click", () => {
            this.$swal.close();
          });
          a.classList.remove("b-skeleton", "b-skeleton-button", "b-skeleton-animate-wave");
          a.classList.add("hithere");
          a.removeAttribute("disabled");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
