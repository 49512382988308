
import { Component, Vue } from "nuxt-property-decorator";

@Component
export default class TheWrapper extends Vue {
  public lightBoxMedia: any = [];
  public toggleLightBoxMedia: boolean = false;

  public openLightBoxMedia() {
    this.toggleLightBoxMedia = true;
  }
  public onLightboxClosed() {
    this.toggleLightBoxMedia = false;
  }
}
