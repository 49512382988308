import { render, staticRenderFns } from "./TheSwiper.vue?vue&type=template&id=1ad97f48&scoped=true"
import script from "./TheSwiper.vue?vue&type=script&lang=ts"
export * from "./TheSwiper.vue?vue&type=script&lang=ts"
import style0 from "./TheSwiper.vue?vue&type=style&index=0&id=1ad97f48&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ad97f48",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResultsCardButtonShareModal: require('/usr/src/nuxt-app/components/results/card/ButtonShareModal.vue').default,ResultsCardButtonFavourite: require('/usr/src/nuxt-app/components/results/card/ButtonFavourite.vue').default})
