
import { Component, Vue, Prop, PropSync } from "nuxt-property-decorator";

@Component
export default class TheMap extends Vue {
  @PropSync('modalShow', { type: Boolean }) syncedName!: Boolean;
  @Prop() src!: string;

  public modalCloseFn = (e: any) => {
      if (e.target.classList.contains('modal')) {
        this.close()
      }
  };
  
  close() {
    this.$store.commit('modal/closeInserzioneMapModal')
  }
  

  // Intercetto sul body dalla modale ed emetto un evento per chiudere la modale
  mounted() {
    document.body.addEventListener('click', this.modalCloseFn)
  }

  // Distruggo il listener quando la modale viene distrutta
  beforeDestroy() {
    document.body.removeEventListener('click', this.modalCloseFn)
  }

}
