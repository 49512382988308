
import { Component, Prop } from "nuxt-property-decorator";
import FormSignup from "~/types/auth/FormSignup";
import SignUpValidator from "@/helpers/validations/signup";
import { saveConsent } from "@/types/forms/saveConsent";

@Component
export default class SignUp extends SignUpValidator {
  @Prop() componentClass!: string;
  @Prop() privacy!: any;
  public form: FormSignup = new FormSignup();
  public activationEmail: string | null = null;
  public success = false;
  public loading = false;

  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
    }
  }

  async onRegister() {
    this.$v.form.$touch();

    if (this.$v.form.$anyError) {
      return;
    }
    this.loading = true;

    const token = await this.$recaptcha.execute("register");
    const data = { ...this.form, recaptcha: token };
    try {
      await this.$axios.post(this.$config.astaEndpoint + "myAsta/register", data);
      this.activationEmail = data.email;
      this.success = true;
      this.loading = false;
      saveConsent(
        "register-form",
        "register-submit",
        { first_name: "nome", last_name: "cognome", email: "email" },
        { legal_documents: "privacy" },
        [{ identifier: "privacy_policy", version: "15/05/2018" }],
        ["password", "repeat-password"]
      );
    } catch (err: any) {
      console.error(err);
      this.loading = false;
    }
    this.form = new FormSignup();
    this.$v.$reset();
    setTimeout(() => {
      this.$v.$reset();
    }, 500);
  }
}
