
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { Term } from "@/types/components";
import { BIcon, BIconXCircle, BIconPlus } from "bootstrap-vue";

@Component({ components: { BIcon, BIconXCircle, BIconPlus } })
export default class TheCategory extends Vue {
  @Prop() value!: any;
  @Prop() options!: Array<Term>;
  @Prop() componentClass!: string;
  @Prop({ default: false }) isloading: boolean;

  public visibleFilter: boolean = false;
  public optionsTipoProcedura: Array<any> = [];

  @Watch("options")
  onOptionsChanged(val: any) {
    this.optionsTipoProcedura = val?.map((item: any) => {
      return {
        alias: item.value,
        value: item.value + "<b>(" + item.count + ")</b>",
      };
    });
  }

  public openFilter() {
    this.visibleFilter = true;
  }

  public closeFilter() {
    this.visibleFilter = false;
  }

  public clearFilter() {
    this.$emit("set-value", []);
  }

  public selectedTipoProcedura(obj: any) {
    this.$emit("set-value", [obj.alias]);
    this.visibleFilter = false;
  }
}
