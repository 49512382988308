
import { Component, Prop } from "nuxt-property-decorator";
import Extractors from "@/helpers/validations/extractors";

@Component
export default class InputWithValidation extends Extractors {
  @Prop() value!: any;
  @Prop({ default: "text" }) type!: string;
  @Prop() placeholder!: string;
  @Prop() id!: string;
  @Prop() label!: string;
  @Prop() fieldName!: string;
  @Prop({ default: false }) excludeCons!: boolean;
}
