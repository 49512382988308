
import { Vue, Component, Getter } from "nuxt-property-decorator";

@Component({ layout: "empty", middleware: "default" })
export default class NewPasswordPage extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  head() {
    return {
      title: this.siteName + " | Inserisci una nuova password",
    };
  }
}
