import { render, staticRenderFns } from "./TheText.vue?vue&type=template&id=0ff2b60a"
import script from "./TheText.vue?vue&type=script&lang=ts"
export * from "./TheText.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchTheText: require('/usr/src/nuxt-app/components/core/search/TheText.vue').default})
