import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { Oauth2Scheme } from '~auth/runtime'
import { LocalScheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": false,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/myAsta/login",
    "logout": false,
    "home": false,
    "callback": "/myAsta/login"
  },
  "vuex": false,
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/"
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "google",
  "useVuex": false
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // google
  $auth.registerStrategy('google', new Oauth2Scheme($auth, {
  "clientId": "860585956458-g83j7fg4jjbhoco1vth47k38fbjf647k.apps.googleusercontent.com",
  "responseType": "token id_token",
  "grantType": "authorization_code",
  "state": "UNIQUE_AND_NON_GUESSABLE",
  "codeChallengeMethod": "",
  "responseMode": "",
  "acrValues": "",
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 180000
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "endpoints": {
    "authorization": "https://accounts.google.com/o/oauth2/auth",
    "userInfo": "https://www.googleapis.com/oauth2/v3/userinfo",
    "logout": false
  },
  "name": "google",
  "scope": [
    "openid",
    "profile",
    "email"
  ]
}))

  // facebook
  $auth.registerStrategy('facebook', new Oauth2Scheme($auth, {
  "clientId": "925803991844906",
  "secretId": "925803991844906",
  "codeChallengeMethod": "",
  "responseType": "token",
  "grantType": "authorization_code",
  "state": "UNIQUE_AND_NON_GUESSABLE",
  "responseMode": "",
  "acrValues": "",
  "token": {
    "property": "access_token",
    "type": "Bearer",
    "maxAge": 180000
  },
  "refreshToken": {
    "property": "refresh_token",
    "maxAge": 2592000
  },
  "endpoints": {
    "authorization": "https://facebook.com/v2.17/dialog/oauth",
    "userInfo": "https://graph.facebook.com/v6.0/me?fields=id,name,picture{url}",
    "logout": false
  },
  "name": "facebook",
  "scope": [
    "public_profile",
    "email"
  ]
}))

  // myAsta
  $auth.registerStrategy('myAsta', new LocalScheme($auth, {
  "useVuex": false,
  "vuex": false,
  "token": {
    "property": "access_token",
    "global": false,
    "type": "Bearer",
    "name": "UserAuthorization"
  },
  "user": {
    "property": "data",
    "autoFetch": true
  },
  "endpoints": {
    "login": {
      "url": "https://core-v3.astagiudiziaria.com/api/v1/front/login",
      "method": "post"
    },
    "logout": false,
    "user": {
      "url": "https://core-v3.astagiudiziaria.com/api/v1/front/myAsta/user",
      "method": "get"
    }
  },
  "name": "myAsta"
}))

  // reserved
  $auth.registerStrategy('reserved', new LocalScheme($auth, {
  "token": {
    "property": "access_token",
    "global": false,
    "type": "Bearer",
    "name": "UserAuthorization"
  },
  "user": {
    "property": "data",
    "autoFetch": true
  },
  "endpoints": {
    "login": {
      "url": "https://core-v3.astagiudiziaria.com/api/v1/front/login",
      "method": "post"
    },
    "logout": false,
    "user": {
      "url": "https://core-v3.astagiudiziaria.com/api/v1/front/reserved/user",
      "method": "get"
    }
  },
  "name": "reserved"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}
