import VueSweetalert2 from "vue-sweetalert2";
import Vue from "vue";
Vue.use(VueSweetalert2);
import "sweetalert2/dist/sweetalert2.min.css";

export default function ({ $axios, app, route, redirect, store, req, res }) {
  $axios.onRequest((config) => {
    if (process.client) {
      config.headers["Authorization"] = "Bearer " + store.getters["tenant/currentToken"];
    }

    if (app.$auth.loggedIn && app.$auth.strategy.token.get()) {
      config.headers["UserAuthorization"] = app.$auth.strategy.token.get();
    }
  });

  $axios.onError((error) => {
    const code = parseInt(error.response && error.response.status);

    if (code === 301 && error?.response?.data?.data?.redirectsTo) {
      redirect(error.response.data.data.redirectsTo);
    }

    if (process.client && !error.config.url.includes("typesense")) {
      if (code === 422) {
        const { data } = error.response;
        const { errors } = data;

        // risposta in inglese
        let msg = `<p class="mb-0">${data.message}</p>`;

        // risposta in italiano
        if (errors) {
          msg = "";
          for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
              const element = errors[key];
              msg += `<p class="mb-0">${element[0]}</p>`;
            }
          }
        }

        Vue.swal({
          html:
            '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4"src="https://library.astagiudiziaria.com/front/icons/warning.svg" width="60">' +
            `<h4 class="mb-2">Errore nell'invio della form</h4>` +
            `<p class="mt-1 mb-0"><small>` +
            msg +
            "</small></p></div>",
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
        return;
      }

      if (code > 499) {
        Vue.swal({
          html:
            '<img class="mt-3" src="https://library.astagiudiziaria.com/front/icons/face.svg" width="100">' +
            '<h4 class="mt-4">Ci sono stati dei problemi...</h4>' +
            "<small>" +
            (error.message ? error.message : "Controlla la tua connessione internet e riprova...") +
            "</small>",
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: false,
          confirmButtonText: "Ho capito...",
          cancelButtonText: "Chiudi",
        });
        return;
      }
    }

    throw error;
  });
}
