
import { Component, Vue, Prop } from "nuxt-property-decorator";
import copy from "copy-to-clipboard";
import {
  BIcon,
  BIconShare,
  BIconEnvelope,
  BIconFacebook,
  BIconLinkedin,
  BIconMessenger,
  BIconSkype,
  BIconTelegram,
  BIconTwitter,
  BIconWhatsapp,
  BIconFiles,
} from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconShare,
    BIconEnvelope,
    BIconFacebook,
    BIconLinkedin,
    BIconMessenger,
    BIconSkype,
    BIconTelegram,
    BIconTwitter,
    BIconWhatsapp,
    BIconFiles,
  },
})
export default class ModalShare extends Vue {
  @Prop() data: any;

  public networks = [
    {
      network: "facebook",
      name: "Facebook",
      icon: "facebook",
      color: "#1877F2",
    },

    {
      network: "linkedin",
      name: "LinkedIn",
      icon: "linkedin",
      color: "#0077B5",
    },
    {
      network: "telegram",
      name: "Telegram",
      icon: "telegram",
      color: "#229ED9",
    },

    {
      network: "twitter",
      name: "Twitter",
      icon: "twitter",
      color: "#1DA1F2",
    },
    {
      network: "whatsapp",
      name: "Whatsapp",
      icon: "whatsapp",
      color: "#25D366",
    },
  ];

  public copyToClipboard(event: string) {
    copy(event);
    setTimeout(() => {
      this.$root.$emit("bv::hide::tooltip");
    }, 1500);
  }
}
