
import { Vue, Component, Getter } from "nuxt-property-decorator";
import Platform from "@/types/domains/platform";

@Component({ layout: "empty", middleware: "default" })
export default class LoginPage extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  @Getter("tenant/currentDomain") domain: string;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/seo") seo: any;
  @Getter("tenant/privacy") privacy: any;

  public justActivated: boolean = false;

  public canonical(): string {
    return new Platform(this.domain).getDomain() + "/myAsta/login";
  }

  mounted() {
    if (this.$route?.query?.status === "activated") {
      this.justActivated = true;
    }
  }

  public redirect(): void {
    this.$router.push({ path: "/myAsta" });
  }

  head() {
    return {
      title: this.seo?.myasta?.title || "Login - " + this.siteName,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo?.myasta?.description || "Accedi al tuo account myAsta per gestire le tue aste e i tuoi preferiti",
        },

        { hid: "og:title", property: "og:title", content: this.seo?.myasta?.title || "Login - " + this.siteName },
        {
          hid: "og:description",
          property: "og:description",
          content: this.seo?.myasta?.description || "Accedi al tuo account myAsta per gestire le tue aste e i tuoi preferiti",
        },
        { hid: "og:url", property: "og:url", content: this.canonical() },
      ],
      link: [{ rel: "canonical", href: this.canonical() }],
    };
  }
}
