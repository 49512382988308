
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconPlusLg, BIconImages, BIconFullscreen, BIconPlayBtnFill } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconPlusLg,
    BIconImages,
    BIconFullscreen,
    BIconPlayBtnFill,
  },
})
export default class ImageWrapper extends Vue {
  @Prop() isLightboxInitiator: boolean;
  @Prop() gallery!: Array<string>;
  @Prop() componentClass!: string;
  @Prop({ default: "" }) buttonClass: string;
  @Prop({ default: 0 }) index: number;
  @Prop() aspect!: string;

  boxedHasBeenClicked(index: number) {
    this.$emit("toggle-gallery-modal", index);
  }
}
