var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-3"},[(_vm.item.main.terminePresentazioneOfferte.value)?_c('ShowAstaLabelValue',{attrs:{"value":{
      label: _vm.item.main.terminePresentazioneOfferte.label.toUpperCase(),
      item: _vm.item.main.terminePresentazioneOfferte.value,
    }}}):_vm._e(),_vm._v(" "),_c('ShowAstaLabelValue',{attrs:{"value":{
      label: 'TIPO DI VENDITA',
      item: _vm.item.tipologiaVendita,
    }}}),_vm._v(" "),(_vm.item.main?.tipoProcedura)?_c('ShowAstaLabelValue',{attrs:{"value":{
      label: 'TIPO PROCEDURA',
      item: _vm.item.main?.tipoProcedura,
    }}}):_vm._e(),_vm._v(" "),(_vm.item.main.numeroProcedura)?_c('ShowAstaLabelValue',{attrs:{"value":{
      label: 'NUMERO PROCEDURA',
      item: _vm.item.main.numeroProcedura,
    }}}):_vm._e(),_vm._v(" "),(_vm.item.ivgNumber)?_c('ShowAstaLabelValue',{attrs:{"value":{
      label: 'NUMERO IVG',
      item: _vm.item.ivgNumber,
    }}}):_vm._e(),_vm._v(" "),(_vm.item.idInserzioneEspVendita)?_c('ShowAstaLabelValue',{attrs:{"value":{
      label: 'IDENTIFICATIVO PVP',
      item: _vm.item.idInserzioneEspVendita,
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }