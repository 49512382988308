import { render, staticRenderFns } from "./TheGallery.vue?vue&type=template&id=1ba0279c&scoped=true"
import script from "./TheGallery.vue?vue&type=script&lang=ts"
export * from "./TheGallery.vue?vue&type=script&lang=ts"
import style0 from "./TheGallery.vue?vue&type=style&index=0&id=1ba0279c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ba0279c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowGalleryFiveAndMore: require('/usr/src/nuxt-app/components/show/gallery/FiveAndMore.vue').default,ShowGalleryOnlyThree: require('/usr/src/nuxt-app/components/show/gallery/OnlyThree.vue').default,ShowGalleryOnlyTwo: require('/usr/src/nuxt-app/components/show/gallery/OnlyTwo.vue').default,ShowGalleryOnlyOne: require('/usr/src/nuxt-app/components/show/gallery/OnlyOne.vue').default,ShowTheMedia: require('/usr/src/nuxt-app/components/show/TheMedia.vue').default,ElementsMyLightBox: require('/usr/src/nuxt-app/components/elements/MyLightBox.vue').default})
