
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";
import {
  BIcon,
  BIconColumns,
  BIconMegaphone,
  BIconShieldCheck,
  BIconShieldExclamation,
  BIconRulers,
  BIconFileEarmarkPdf,
  BIconDownload,
} from "bootstrap-vue";
import axios from "axios";

@Component({
  components: {
    BIcon,
    BIconColumns,
    BIconMegaphone,
    BIconShieldCheck,
    BIconShieldExclamation,
    BIconRulers,
    BIconFileEarmarkPdf,
    BIconDownload,
  },
})
export default class ModalitaPartecipazione extends Vue {
  @Getter("tenant/currentToken") token: string;

  @Prop() item!: any;
  @Prop() id!: number | string;

  doesTipoStartsWith(tipo: string, start: string) {
    return tipo.startsWith(start);
  }
}
