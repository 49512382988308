var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 5,
    once: true,
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 5,\n    once: true,\n  }"}],ref:"swiper",staticClass:"swiper asta-card-swiper",class:{ 'is-card': _vm.iscard, 'is-list': !_vm.iscard }},[(_vm.showCalendar)?[_c('div',{staticClass:"position-absolute calendar-icon-wrapper"},[_c('b-icon-calendar3-fill',{class:'text-' + _vm.item.genre.toLowerCase(),attrs:{"font-scale":"2.75"}})],1),_vm._v(" "),_c('span',{staticClass:"position-absolute calendar-day-wrapper"},[_vm._v("\n      "+_vm._s(_vm._f("toDay")(_vm.showCalendar))+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"position-absolute calendar-month-wrapper"},[_vm._v("\n      "+_vm._s(_vm._f("toMonth")(_vm.showCalendar))+"\n    ")])]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"swiper-wrapper"},[_vm._l((_vm.images),function(image){return _c('div',{key:image,staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-lazy",class:{
          hoverable: !_vm.clickdisabled,
          'rounded-xl-tl rounded-xl-tr': _vm.iscard,
          'rounded-xl-tl rounded-xl-bl': !_vm.iscard,
        },attrs:{"data-background":image},on:{"click":_vm.goToDetail}}),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})])}),_vm._v(" "),(_vm.images.length < 1)?_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-lazy",class:{ hoverable: !_vm.clickdisabled, 'rounded-xl-tl rounded-xl-tr': _vm.iscard, 'rounded-xl-tl rounded-xl-bl': !_vm.iscard },attrs:{"data-background":"https://library.astagiudiziaria.com/tappi/tappo.png"},on:{"click":_vm.goToDetail}}),_vm._v(" "),_c('div',{staticClass:"swiper-lazy-preloader"})]):_vm._e()],2),_vm._v(" "),(_vm.cardWithSwiper)?_c('div',[(_vm.images.length > 1)?_c('div',{staticClass:"swiper-button-prev d-none d-sm-inline",on:{"click":function($event){$event.preventDefault();return _vm.prev.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),(_vm.images.length > 1)?_c('div',{staticClass:"swiper-button-next d-none d-sm-inline",on:{"click":function($event){$event.preventDefault();return _vm.next.apply(null, arguments)}}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"swiper-pagination"}),_vm._v(" "),(_vm.images.length)?_c('div',{staticClass:"contatore-immagini"},[_c('b-icon',{attrs:{"icon":"camera-fill","font-scale":"1.1","aria-label":"Back"}}),_vm._v("\n      "+_vm._s(_vm.current + "/" + _vm.images.length)+"\n    ")],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"action-wrapper"},[_c('ResultsCardButtonShareModal',{attrs:{"item":_vm.item,"title":_vm.item.title,"id":_vm.item.id},on:{"toggle-share-modal":function($event){return _vm.$emit('toggle-share-modal', $event)}}}),_vm._v(" "),_c('ResultsCardButtonFavourite',{attrs:{"id":_vm.item.id,"favourite":_vm.item?.favourite},on:{"toggle-favourite":function($event){return _vm.$emit('toggle-favourite', $event)},"toggle-login-modal":function($event){return _vm.$emit('toggle-login-modal', $event)}}})],1)]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }