import AnyPage from "~/pages/statics/any.vue";
import GuidaPage from "~/pages/statics/guida.vue";
import ServiziPage from "~/pages/statics/servizi.vue";
import PubblicaConNoi from "~/pages/statics/pubblica-con-noi.vue";
import Privacy from "~/pages/statics/privacy.vue";

export function getPagineStatiche(store, config, ssrContext) {
  return [
    {
      name: "pubblica-con-noi",
      path: "/pubblica-con-noi",
      component: PubblicaConNoi,
      beforeEnter: async (to, from, next) => {
        let host = ssrContext?.req?.headers?.host;
        if (host === undefined) {
          host = window.location.host;
        }
        if (await store.store.dispatch("tenant/getMain", host)) {
          next();
        } else {
          next({ name: to.fullPath });
        }
      },
    },
    {
      name: "servizi",
      path: "/servizi",
      component: ServiziPage,
    },
    {
      name: "guida",
      path: "/guida",
      component: GuidaPage,
    },

    {
      name: "privacy",
      path: "/privacy-policy",
      component: Privacy,
    },

    {
      name: "any",
      path: "/:slug([a-z0-9-]+)",
      component: AnyPage,
    },
  ];
}
