import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "./index";

@Module({
  name: "paginator",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class PaginatorStore extends VuexModule {
  public page: number = 1;
  public last_page_visited: number = 0;
  public rpp: number = 20;

  @Action
  addPage() {
    this.context.commit("addToPage");
    this.context.commit("filter/setPage", this.page, { root: true });
  }

  @Action
  subPage() {
    this.context.commit("subToPage");
    this.context.commit("filter/setPage", this.page, { root: true });
  }

  @Action
  resetPage() {
    this.context.commit("resetToPage");
    this.context.commit("filter/setPage", this.page, { root: true });
  }

  @Action({ commit: "setToPage" })
  initSetPage(value: number) {
    return value;
  }

  @Action
  setPage(value: number) {
    this.context.commit("setToPage", value);
    this.context.commit("filter/setPage", value, { root: true });
  }

  @Mutation
  setPerPage(value: number) {
    this.rpp = value;
  }

  @Action
  changePerPage(value: number) {
    this.context.commit("setPerPage", value);
  }

  @Mutation
  setLastPage(value: number) {
    this.last_page_visited = value;
  }

  @Mutation
  addToPage() {
    this.page++;
  }

  @Mutation
  subToPage() {
    this.page--;
  }

  @Mutation
  resetToPage() {
    this.page = 1;
    this.last_page_visited = 0;
  }

  @Mutation
  setToPage(value: number) {
    this.page = value;
  }

  get resultPerPage(): number {
    return this.rpp;
  }

  get currentPage(): number {
    return parseInt("" + this.page);
  }

  get lastPageVisited(): number {
    return this.last_page_visited;
  }

  get rows(): number {
    return this.context.rootGetters["typesense/total"];
  }
}
