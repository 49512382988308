export default class FormContact {
  iev_id: number = 0;
  IVG_id: number = 0;
  nome: string = "";
  cognome: string = "";
  telefono: string = "";
  email: string = "";
  note: string = "";
  accettazione_privacy?: boolean = false;
  recaptcha?: string = "";
}
