
import { Component } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { Getter } from "vuex-class";
import { HeaderData } from "~/types/components";

interface User {
  id: number;
  nome: string;
  cognome: string;
  email: string;
  newsletter: boolean;
  categories: Array<number>;
  ivg: Array<number>;
}

@Component({
  middleware: ["default"],
  // middleware: ["auth", "default"],
})
export default class MyAsta extends SearchMainComponent {
  @Getter("tenant/headerData") headerData!: HeaderData;

  public user: User = this.$auth.$state?.user;
  public type: string = String(this.$auth.$state.strategy);
  public wishlist: Array<number> = [];

  public head() {
    return {
      title: (String(this.$auth.$state.strategy) === "reserved" ? "Area Riservata | " : "MyAsta | ") + this.headerData.name,
    };
  }

  beforeMount() {
    // if (!this.$auth.$state?.user) {
    //   this.$router.push({ path: "/myAsta/login" });
    // }
  }

  async mounted() {
    if (this.type !== "reserved" && this.$auth.$state?.user) {
      this.wishlist = await this.$store.dispatch("wishlist/getFavouritesFromUserApi");
    }
  }
}
