
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { SummaryFilterLabel, GenreCollection } from "@/types/Filters";
import { Genre } from "@/types/Genre";

@Component
export default class TheFilterSummary extends Vue {
  @Prop() filters!: any;
  @Prop() query!: string;

  public activeFilters: Array<any> = [];
  public summaryFilterLabels: any = new SummaryFilterLabel();
  public genre = {} as Genre | undefined;

  mounted() {
    if (this.filters?.genre?.length > 0) {
      this.genre = new GenreCollection().findGenreFromAlias(this.filters?.genre[0]);
    }
    this.doActiveFilters();
  }

  public doActiveFilters() {
    this.activeFilters = Object.keys(this.filters)
      .map((item: any) => {
        return this.filters[item]?.length > 0
          ? {
              filter: item,
              values: this.summaryFilterLabels.getValue(item, this.filters[item]),
              label: this.summaryFilterLabels.getLabel(item),
            }
          : null;
      })
      .filter((item: any) => item?.label !== undefined);

    // Special per il campo di testo query
    if (this.query) {
      this.activeFilters.push({
        filter: "query",
        values: this.summaryFilterLabels.getValue("query", this.query),
        label: this.summaryFilterLabels.getLabel("query"),
      });
    }
  }
}
