import { render, staticRenderFns } from "./Pvp.vue?vue&type=template&id=70f6542e&scoped=true"
import script from "./Pvp.vue?vue&type=script&lang=ts"
export * from "./Pvp.vue?vue&type=script&lang=ts"
import style0 from "./Pvp.vue?vue&type=style&index=0&id=70f6542e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70f6542e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowTabsDettaglioPvpSingleData: require('/usr/src/nuxt-app/components/show/tabs/dettaglioPvp/SingleData.vue').default,ShowTabsDettaglioPvpGoodsData: require('/usr/src/nuxt-app/components/show/tabs/dettaglioPvp/GoodsData.vue').default})
