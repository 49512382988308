
import { Component, Getter } from "nuxt-property-decorator";

import WorkWithUsValidator from "@/helpers/validations/workwithus";
import FormWorkWithUs from "~/types/forms/FormWorkWithUs";

@Component
export default class WorkWithUsForm extends WorkWithUsValidator {
  @Getter("tenant/privacy") privacy!: any;
  public form: FormWorkWithUs = new FormWorkWithUs();
  public loading: boolean = false;

  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  }

  public async onSubmit() {
    this.loading = true;
    this.$v.form.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const data = this.form;

    try {
      const token = await this.$recaptcha.execute("pubblicaConNoi");
      data.recaptcha = token;
    } catch (error) {
      throw error;
    }

    try {
      await this.$axios.$post(this.$config.astaEndpoint + "forms/pubblicaConNoi", data);
      this.success = true;
      this.resetForm();
    } catch (error: any) {
      console.error(error);
    }
  }

  public resetForm() {
    this.form = new FormWorkWithUs();
    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }
}
