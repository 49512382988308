import { render, staticRenderFns } from "./InformazioniVendita.vue?vue&type=template&id=ac19f318"
import script from "./InformazioniVendita.vue?vue&type=script&lang=ts"
export * from "./InformazioniVendita.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowTabsDettaglioVenditaGroupDatiVendita: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/GroupDatiVendita.vue').default,ShowTabsDettaglioVenditaGroupGeneric: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/GroupGeneric.vue').default,ShowTabsDettaglioVenditaGroupSoggetti: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/GroupSoggetti.vue').default,ShowTabsDettaglioVenditaGroupCondizioniVendita: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/GroupCondizioniVendita.vue').default,ShowTabsDettaglioVenditaDetailClaim: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/DetailClaim.vue').default,ShowTabsDettaglioVenditaLuogoVendita: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/LuogoVendita.vue').default})
