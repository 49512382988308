
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { ResponseIvgChiSiamo, ResponseDropdownIvg, DropdownIvg, DataChiSiamo } from "@/types/responses/chisiamo/ResponseChiSiamoInterface";
import { ClientMap } from "~/types/responses/inserzione/ResponseInserzioneInterface";
import axios from "axios";

@Component
export default class AboutPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/currentToken") token: string;
  @Getter("tenant/sedi") sedi: boolean;
  @Getter("tenant/seo") seo: any;
  @Getter("tenant/clientMap") clientMap: ClientMap;
  @Getter("tenant/completeDomain") domain: string;

  public visibleFilter: boolean = false;
  public data = {} as DataChiSiamo;
  public options: Array<DropdownIvg> = [];
  public selected = {} as DropdownIvg;

  public visibleFilterControparte: boolean = false;
  @Getter("filter/getControparte") optionsControparte!: any;

  public head() {
    return {
      title: this.seo.chisiamo.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.chisiamo.description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.seo.chisiamo.description,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.seo.chisiamo.description,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  async mounted() {
    // prendo la dropdown list in client side
    await this.fetchIvgDropdown();
  }

  async fetch() {
    try {
      const { data } = await axios.get<ResponseIvgChiSiamo>(this.$config.astaEndpoint + "IVG/chi-siamo", {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      this.data = data.data;
    } catch (e) {
      this.$errorHandler(e);
    }
  }

  fetchOnServer() {
    return true;
  }

  async fetchIvgDropdown() {
    try {
      const { data } = await this.$axios.$get<ResponseDropdownIvg>(this.$config.astaEndpoint + "IVG/dropdown-list");
      this.options = data;
    } catch (e) {
      this.$errorHandler(e);
    }
    await this.$store.dispatch("typesense/getControparte");
  }

  goToIvg() {
    if (this.selected.id) {
      window.location.href = window.location.origin + "/ivg?ivg=" + this.selected.id;
    }
  }

  public openFilter() {
    this.visibleFilter = true;
  }

  public closeFilter() {
    this.visibleFilter = false;
  }

  public openFilterControparte() {
    this.visibleFilterControparte = true;
  }

  public closeFilterControparte() {
    this.visibleFilterControparte = false;
  }

  public selectedIvg(value: any) {
    this.selected = value;
    this.goToIvg();
  }

  public URLToTribunal(value: string) {
    return (
      "/ricerca?filter%5Bcontroparte%5D%5B0%5D=" +
      encodeURI(value) +
      "&filter%5Bvisibile_su%5D%5B0%5D=1&status%5D%5B0%5D=In%20vendita&query=&page=1&rpp=20"
    );
  }
}
