import Vue from "vue";
import Router from "vue-router";
import { SearchRoutes } from "./search.js";
import { MyAstaRoutes } from "./myasta.js";
import { NewsRoutes } from "./news.js";
import { BollettiniRoutes } from "./bollettini.js";
import { MainRoutes } from "./main.js";
import { getPagineStatiche } from "./pagine_statiche";

Vue.use(Router);

export function createRouter(ssrContext, createDefaultRouter, routerOptions, config, store) {
  const options = routerOptions ? routerOptions : createDefaultRouter(ssrContext, config).options;

  const router = new Router({
    options,
    mode: "history",
    scrollBehavior(_to, _from, savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (savedPosition) {
            resolve(savedPosition);
          } else {
            resolve({ x: 0, y: 0 });
          }
        }, 200);
      });
    },
    parseQuery: function (q) {
      return require("qs").parse(q, { arrayLimit: 50 });
    },
    stringifyQuery: function (q) {
      var r = require("qs").stringify(q);
      return r ? "?" + r : "";
    },
    routes: [
      ...MainRoutes,
      ...SearchRoutes,
      ...MyAstaRoutes,
      ...NewsRoutes,
      ...BollettiniRoutes,
      ...getPagineStatiche(store, config, ssrContext),
    ],
  });

  return router;
}
