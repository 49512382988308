import { render, staticRenderFns } from "./myAsta.vue?vue&type=template&id=2e8daf34"
import script from "./myAsta.vue?vue&type=script&lang=ts"
export * from "./myAsta.vue?vue&type=script&lang=ts"
import style0 from "./myAsta.vue?vue&type=style&index=0&id=2e8daf34&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,MyastaSavedSearches: require('/usr/src/nuxt-app/components/myasta/SavedSearches.vue').default,MyastaTheWishlist: require('/usr/src/nuxt-app/components/myasta/TheWishlist.vue').default,MyastaTheDocuments: require('/usr/src/nuxt-app/components/myasta/TheDocuments.vue').default})
