
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { Term } from "@/types/components";
import { BIcon, BIconXCircle } from "bootstrap-vue";

@Component({ components: { BIcon, BIconXCircle } })
export default class TheProvince extends Vue {
  @Prop() value!: any;
  @Prop() options!: Array<Term>;
  @Prop() componentClass!: string;
  @Prop({ default: false }) isloading: boolean;

  public visibleFilter: boolean = false;
  public optionsCity: Array<any> = [];

  @Watch("options", { immediate: true })
  onOptionsChanged() {
    this.buildOptions();
  }

  public buildOptions(): void {
    this.optionsCity = this.options?.map((item: any) => {
      return {
        alias: item.value,
        value: item.value.toUpperCase() + "<b>(" + item.count + ")</b>",
      };
    });
  }

  public openFilter() {
    this.visibleFilter = true;
  }

  public closeFilter() {
    this.visibleFilter = false;
  }

  public clearFilter() {
    this.buildOptions();
    this.$emit("set-value", []);
    this.visibleFilter = true;
  }

  public selectedCity(arr: string[]) {
    this.$emit("set-value", arr);
    this.visibleFilter = false;
  }
}
