
import { Component, Prop, Vue, Getter } from "nuxt-property-decorator";

@Component
export default class ThePriceModal extends Vue {
  @Prop() value!: any;
  @Prop() genre!: string;
  @Prop() componentClass!: string;
  @Prop({ default: false }) isloading: boolean;

  @Getter("filter/getPriceStats") priceStats!: any;

  public options: number[] = [];

  public getOptions() {
    this.$store.dispatch("typesense/getRangeOfGenre", this.genre !== "default" ? this.genre : null).then((res: any) => {
      this.options = this.range(50, 0, this.priceStats.max);
    });
  }

  public update(value: (string | number)[]) {
    if (value) {
      this.$emit("set-value", value);
    }
  }

  public reset() {
    this.$emit("reset-price");
  }

  public round(number: number, mode?: string) {
    const n = String(number).length - 1;
    if (mode === "ceil") {
      return Math.ceil(number / Math.pow(10, n)) * Math.pow(10, n);
    }
    return Math.floor(number / Math.pow(10, n)) * Math.pow(10, n);
  }

  private range(times: number, startAt: number, endAt: number): number[] {
    let slice = (endAt - startAt) / times;
    let piece = 0;
    let n = 0;
    const obj: number[] = [];
    for (let x = 1; x <= times; x++) {
      piece += slice;
      n = Math.round(0.001 * Math.pow(x, 3) * piece);
      if (x === times) {
        obj.push(this.round(n, "ceil"));
      } else {
        obj.push(this.round(n));
      }
    }
    return [...new Set(obj)];
  }
}
