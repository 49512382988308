
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { Document } from "@/types/typesense/Document";
import { BIcon, BIconBoxArrowUpRight, BIconLink } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconBoxArrowUpRight,
    BIconLink,
  },
})
export default class TheCard extends Vue {
  @Prop({ default: () => ({} as Document) }) item!: Document;
  @(Prop()!) iscard: boolean;
  @Prop({ default: true }) isAsta!: boolean;
  @Prop({ default: false }) showIcon!: boolean;
  @Prop({ default: false }) showCalendar!: boolean;
  @Prop({ default: true }) cardWithSwiper: boolean;
  @Prop({ default: false }) clickdisabled: boolean;
  @Prop({ default: false }) isMap: boolean;

  public toggleHover(bool: boolean) {
    this.hover = bool;
  }

  public hover: boolean = false;
  public clicked: boolean = false;
  public loading: boolean = false;

  public getImage(item: any) {
    return "background-image: url('" + item.image_link + "');";
  }

  public dhover(bool: boolean) {
    return bool && this.clickdisabled;
  }

  cardClicked(id: string) {
    this.$emit("make-it-active", id);
  }

  public isLoading() {
    this.loading = true;
  }
}
