
import { Component, Prop } from "nuxt-property-decorator";

import SignInValidator from "@/helpers/validations/signin";
import FormLogin from "~/types/auth/FormLogin";
import { BIcon, BIconFacebook, BIconGoogle } from "bootstrap-vue";

@Component({ components: { BIcon, BIconFacebook, BIconGoogle } })
export default class SignIn extends SignInValidator {
  @Prop({ default: false }) public justActivated!: boolean;
  @Prop({ default: null }) payload!: any | null;
  @Prop() componentClass!: string;
  @Prop({ default: false }) hasSignUpLink!: boolean;
  public formLogin: FormLogin = new FormLogin();
  public loginType: string = "myAsta";
  public inError: boolean = false;
  public loading: boolean = false;

  async mounted() {
    this.formLogin = new FormLogin(this.loginType);
    try {
      await this.$recaptcha.init();
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
    }
  }

  public async onSocialLogin(social: string) {
    const url = new URL(window.location.href);
    try {
      this.loading = true;
      await this.$auth.loginWith(social, {
        params: { state: this.payload, redirect_uri: url.origin + "/auth/" + social + "/callback", auth_type: "rerequest" },
      });
    } catch (e: any) {
      console.error(e);
    }
  }

  public async onLogin() {
    this.$v.formLogin.$touch();

    if (this.$v.formLogin.$anyError) {
      return;
    }

    const token = await this.$recaptcha.execute("login");
    const data = { ...this.formLogin, recaptcha: token };

    try {
      this.loading = true;

      await this.$auth.loginWith(this.loginType, { data: data });

      this.$store.dispatch("wishlist/getFavouritesFromAsta");
      this.$emit("logged", true);

      this.loading = false;
    } catch (err: any) {
      this.formLogin = new FormLogin(this.loginType);
      if (err?.response?.status === 401) {
        this.$swal({
          html:
            '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4"src="https://library.astagiudiziaria.com/front/icons/warning.svg" width="60">' +
            `<h4 class="mb-2">Errore nell'invio della form</h4>` +
            `<p class="mt-1 mb-0"><small>` +
            err.response.data.message +
            "</small></p></div>",
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      }
      this.loading = false;

      this.$v.$reset();
    }
  }
}
