import Vue from 'vue';

import {
  AlertPlugin,
  BreadcrumbPlugin,
  ButtonPlugin,
  CardPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormFilePlugin,
  ImagePlugin,
  InputGroupPlugin,
  LayoutPlugin,
  LinkPlugin,
  ListGroupPlugin,
  MediaPlugin,
  ModalPlugin,
  NavbarPlugin,
  OverlayPlugin,
  PaginationPlugin,
  PopoverPlugin,
  ToastPlugin,
  TooltipPlugin,
  VBPopoverPlugin,
  BAspect,
  BFormRadioGroup,
  BFormDatepicker,
  BSidebar,
  BSkeleton,
  BSpinner,
  BSkeletonImg,
  BTab,
  BTabs,
  BTooltip,
  BListGroup,
  BFormSelect,
  BIcon,
  BIconArrowLeftCircle,
  BIconArrowLeftShort,
  BIconPrinter,
  BIconPlusLg,
  BIconArrowRightCircle,
  BIconBackspace,
  BIconBook,
  BIconCameraVideo,
  BIconBuilding,
  BIconDashLg,
  BIconDoorClosed,
  BIconListUl,
  BIconEnvelope,
  BIconChevronUp,
  BIconEye,
  BIconEyeSlash,
  BIconArrowClockwise,
  BIconCaretLeftFill,
  BIconEmojiFrown,
  BIconFacebook,
  BIconFilter,
  BIconGeoFill,
  BIconFullscreen,
  BIconGrid3x3Gap,
  BIconGridFill,
  BIconHddStack,
  BIconHeart,
  BIconHouse,
  BIconImages,
  BIconInfoCircle,
  BIconColumns,
  BIconInstagram,
  BIconLink,
  BIconHeartFill,
  BIconLinkedin,
  BIconList,
  BIconLock,
  BIconMap,
  BIconXCircle,
  BIconStar,
  BIconMessenger,
  BIconPen,
  BIconPerson,
  BIconPlayBtnFill,
  BIconRulers,
  BIconSearch,
  BIconShare,
  BIconSkype,
  BIconSortAlphaDown,
  BIconSortAlphaDownAlt,
  BIconSortDown,
  BIconSortNumericDown,
  BIconBoxArrowUpRight,
  BIconSortNumericDownAlt,
  BIconChevronRight,
  BIconSortNumericUp,
  BIconSortUp,
  BIconStarFill,
  BIconThreeDots,
  BIconTelegram,
  BIconTrash2,
  BIconTwitter,
  BIconWallet2,
  BIconWatch,
  BIconWhatsapp,
  BIconX,
  BIconSortAlphaUp,
  BIconXLg,
  BIconYoutube,
  BIconHouseFill
} from 'bootstrap-vue';

Vue.use(AlertPlugin);
Vue.use(BreadcrumbPlugin);
Vue.use(ButtonPlugin);
Vue.use(CardPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormFilePlugin);
Vue.use(ImagePlugin);
Vue.use(InputGroupPlugin);
Vue.use(LayoutPlugin);
Vue.use(LinkPlugin);
Vue.use(ListGroupPlugin);
Vue.use(MediaPlugin);
Vue.use(ModalPlugin);
Vue.use(NavbarPlugin);
Vue.use(OverlayPlugin);
Vue.use(PaginationPlugin);
Vue.use(PopoverPlugin);
Vue.use(ToastPlugin);
Vue.use(TooltipPlugin);

Vue.use(VBPopoverPlugin);

Vue.component('BAspect', BAspect);
Vue.component('BFormRadioGroup', BFormRadioGroup);
Vue.component('BFormDatepicker', BFormDatepicker);
Vue.component('BSidebar', BSidebar);
Vue.component('BSkeleton', BSkeleton);
Vue.component('BSpinner', BSpinner);
Vue.component('BSkeletonImg', BSkeletonImg);
Vue.component('BTab', BTab);
Vue.component('BTabs', BTabs);
Vue.component('BTooltip', BTooltip);
Vue.component('BListGroup', BListGroup);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BIcon', BIcon);
Vue.component('BIconArrowLeftCircle', BIconArrowLeftCircle);
Vue.component('BIconArrowLeftShort', BIconArrowLeftShort);
Vue.component('BIconPrinter', BIconPrinter);
Vue.component('BIconPlusLg', BIconPlusLg);
Vue.component('BIconArrowRightCircle', BIconArrowRightCircle);
Vue.component('BIconBackspace', BIconBackspace);
Vue.component('BIconBook', BIconBook);
Vue.component('BIconCameraVideo', BIconCameraVideo);
Vue.component('BIconBuilding', BIconBuilding);
Vue.component('BIconDashLg', BIconDashLg);
Vue.component('BIconDoorClosed', BIconDoorClosed);
Vue.component('BIconListUl', BIconListUl);
Vue.component('BIconEnvelope', BIconEnvelope);
Vue.component('BIconChevronUp', BIconChevronUp);
Vue.component('BIconEye', BIconEye);
Vue.component('BIconEyeSlash', BIconEyeSlash);
Vue.component('BIconArrowClockwise', BIconArrowClockwise);
Vue.component('BIconCaretLeftFill', BIconCaretLeftFill);
Vue.component('BIconEmojiFrown', BIconEmojiFrown);
Vue.component('BIconFacebook', BIconFacebook);
Vue.component('BIconFilter', BIconFilter);
Vue.component('BIconGeoFill', BIconGeoFill);
Vue.component('BIconFullscreen', BIconFullscreen);
Vue.component('BIconGrid3x3Gap', BIconGrid3x3Gap);
Vue.component('BIconGridFill', BIconGridFill);
Vue.component('BIconHddStack', BIconHddStack);
Vue.component('BIconHeart', BIconHeart);
Vue.component('BIconHouse', BIconHouse);
Vue.component('BIconImages', BIconImages);
Vue.component('BIconInfoCircle', BIconInfoCircle);
Vue.component('BIconColumns', BIconColumns);
Vue.component('BIconInstagram', BIconInstagram);
Vue.component('BIconLink', BIconLink);
Vue.component('BIconHeartFill', BIconHeartFill);
Vue.component('BIconLinkedin', BIconLinkedin);
Vue.component('BIconList', BIconList);
Vue.component('BIconLock', BIconLock);
Vue.component('BIconMap', BIconMap);
Vue.component('BIconXCircle', BIconXCircle);
Vue.component('BIconStar', BIconStar);
Vue.component('BIconMessenger', BIconMessenger);
Vue.component('BIconPen', BIconPen);
Vue.component('BIconPerson', BIconPerson);
Vue.component('BIconPlayBtnFill', BIconPlayBtnFill);
Vue.component('BIconRulers', BIconRulers);
Vue.component('BIconSearch', BIconSearch);
Vue.component('BIconShare', BIconShare);
Vue.component('BIconSkype', BIconSkype);
Vue.component('BIconSortAlphaDown', BIconSortAlphaDown);
Vue.component('BIconSortAlphaDownAlt', BIconSortAlphaDownAlt);
Vue.component('BIconSortDown', BIconSortDown);
Vue.component('BIconSortNumericDown', BIconSortNumericDown);
Vue.component('BIconBoxArrowUpRight', BIconBoxArrowUpRight);
Vue.component('BIconSortNumericDownAlt', BIconSortNumericDownAlt);
Vue.component('BIconChevronRight', BIconChevronRight);
Vue.component('BIconSortNumericUp', BIconSortNumericUp);
Vue.component('BIconSortUp', BIconSortUp);
Vue.component('BIconStarFill', BIconStarFill);
Vue.component('BIconThreeDots', BIconThreeDots);
Vue.component('BIconTelegram', BIconTelegram);
Vue.component('BIconTrash2', BIconTrash2);
Vue.component('BIconTwitter', BIconTwitter);
Vue.component('BIconWallet2', BIconWallet2);
Vue.component('BIconWatch', BIconWatch);
Vue.component('BIconWhatsapp', BIconWhatsapp);
Vue.component('BIconX', BIconX);
Vue.component('BIconSortAlphaUp', BIconSortAlphaUp);
Vue.component('BIconXLg', BIconXLg);
Vue.component('BIconYoutube', BIconYoutube);
Vue.component('BIconHouseFill', BIconHouseFill);
