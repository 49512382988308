
import { Component, Prop, Vue, Getter, Watch } from "nuxt-property-decorator";
import { BIcon, BIconXLg, BIconCheck2 } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconXLg,
    BIconCheck2,
  },
})
export default class TheSelect extends Vue {
  @Prop({ default: false }) multiple: boolean;
  @Prop({ default: false }) visible: boolean;
  @Prop({ default: true }) closeOnSelect: boolean;
  @Prop({ default: false }) searchable: boolean;
  @Prop() value!: any;
  @Prop({ default: () => [] }) options: Array<any>;
  @Prop({ default: "select-modal" }) id: string;
  @Prop({ default: "Select" }) title: string;
  @Prop({ default: "label" }) label: string;
  @Prop({ default: "trackby" }) trackby: string;
  @Prop({ default: "sm" }) size: string;
  @Prop() maxHeight!: number;

  public selectedValues: string[] = [];

  mounted() {
    if (this.value) {
      this.selectedValues = [...this.value];
    }
  }

  @Watch("visible")
  updateVisibility(val: boolean) {
    this.visible_var = val;
  }

  @Watch("options", { deep: true, immediate: true })
  updateptions(val: Array<any>) {
    this.options_var = val;
  }

  public visible_var: boolean = false;
  public options_var: Array<any> = [];

  public shown() {
    this.options_var = [...this.options];
  }

  public filterOptions(str: string) {
    this.options_var = this.options.filter((item: any) => {
      return item[this.label].toLowerCase().includes(str.toLowerCase());
    });
  }

  public resetValues() {
    this.selectedValues = [];
  }

  public selected(event: string) {
    if (this.closeOnSelect) {
      this.$emit("selected", event);
      this.closeModal();
    } else {
      //@ts-ignore
      if (!this.selectedValues.includes(event[this.trackby])) {
        //@ts-ignore
        this.selectedValues.push(event[this.trackby]);
      } else {
        //@ts-ignore
        this.selectedValues = this.selectedValues.filter((item) => item !== event[this.trackby]);
      }
    }
  }

  public search() {
    if (this.selectedValues.length) {
      this.$emit("selected", this.selectedValues);
    }
    this.closeModal();
  }

  public isActive(item: string) {
    return this.selectedValues.includes(item);
  }

  public closeModal() {
    this.$emit("close-modal");
    if (this.closeOnSelect) {
      this.visible_var = false;
    }
  }
}
