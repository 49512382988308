
import { Component, Prop, Vue, Getter } from "nuxt-property-decorator";
import { BIcon, BIconStar, BIconStarFill } from "bootstrap-vue";
import { LoginModalValue } from "@/types/components";
import qs from "qs";

@Component({
  components: { BIcon, BIconStar, BIconStarFill },
})
export default class TheSaveSearchButton extends Vue {
  @Prop() total!: number;
  @Prop({ default: "link" }) variant: string;
  @Prop({ default: true }) icon: boolean;
  @Prop({ default: "Salva <span class='d-none d-lg-inline'>questa</span> ricerca" }) text: string;
  @Prop() extraClasses!: string;
  @Getter("filter/getSavedSearchStatus") public savedSearch!: boolean;

  public loading: boolean = false;
  public saved: boolean = false;
  public loginModalData: LoginModalValue = {
    model: false,
    title: "Salva la ricerca",
    text: "Per poter salvare la ricerca ci si deve prima loggare",
    icon: "star",
    payload: null,
  };

  public saveSearch() {
    this.loading = true;

    if (!this.$auth.loggedIn) {
      const url = new URL(window.location.href);
      this.loginModalData.model = true;
      this.loginModalData.payload = {
        redirectUri: url.origin + url.pathname + decodeURI(url.search) + "&action=saveSearch",
      };
      return;
    }

    setTimeout(async () => {
      try {
        await this.$store.dispatch("filter/saveSearch");
        this.saved = true;
        this.loading = false;
        this.loginModalData.model = false;

        this.$swal({
          html:
            '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/success.svg" width="60">' +
            `<h4 class="mb-2">Ricerca salvata</h4>` +
            `<p class="mt-1 mb-0"><small>Ora potrai recuperare la tua ricerca dalla pagina myAsta</small></p></div>`,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
          timer: 3000,
        });
      } catch (error: any) {
        this.loading = false;
        this.loginModalData.model = false;
        let errorsMessage = [];
        if (error?.response?.data?.errors) {
          for (const [key, value] of Object.entries(error.response.data.errors)) {
            errorsMessage.push([...[value]]);
          }
        }
        const code = parseInt(error.response && error.response.status);
        if (code !== 422) {
          this.$swal({
            html:
              '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4"src="https://library.astagiudiziaria.com/front/icons/warning.svg" width="60">' +
              `<h4 class="mb-2">Impossibile salvare la ricerca</h4>` +
              `<p class="mt-1 mb-0"><small>` +
              errorsMessage.join(", ") +
              "</small></p></div>",
            showCloseButton: true,
            showCancelButton: false,
            confirmButtonText: "Ok",
            timer: 9000,
          });
        }
      }
    }, 500);
  }
}
