import Vue from "vue";
import * as dayjs from "dayjs";

Vue.filter("toDate", function (value) {
  if (typeof value !== "string") {
    return value;
  }
  return dayjs(value).format("DD/MM/YYYY HH:mm");
});

Vue.filter("toDateNoTime", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  return dayjs(value * 1000).format("DD/MM/YYYY");
});

Vue.filter("toDay", function (value) {
  return dayjs(value * 1000).format("DD");
});

Vue.filter("toMonth", function (value) {
  return itaMonth[dayjs(value * 1000).month()];
  return dayjs(value * 1000).format("DD");
});

const itaMonth = {
  0: "Gen",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "Mag",
  5: "Giu",
  6: "Lug",
  7: "Ago",
  8: "Set",
  9: "Ott",
  10: "Nov",
  11: "Dic",
};
