import { render, staticRenderFns } from "./TheSaveSearchButton.vue?vue&type=template&id=27d679e0"
import script from "./TheSaveSearchButton.vue?vue&type=script&lang=ts"
export * from "./TheSaveSearchButton.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthLoginModal: require('/usr/src/nuxt-app/components/auth/LoginModal.vue').default})
