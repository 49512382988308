import { Component } from "nuxt-property-decorator";
import ResponseHandler from "~/helpers/validations/ResponseHandler";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  sameAs,
  minLength, //@ts-ignore
} from "vuelidate/lib/validators";

@Component({})
export default class NewsletterValidator extends ResponseHandler {
  // definisco le regole di validazione
  @Validations()
  validations = {
    form: {
      nome: { required, minLength: minLength(2) },
      cognome: { required, minLength: minLength(2) },
      email: { required, email },
      accettazione_privacy: {
        sameAs: sameAs(() => true),
      },
    },
  };
}
