
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";

@Component
export default class TheHasGaraOnline extends Vue {
  @Prop() value!: any;

  // @Watch("value.status")
  // resetLatestSearchValueOnDefault() {
  //   if (this.value.status.length && this.value.status[0].toLowerCase() !== "venduto") {
  //     this.$emit("set-value", []);
  //   }
  // }

  emitUpdate(e: any) {
    const today = this.$dayjs().subtract(1, "day").startOf("day").unix();
    const daysAgo = this.$dayjs().subtract(15, "day").startOf("day").unix();

    if (!e) {
      this.$emit("set-array-of-filter-value", [
        { filter: "filter/setStatusFilter", value: ["In vendita"] },
        { filter: "filter/setLatestSearch", value: [] },
      ]);
    } else {
      this.$emit("set-array-of-filter-value", [
        { filter: "filter/setStatusFilter", value: ["Venduto"] },
        { filter: "filter/setLatestSearch", value: [daysAgo, today] },
      ]);
    }
  }
}
