import { render, staticRenderFns } from "./TheExpiringInDays.vue?vue&type=template&id=af99d7d2&scoped=true"
import script from "./TheExpiringInDays.vue?vue&type=script&lang=ts"
export * from "./TheExpiringInDays.vue?vue&type=script&lang=ts"
import style0 from "./TheExpiringInDays.vue?vue&type=style&index=0&id=af99d7d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "af99d7d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSelectTheDateModal: require('/usr/src/nuxt-app/components/core/select/TheDateModal.vue').default})
