
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { GenreCollection } from "@/types/Filters";

@Component({ middleware: ["theme", "preselect_category", "populateFilterInStore"] })
export default class SearchPage extends SearchMainComponent {
  public pageTitle: string = this.$nuxt.$route.name ?? "index";

  @Getter("filter/genre") genre!: string;
  @Getter("filter/getSubcategories") subcategory!: string[];
  @Getter("filter/getQuery") query!: string;
  @Getter("tenant/completeDomain") domain: string;
  @Getter("typesense/total") total!: number;
  @Getter("typesense/resultData") results!: Array<any>;
  @Getter("tenant/seo") seo: any;
  @Getter("listlayout/isMap") isMap: boolean;
  @Getter("layout/searchMapIsOpen") searchMapIsOpen: boolean;

  public head() {
    let path = "ricerca";
    if (this.pageTitle === "index") {
      path = "ricerca";
    } else {
      path = this.pageTitle;
    }

    return {
      title: this.seo[path]?.title || "Ricerca",
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo[path]?.description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.seo[path]?.description,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.seo[path]?.description,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  public async fetch() {
    await this.$store.dispatch("typesense/getData");
  }

  public mounted() {
    this.$store.dispatch("listlayout/initLayoutFromLocalStorage");
  }

  public getGenreText() {
    return new GenreCollection().getText(this.genre);
  }

  public getPageTitle() {
    if (this.getGenreText() === undefined) {
      return "Risultati ricerca";
    } else {
      return "Risultati " + this.getGenreText();
    }
  }

  /**
   * Ordinamento
   */
  public async sort(event: any) {
    this.$store.commit("filter/setSort", event);
    await this.search(1);
  }

  /**
   * Risultati per Pagina
   */
  public async rppChanged(event: any) {
    this.$store.commit("filter/setRpp", event);
    this.$store.dispatch("paginator/changePerPage", event);
    await this.search(1);
  }

  /**
   * Effettuo la ricerca
   */
  public async search(pageNumber: number, clickedGenre?: string) {
    this.$scrollOntop();
    // Svuoto i risultati
    this.$store.commit("typesense/clearResults");

    // Risetto Pagina 1 e Ultima pagina 0
    this.$store.commit("paginator/resetToPage");

    // Chiudo la modale
    this.$store.commit("modal/closeModal");

    if (pageNumber) {
      // Setto la nuova pagina
      await this.$store.dispatch("paginator/setPage", pageNumber);
    }

    if (clickedGenre) {
      const subcategory = [...this.subcategory];
      const query = this.query;

      // resetto tutti i filtri precedenti
      this.$store.commit("filter/resetFilterMutation", this.$store.getters["tenant/ivgId"]);
      this.$store.commit("filter/commitIvgPayload", this.$store.getters["tenant/ivgPayload"]);
      // riapplico i filtri dal click
      this.$store.commit("filter/setGenreFilter", [clickedGenre]);
      this.$store.commit("filter/setSubcategoryFilter", subcategory);
      this.$store.commit("filter/setQueryText", query);
    }

    this.reFetch(this.genre || clickedGenre);

    await this.$store.dispatch("filter/setQueryParams");
  }

  /**
   * se la slug non cambia si deve forzare la fetch
   */
  public reFetch(genre?: string): void {
    if ((genre && this.$route.name === new GenreCollection().getAlias(genre)) || this.$route.name === "index") {
      this.$fetch();
    }
  }
}
