import Platform from "@/types/domains/platform";

export interface ImmobileRD {
  id: string | undefined;
  name: string | undefined;
  description: string | undefined;
  image: string | undefined;
  category: string | undefined;
  dateOfPublication: string | undefined | null;
  addressLocality: string | undefined | null;
  addressRegion: string | undefined | null;
  price: number | undefined;
  domain: string;
}

export function immobileJson(data: ImmobileRD) {
  return {
    script: [
      {
        hid: "schema-immobile",
        type: "application/ld+json",
        json: {
          "@context": "http://schema.org/",
          "@graph": [
            {
              "@id": data.id,
              "@type": ["Product", "RealEstateListing"],
              name: data.name,
              description: data.description,
              category: data.category,
              lastReviewed: data.dateOfPublication,
              address: {
                "@type": "PostalAddress",
                addressCountry: "IT",
                addressLocality: data.addressLocality,
                addressRegion: data.addressRegion,
              },
              image: [{ "@type": "ImageObject", contentUrl: data.image }],
              offers: {
                "@type": "Offer",
                priceCurrency: "EUR",
                price: data.price,
                url: data.id,
                availability: "https://schema.org/InStock",
              },
            },
          ],
        },
      },
      {
        hid: "schema-breadcrumb",
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            { "@type": "ListItem", position: 1, item: { "@id": new Platform(data.domain).getDomain(), name: data.domain } },
            {
              "@type": "ListItem",
              position: 2,
              item: { "@id": new Platform(data.domain).getDomain() + "/ricerca/immobili", name: "Immobili" },
            },
            { "@type": "ListItem", position: 3, name: data.name },
          ],
        },
      },
    ],
  };
}
