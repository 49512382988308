
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { LoginModalValue } from "@/types/components";

@Component
export default class IndexPage extends SearchMainComponent {
  public searchSelectLabel: Array<string> = ["Cerca"];
  @Getter("tenant/additionalModules") modules: Array<any>;
  @Getter("tenant/additionalBanners") banners: Array<any>;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/seo") seo: any;
  @Getter("tenant/completeDomain") domain: string;
  @Getter("tenant/logoSrc") logoSrc: string;
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/claim") claim: string;
  @Getter("tenant/ivgId") ivgId: number;
  @Getter("tenant/isAssociato") isAssociato: boolean;
  @Getter("tenant/categorieArricchite") categorieArrichite: any;
  @Getter("tenant/aboutUsText") aboutUsText: any;
  @Getter("layout/isScroll") isScroll: boolean;
  @Getter("preset/allGenres") genres: any;
  @Getter("filter/getFilter") filters: any;

  public head() {
    return {
      title: this.seo.home.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.home.description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.seo.home.title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.seo.home.description,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],

      script: [
        {
          hid: "schema-organization",
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Organization",
            url: this.domain,
            logo: this.isAsta ? this.$config.cdnEndpoint + "loghi/default.webp" : this.logoSrc,
          },
        },
        {
          hid: "schema-website",
          type: "application/ld+json",
          json: {
            "@context": "https://schema.org",
            "@type": "Website",
            url: this.domain,
            name: this.siteName,
            potentialAction: {
              "@type": "SearchAction",
              target: this.domain + "/search?filter[visibile_su][0]=" + this.ivgId + "&query={search_term}",
              "query-input": "required name=search_term",
            },
          },
        },
      ],
    };
  }

  public searchGenre(genre: string) {
    // Setto il genre
    this.$store.commit("filter/setGenreFilter", [genre]);
    // Effettuo la ricerca
    this.search(1);
  }

  public searchCategory(event: any) {
    // Setto il genre
    this.$store.commit("filter/setGenreFilter", [event.genre]);
    // Setto il filtro per categoria
    this.$store.commit("filter/setCategoryFilter", event.category);
    // Effettuo la ricerca
    this.search(1);
  }

  public loginModalData: LoginModalValue = {
    model: false,
    title: "Autenticazione richiesta",
    text: "Registrati o esegui il login poter salvare la tue inserzioni preferite",
    icon: "heart",
    payload: null,
  };
  public itemToFavourite: string = "";
  public shareData = { visible: false, item: null, title: null, id: null, url: null };

  public closeModalAndSetFavourite() {
    this.loginModalData.model = false;
    this.toggleFavourite(this.itemToFavourite);
  }

  public toggleLoginModal(event: any) {
    this.loginModalData.model = !this.loginModalData.model;

    this.loginModalData.payload = {
      type: "saveWishlist",
      id: event.id,
      redirectUri: event.redirect,
    };

    this.itemToFavourite = event;
  }

  public toggleShareModal(event: any) {
    this.shareData = { ...event };
  }

  public async toggleFavourite(id: string) {
    await this.$store.dispatch("wishlist/changeFavourite", id);
  }
}
