import { render, staticRenderFns } from "./OnlyThree.vue?vue&type=template&id=6886b16c"
import script from "./OnlyThree.vue?vue&type=script&lang=ts"
export * from "./OnlyThree.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowGalleryMultipleImageWrapper: require('/usr/src/nuxt-app/components/show/gallery/MultipleImageWrapper.vue').default,ShowGalleryImageWrapper: require('/usr/src/nuxt-app/components/show/gallery/ImageWrapper.vue').default})
