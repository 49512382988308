import { render, staticRenderFns } from "./TheSelectTipoProcedura.vue?vue&type=template&id=cf0d49f8&scoped=true"
import script from "./TheSelectTipoProcedura.vue?vue&type=script&lang=ts"
export * from "./TheSelectTipoProcedura.vue?vue&type=script&lang=ts"
import style0 from "./TheSelectTipoProcedura.vue?vue&type=style&index=0&id=cf0d49f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cf0d49f8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSelectTheSelect: require('/usr/src/nuxt-app/components/core/select/TheSelect.vue').default})
