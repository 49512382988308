import { Module, Mutation, VuexModule, Action } from "vuex-module-decorators";
import { store } from "./index";

@Module({
  name: "listlayout",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class ListLayoutStore extends VuexModule {
  public layout: string = "card";
  extraFilters: boolean = false;

  @Mutation
  setExtraFilters(value: boolean) {
    this.extraFilters = value;
  }

  @Mutation
  toggleExtraFilters() {
    this.extraFilters = !this.extraFilters;
  }

  @Mutation
  setLayoutAsCard() {
    this.layout = "card";
    localStorage.setItem("layout", this.layout);
  }

  @Mutation
  setLayoutAsList() {
    this.layout = "list";
    localStorage.setItem("layout", this.layout);
  }

  @Mutation
  setLayout(value: string) {
    this.layout = value;
    if (this.layout !== "map") {
      localStorage.setItem("layout", this.layout);
    }
  }

  @Action({ commit: "setLayout" })
  initLayoutFromLocalStorage() {
    const layout = localStorage.getItem("layout");
    return layout ?? "card";
  }

  public get getLayout(): string {
    return this.layout;
  }

  public get isCard(): boolean {
    return this.layout === "card";
  }

  public get isList(): boolean {
    return this.layout === "list";
  }

  public get isMap(): boolean {
    return this.layout === "map";
  }

  public get isExtraFilterVisible(): boolean {
    return this.extraFilters;
  }
}
