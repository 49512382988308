import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "./index";

@Module({
  name: "modal",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class ModalStore extends VuexModule {
  public modal_isopen: boolean = false;
  public inserzioneMap: boolean = false;

  public get modalIsOpen(): boolean {
    return this.modal_isopen;
  }

  public get modalInserzioneMap(): boolean {
    return this.inserzioneMap;
  }

  @Mutation
  openInserzioneMapModal() {
    this.inserzioneMap = true;
  }

  @Mutation
  closeInserzioneMapModal() {
    this.inserzioneMap = false;
  }

  @Mutation
  openModal() {
    this.modal_isopen = true;
  }

  @Mutation
  closeModal() {
    this.modal_isopen = false;
  }

  @Action
  changeModalToClose() {
    this.context.commit("closeModal");
  }

  @Mutation
  toggleModal() {
    this.modal_isopen = !this.modal_isopen;
  }
}
