import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0cbb966b = () => interopDefault(import('../pages/bollettini/index.vue' /* webpackChunkName: "pages/bollettini/index" */))
const _56f52f5b = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _305ea4d0 = () => interopDefault(import('../pages/inserzione.vue' /* webpackChunkName: "pages/inserzione" */))
const _7e912d67 = () => interopDefault(import('../pages/myAsta.vue' /* webpackChunkName: "pages/myAsta" */))
const _1d403a92 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _4d90caa4 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4876c7a4 = () => interopDefault(import('../pages/auth/activate.vue' /* webpackChunkName: "pages/auth/activate" */))
const _4c272b84 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _9e9c33f6 = () => interopDefault(import('../pages/auth/logout.vue' /* webpackChunkName: "pages/auth/logout" */))
const _3e93a03a = () => interopDefault(import('../pages/auth/reserved.vue' /* webpackChunkName: "pages/auth/reserved" */))
const _8df195b8 = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _44a3e98c = () => interopDefault(import('../pages/auth/social-callback.vue' /* webpackChunkName: "pages/auth/social-callback" */))
const _761bec26 = () => interopDefault(import('../pages/news/show.vue' /* webpackChunkName: "pages/news/show" */))
const _d4b6d354 = () => interopDefault(import('../pages/statics/any.vue' /* webpackChunkName: "pages/statics/any" */))
const _343a582e = () => interopDefault(import('../pages/statics/chi-siamo.vue' /* webpackChunkName: "pages/statics/chi-siamo" */))
const _a894581c = () => interopDefault(import('../pages/statics/error.vue' /* webpackChunkName: "pages/statics/error" */))
const _1c1544e2 = () => interopDefault(import('../pages/statics/guida.vue' /* webpackChunkName: "pages/statics/guida" */))
const _497f8e44 = () => interopDefault(import('../pages/statics/ivg.vue' /* webpackChunkName: "pages/statics/ivg" */))
const _79ca62bf = () => interopDefault(import('../pages/statics/newsletter.vue' /* webpackChunkName: "pages/statics/newsletter" */))
const _1a638671 = () => interopDefault(import('../pages/statics/newsletter-activate.vue' /* webpackChunkName: "pages/statics/newsletter-activate" */))
const _127cbac3 = () => interopDefault(import('../pages/statics/newsletter-unsubscribe.vue' /* webpackChunkName: "pages/statics/newsletter-unsubscribe" */))
const _7355d8f2 = () => interopDefault(import('../pages/statics/privacy.vue' /* webpackChunkName: "pages/statics/privacy" */))
const _5baeb456 = () => interopDefault(import('../pages/statics/pubblica-con-noi.vue' /* webpackChunkName: "pages/statics/pubblica-con-noi" */))
const _552146c4 = () => interopDefault(import('../pages/statics/segnalazioni.vue' /* webpackChunkName: "pages/statics/segnalazioni" */))
const _637e1c28 = () => interopDefault(import('../pages/statics/servizi.vue' /* webpackChunkName: "pages/statics/servizi" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bollettini",
    component: _0cbb966b,
    name: "bollettini"
  }, {
    path: "/home",
    component: _56f52f5b,
    name: "home"
  }, {
    path: "/inserzione",
    component: _305ea4d0,
    name: "inserzione"
  }, {
    path: "/myAsta",
    component: _7e912d67,
    name: "myAsta"
  }, {
    path: "/news",
    component: _1d403a92,
    name: "news"
  }, {
    path: "/search",
    component: _4d90caa4,
    name: "search"
  }, {
    path: "/auth/activate",
    component: _4876c7a4,
    name: "auth-activate"
  }, {
    path: "/auth/login",
    component: _4c272b84,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _9e9c33f6,
    name: "auth-logout"
  }, {
    path: "/auth/reserved",
    component: _3e93a03a,
    name: "auth-reserved"
  }, {
    path: "/auth/reset",
    component: _8df195b8,
    name: "auth-reset"
  }, {
    path: "/auth/social-callback",
    component: _44a3e98c,
    name: "auth-social-callback"
  }, {
    path: "/news/show",
    component: _761bec26,
    name: "news-show"
  }, {
    path: "/statics/any",
    component: _d4b6d354,
    name: "statics-any"
  }, {
    path: "/statics/chi-siamo",
    component: _343a582e,
    name: "statics-chi-siamo"
  }, {
    path: "/statics/error",
    component: _a894581c,
    name: "statics-error"
  }, {
    path: "/statics/guida",
    component: _1c1544e2,
    name: "statics-guida"
  }, {
    path: "/statics/ivg",
    component: _497f8e44,
    name: "statics-ivg"
  }, {
    path: "/statics/newsletter",
    component: _79ca62bf,
    name: "statics-newsletter"
  }, {
    path: "/statics/newsletter-activate",
    component: _1a638671,
    name: "statics-newsletter-activate"
  }, {
    path: "/statics/newsletter-unsubscribe",
    component: _127cbac3,
    name: "statics-newsletter-unsubscribe"
  }, {
    path: "/statics/privacy",
    component: _7355d8f2,
    name: "statics-privacy"
  }, {
    path: "/statics/pubblica-con-noi",
    component: _5baeb456,
    name: "statics-pubblica-con-noi"
  }, {
    path: "/statics/segnalazioni",
    component: _552146c4,
    name: "statics-segnalazioni"
  }, {
    path: "/statics/servizi",
    component: _637e1c28,
    name: "statics-servizi"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
