
import { Component } from "nuxt-property-decorator";
import NewPasswordValidator from "@/helpers/validations/newpassword";
import FormNewPassword from "~/types/auth/FormNewPassword";
import { BIcon, BIconEye, BIconEyeSlash } from "bootstrap-vue";

@Component({ components: { BIcon, BIconEye, BIconEyeSlash } })
export default class NewPassword extends NewPasswordValidator {
  public formNewPassword: FormNewPassword = new FormNewPassword();
  public inError: boolean = false;
  public success: boolean = false;
  public sentTo!: string;
  public seePassword: boolean = false;
  public errors: Array<string> = [];

  public async onNewPassword() {
    this.$v.formNewPassword.$touch();
    if (this.$v.formNewPassword.$anyError) {
      return;
    }

    this.formNewPassword.token = this.$router.currentRoute.params?.token;

    try {
      await this.$axios.post(this.$config.astaEndpoint + "myAsta/reset-password", this.formNewPassword);
      this.success = true;
    } catch (err: any) {
      if (err?.response?.status === 401) {
        this.$swal({
          html:
            '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4"src="https://library.astagiudiziaria.com/front/icons/warning.svg" width="60">' +
            `<h4 class="mb-2">Errore nell'invio della form</h4>` +
            `<p class="mt-1 mb-0"><small>` +
            err.response.data.message +
            "</small></p></div>",
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
      }
    }
    this.formNewPassword = new FormNewPassword();
    this.$v.$reset();
  }
}
