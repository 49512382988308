
import { Component, Vue, Prop } from "nuxt-property-decorator";
import {
  BIcon,
  BIconColumns,
  BIconMegaphone,
  BIconShieldCheck,
  BIconShieldExclamation,
  BIconRulers,
  BIconArrowLeftCircle,
  BIconArrowDownCircle,
  BIconArrowDownCircleFill,
} from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconColumns,
    BIconMegaphone,
    BIconShieldCheck,
    BIconShieldExclamation,
    BIconRulers,
    BIconArrowLeftCircle,
    BIconArrowDownCircle,
    BIconArrowDownCircleFill,
  },
})
export default class TheDocuments extends Vue {
  @Prop() item!: any;

  seeOtherDocs() {
    this.$emit("see-all-documents", true);
    setTimeout(() => {
      const allDocuments = document.getElementById("allDocuments");
      if (allDocuments) {
        allDocuments.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        return;
      }
    }, 425);
  }
}
