
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";

@Component
export default class TheCorrelated extends Vue {
  @Prop() item!: any;
  @Getter("filter/searchParameters") filter: any;

  public title: string = "procedura";
  public items: Array<any> = [];

  async fetch() {
    const correlatedFilter = { ...this.filter };
    correlatedFilter.q = this.item?.main?.tipoProcedura + " " + this.item?.main?.numeroProcedura || "";
    const results = await this.$store.dispatch("typesense/getCorrelated", { correlatedFilter, id: this.item.id });
    if (results.length) {
      this.items = results;
      return;
    }
    correlatedFilter.q = "";
    correlatedFilter.filter_by += " && category: [" + this.item.main.categoria + "]";
    this.title = "categoria";
    const fallbackResults = await this.$store.dispatch("typesense/getCorrelated", { correlatedFilter, id: this.item.id });
    if (fallbackResults.length) {
      this.items = fallbackResults;
      return;
    }
  }

  fetchOnServer() {
    return false;
  }

  async mounted() {
    await this.$nextTick();
    new Swiper(this.$refs.swiper, {
      grabCursor: true,
      // centeredSlide: true,
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        // when window width is >= 320px
        480: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        // when window width is >= 480px
        991: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
        // when window width is >= 640px
        1281: {
          slidesPerView: 4,
          spaceBetween: 40,
        },
      },
    });
  }
}
