
import { Component, Prop, Vue, Getter } from "nuxt-property-decorator";
import { Filter, Options } from "@/types/components";
import { FilterSelected } from "@/types/modal/interface";
import { BIconChevronDoubleDown, BIconChevronDoubleUp } from "bootstrap-vue";

@Component({
  components: {
    BIconChevronDoubleDown,
    BIconChevronDoubleUp,
  },
})
export default class TheModal extends Vue {
  @Prop() query!: string;
  @Prop() filters!: Filter;
  @Prop() options!: Options;
  @Prop() componentClass!: string;
  @Prop() isgenere!: boolean;
  @Prop() genre!: string;
  @Prop({ default: false }) isloading: boolean;
  @Prop() totale_preview!: number;
  @Prop() filtersCount!: number;
  @Prop({ default: false }) multiSearchError!: boolean;
  @Prop() isAsta!: boolean;
  @Prop({ default: false }) searchMapIsOpen: boolean;
  @Getter("listlayout/isMap") isMap!: boolean;
  @Getter("listlayout/isExtraFilterVisible") isExtraFilterVisible!: boolean;

  public mobileIsTyping: boolean = false;
  public isIphone: boolean = false;

  public setFilterValue(filter: string, valore: any) {
    this.$emit("set-filter-value", { filter: filter, value: valore } as FilterSelected);
  }

  public setArrayOfFilterValue(FilterSelected: FilterSelected[]) {
    this.$emit("set-array-of-filter-value", FilterSelected);
  }

  public hideMobileKeyboardOnReturn = (element: any) => {
    element.addEventListener("keyup", (keyboardEvent: any) => {
      const key = keyboardEvent.code || keyboardEvent.keyCode;
      if (key === "Enter" || key === 13) {
        element.blur();
      }
    });
  };

  async mounted() {
    if (this.isgenere) {
      this.$emit("genreChanged");
    }

    document.querySelectorAll("[type=search]").forEach((element) => {
      this.hideMobileKeyboardOnReturn(element);
    });
  }

  // Alla distruzione del cpn, rimuovo l'event listener
  beforeDestroy() {
    document.querySelectorAll("[type=search]").forEach((element) => {
      element.removeEventListener("keyup", this.hideMobileKeyboardOnReturn);
    });
  }

  // mostra/nascondi extra filtri
  toggleExtraFilters() {
    this.$store.commit("listlayout/toggleExtraFilters");
  }
}
