
import { Component, Getter, Vue, Watch } from "nuxt-property-decorator";
import { latestDaysOptions } from "@/types/Filters";
import { BIconPlus, BIconPlusCircle, BIconPencilSquare } from "bootstrap-vue";

@Component({ components: { BIconPlus, BIconPlusCircle, BIconPencilSquare } })
export default class TheLatestDays extends Vue {
  @Getter("filter/latestDays") latestDays: any;
  public selected: any = null;
  public options: Array<any> = latestDaysOptions;
  public active = false;
  public modal: any = { visible: false, title: "Ricerca per data" };
  public value: any = { from: "", to: "" };

  mounted() {
    // build an array of options with unix timestamp from days as integer
    this.makeOptions();
    // set selected value
    this.makeSelected();
    // set value for modal
    if (this.latestDays.length) {
      this.value.from = this.$dayjs(this.latestDays[0] * 1000).format("YYYY-MM-DD");
      this.value.to = this.$dayjs(this.latestDays[1] * 1000).format("YYYY-MM-DD");
    }
  }

  @Watch("latestDays")
  reMountSelected() {
    if (!this.active) {
      this.makeSelected();
    }
  }

  public makeSelected() {
    this.selected = [0, 0];
    // se la differenza fra le due date è un numero
    if (!isNaN(this.$store.$dayjs.unix(this.latestDays[1]).diff(this.$store.$dayjs.unix(this.latestDays[0]), "d"))) {
      // setto il valore selezionato (apparira' come attivo se esiste una gap data settato nella classe di definizione dei filtri)
      this.selected = [parseInt(this.latestDays[0]), parseInt(this.latestDays[1])];

      // controllo se nelle opzioni esiste il valore selezionato
      const optionExists = this.options.find((el) => {
        return el.value[0] === Number(this.latestDays[0]) && el.value[1] === Number(this.latestDays[1]);
      });

      // se non esiste allore deve essere un custom e attivo il bottone data custom
      if (!optionExists) {
        this.active = true;
      }
    } else if (typeof this.latestDays[0] === "string") {
      // se il valore è una stringa allora si tratta di un prima di/ dopo il e attivo il bottone
      this.active = true;
      this.selected = [];
    }
  }

  // trasformo i valori da giorni interi a unix timestamp
  public makeOptions() {
    this.options = this.options.map((el) => {
      return {
        text: el.text,
        value: [
          parseInt(el.value) !== 0 ? this.$store.$dayjs().subtract(parseInt(el.value), "day").endOf("day").unix() : 0,
          parseInt(el.value) !== 0 ? this.$store.$dayjs().add(1, "day").startOf("day").unix() : 0,
        ],
      };
    });
  }

  // passo l'array per la ricerca e resetto il bottone dalla data custom
  public emitUpdate(e: Array<number>) {
    this.$emit("set-value", e);
    this.reset();
  }

  // rimetto i valori a default e rimouvo l'attivo
  public reset() {
    this.value.from = "";
    this.value.to = "";
    this.active = false;
  }
}
