import { render, staticRenderFns } from "./CardActions.vue?vue&type=template&id=962d04c6"
import script from "./CardActions.vue?vue&type=script&lang=ts"
export * from "./CardActions.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowAstaThePrint: require('/usr/src/nuxt-app/components/show/asta/ThePrint.vue').default,ShowFormsTheContact: require('/usr/src/nuxt-app/components/show/forms/TheContact.vue').default,ResultsCardButtonFavourite: require('/usr/src/nuxt-app/components/results/card/ButtonFavourite.vue').default,ResultsCardButtonShareModal: require('/usr/src/nuxt-app/components/results/card/ButtonShareModal.vue').default})
