
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class TheSwitch extends Vue {
  @Prop() readonly value!: any;

  emitUpdate(value: boolean) {
    this.$emit("emit-update", value);
  }
}
