
import { Vue, Component } from "nuxt-property-decorator";

@Component({ layout: "empty", middleware: "default" })
export default class LogoutPage extends Vue {
  async mounted() {
    await this.$auth.logout();
    window.location.href = "/";
  }
}
