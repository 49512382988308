
import { Component, Getter } from "nuxt-property-decorator";

import NewsletterValidator from "@/helpers/validations/newsletter";
import FormNewsletter from "~/types/forms/FormNewsletter";
import { saveConsent } from "@/types/forms/saveConsent";

@Component
export default class NewsletterForm extends NewsletterValidator {
  @Getter("tenant/privacy") privacy!: any;
  public form: FormNewsletter = new FormNewsletter();
  public loading: boolean = false;

  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      console.error(e);
    }
  }

  public async onSubmit() {
    this.loading = true;
    this.$v.form.$touch();

    if (this.$v.$invalid) {
      return;
    }

    saveConsent(
      "newsletter-form",
      "newsletter-submit",
      { first_name: "nome", last_name: "cognome", email: "email" },
      {
        legal_documents: "accettazione_privacy",
      },
      [{ identifier: "privacy_policy", version: "15/05/2018" }]
    );

    const data = this.form;

    try {
      const token = await this.$recaptcha.execute("iscrizioneNewsletter");
      data.recaptcha = token;
    } catch (error) {
      throw error;
    }

    try {
      await this.$axios.$post(this.$config.astaEndpoint + "newsletter/register", data);
      this.success = true;
      this.resetForm();
    } catch (error: any) {
      console.error(error);
    }
  }

  public resetForm() {
    this.form = new FormNewsletter();
    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }
}
