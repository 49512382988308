export default ({ store }, inject) => {
  const gaIds = store.getters["tenant/gaIds"];

  // Creo gli script quando il document è completamente caricato
  document.addEventListener("DOMContentLoaded", function () {
    setTimeout(() => {
      createScripts(gaIds);
    }, 2000);
  });

  function createScripts(gaIds) {
    for (let i = 0; i < gaIds.length; i++) {
      let script = document.createElement("script");
      script.async = true;
      script.defer = true;
      script.id = "gtag-src-" + i;
      script.src = "https://www.googletagmanager.com/gtag/js?id=" + gaIds[i];
      document.head.appendChild(script);
  
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
  
      gtag("js", new Date());
      gtag("config", gaIds[i], {
        send_page_view: true,
        //anonymize_ip: true,
      });
    }
  }
};
