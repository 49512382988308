
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BIconChevronDoubleDown, BIconChevronDoubleUp } from "bootstrap-vue";
import { OptionObject, Options } from "~/types/components";

@Component({
  components: {
    BIconChevronDoubleDown,
    BIconChevronDoubleUp,
  },
})
export default class TheSubcategory extends Vue {
  @Prop() value!: Options;
  @Prop() options!: OptionObject[];
  @Prop() componentClass!: string;
  @Prop({ default: false }) isloading: boolean;

  public sliced: number = 0;
  public buttonToggleResultsValues = {
    default: 20,
    isOpen: false,
    showLabel: "Mostra tutte le tipologie",
    hideLabel: "Mostra meno",
  };

  mounted() {
    this.sliced = this.buttonToggleResultsValues.default;
  }

  emitUpdate(e: any) {
    this.$emit("set-value", e);
  }

  toggleResults() {
    if (this.buttonToggleResultsValues.isOpen) {
      this.sliced = this.buttonToggleResultsValues.default;
      this.buttonToggleResultsValues.isOpen = false;
    } else {
      this.sliced = this.options.length;
      this.buttonToggleResultsValues.isOpen = true;
    }
  }
}
