
import { Component, Prop, Vue, Getter } from "nuxt-property-decorator";
import { Filter } from "@/types/components";
import { BIcon, BIconCaretLeftFill } from "bootstrap-vue";
import { FilterSelected } from "~/types/modal/interface";

@Component({
  components: {
    BIcon,
    BIconCaretLeftFill,
  },
})
export default class SearchWrapper extends Vue {
  public preview_is_loading: boolean = false;

  @Prop() title!: string;
  @Prop() total!: number;
  @Prop() data: Array<any>;

  /**
   *  Dichiaro le Action e i Getter Necessari
   */
  @Getter("typesense/getFacets") facets!: Array<any>;
  @Getter("typesense/totalPreview") totale_preview: number;
  @Getter("typesense/resultPreview") preview_data: any;
  @Getter("typesense/loading") isLoading: boolean;

  @Getter("filter/getFilter") filters: any;
  @Getter("filter/getPreviewFilter") previewFilters: any;
  @Getter("filter/getQuery") query: any;
  @Getter("filter/filtersOptions") options: Filter;
  @Getter("filter/filterCount") filtersCount: number;
  @Getter("filter/isGenere") isGenere: boolean;
  @Getter("filter/genre") genre: string;
  @Getter("filter/getFilterAll") filter!: any;

  @Getter("modal/modalIsOpen") modal_is_open: boolean;

  @Getter("layout/isScroll") isScrolled: boolean;
  @Getter("layout/header") headerHeight: number;
  @Getter("layout/offset") offset: any;
  @Getter("layout/searchMapIsOpen") searchMapIsOpen: any;

  @Getter("preset/currentGenre") currentGenere: string;
  @Getter("preset/style") style_genre: string;

  @Getter("paginator/currentPage") currentPage!: number;
  @Getter("paginator/rows") rows!: number;

  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/lat") initialLat: number;
  @Getter("tenant/lng") initialLng: number;

  public multiSearchError: boolean = false;

  /**
   * Metodo di emit evento di sorting
   */
  public addSortingToStore(e: any) {
    this.$emit("sorted", e);
  }

  public changeRppToStore(e: any) {
    this.$emit("rppChanged", e);
  }

  /**
   * Recupero i dati di preview
   */
  public async getPreviewData(lastFilterChanged?: string) {
    this.$store.commit("typesense/clearPreview");
    this.preview_is_loading = true;
    try {
      await this.$store.dispatch("typesense/getPreview", lastFilterChanged);
      this.multiSearchError = false;
    } catch (e) {
      this.multiSearchError = true;
    }
    this.preview_is_loading = false;
  }

  /**
   * Recupero i dati di preview della suggestion senza il genere
   */
  public async getGlobalPreviewData() {
    this.$store.commit("typesense/clearPreview");
    this.preview_is_loading = true;
    await this.$store.dispatch("typesense/getDataWithoutGenre");
    this.preview_is_loading = false;
  }

  /**
   * Setto il querystring della barra di ricerca
   */
  public setQueryText(event: string) {
    this.$store.commit("filter/setQueryText", event);
  }

  /**
   * Chiudo la modale dei filtri
   */
  public closeModal() {
    this.$store.commit("modal/closeModal");
  }

  /**
   * Toggle della modale dei filtri
   */
  public async toggleModal() {
    this.$store.commit("modal/toggleModal");
    await this.getPreviewData();
  }

  /**
   * Metodo di cambio layout da griglia a lista
   */
  public setLayout(layout: string) {
    this.$store.commit("listlayout/setLayout", layout);
  }

  public resetPrice() {
    this.$store.commit("filter/resetPrice");
  }

  /**
   * Aggiorno singolo filtro
   */
  public async setFilterValue(filter: FilterSelected): Promise<void> {
    this.interceptFilterChangeConditions(filter);
    this.$store.commit(filter.filter, filter.value);
    await this.getPreviewData(filter.filter);
  }

  /**
   * Aggiorno array of filtri
   */
  public async setArrayOfFilterValue(filters: FilterSelected[]): Promise<void> {
    for (const filter of filters) {
      this.interceptFilterChangeConditions(filter);
      this.$store.commit(filter.filter, filter.value);
    }
    await this.getPreviewData(filters[filters.length - 1].filter);
  }

  /**
   * Sto trespolo qui serve ad intercettare il reset dei filtri lasiando intatto l'eventuale query testuale ( Sono Federico, Giacomo è allergico ai commenti )
   * @param filter
   */
  public interceptFilterChangeConditions(filter: FilterSelected): void {
    if (filter.filter === "filter/setGenreFilter" && this.genre !== "default") {
      this.$store.commit("filter/resetCategoryFilter");
      this.$store.commit("filter/resetSubcategoryFilter");
    }

    if (filter.filter === "filter/setCategoryFilter") {
      this.$store.commit("filter/resetSubcategoryFilter");
    }
  }

  public removeFilter(str: string): void {
    this.$store.commit(str);
    this.$emit("search", 1);
  }

  public removeFilterFromModal(str: string) {
    this.$store.commit(str);
    this.getPreviewData();
  }

  /**
   * Resetto i filtri
   */
  public async reset() {
    this.$emit("reset");
  }

  /**
   * Effettuo la ricerca
   */
  public async search() {
    this.$emit("search", 1);
  }

  /**
   * Trovo il genere dalla categoria
   */
  public async findGenreFromCategory() {
    if (this.filters.category.length) {
      await this.$store.dispatch("typesense/findGenreFromCategory", this.filters.category[0]);
    }
    this.search();
  }

  /**
   * Apro la mappa
   */
  public openSearchMap() {
    this.$emit("openmapsearch");
  }
}
