export default function (context: any): void {
  const { store, route, redirect } = context;
  if (isAllow(route)) {
    if (route.name !== "index") {
      store.commit("filter/setGenreFilter", [route.name.toUpperCase().replace("-", "/")]);
    }
    if (route.name === "valoricrediti") {
      store.commit("filter/setGenreFilter", ["VALORI/CREDITI"]);
    }
  } else {
    return redirect("/error");
  }
}

export function isAllow(route: any): boolean {
  let routeName = route.name ?? "";
  routeName = routeName.toUpperCase();
  const allowedRouteName = ["IMMOBILI", "MOBILI", "AZIENDE", "VALORICREDITI", "ALTRO", "INDEX"];
  if (!allowedRouteName.includes(routeName)) {
    return false;
  }
  return true;
}
