import { render, staticRenderFns } from "./BeneMobile.vue?vue&type=template&id=330d3739"
import script from "./BeneMobile.vue?vue&type=script&lang=ts"
export * from "./BeneMobile.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowTabsDettaglioPvpGoodsMobiliLuogoRitiro: require('/usr/src/nuxt-app/components/show/tabs/dettaglioPvp/goods/mobili/LuogoRitiro.vue').default,ShowTabsDettaglioPvpGoodsMobiliLuogoVisione: require('/usr/src/nuxt-app/components/show/tabs/dettaglioPvp/goods/mobili/LuogoVisione.vue').default})
