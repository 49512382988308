import { ImmobileRD, immobileJson } from "@/helpers/ld-json/immobili";
import { MobileRD, mobileJson } from "@/helpers/ld-json/mobili";
import { CarRD, carJson } from "@/helpers/ld-json/car";
import Platform from "@/types/domains/platform";

export default function meta(
  title: string | undefined,
  description: string | undefined,
  image: string | undefined,
  domain: string,
  permailink: string,
  logo: string,
  item: any
) {
  const platform = new Platform(domain);
  const url = `${platform.getDomain()}/inserzioni/${permailink}`;
  let canonical = url;

  // se l'inserzione non è presente su ASTA allora ora il canonical originale altrimenti uso asta
  if (item?.main?.pubblicata_su_asta) {
    canonical = `https://www.astagiudiziaria.com/inserzioni/${permailink}`;
  }

  const meta = {
    title: title,
    meta: [
      {
        hid: "description",
        name: "description",
        content: description,
      },
      { hid: "og:title", property: "og:title", content: title },
      { hid: "og:image", property: "og:image", content: image },
      { hid: "og:image:width", property: "og:image:width", content: "1024" },
      { hid: "og:image:height", property: "og:image:height", content: "768" },
      { hid: "og:url", property: "og:url", content: url },
      {
        hid: "og:description",
        property: "og:description",
        content: description,
      },
      {
        hid: "twitter:description",
        property: "twitter:description",
        content: description,
      },
      { hid: "twitter:title", property: "twitter:title", content: title },
      { hid: "twitter:image", property: "twitter:image", content: image },
    ],
    link: [
      { rel: "canonical", href: canonical },
      { rel: "img_src", href: image },
    ],
  };

  if (item?.main.genere === "IMMOBILI") {
    const immobileRD: ImmobileRD = {
      id: url,
      name: title,
      description: description,
      image: image,
      category: item?.main?.categoria,
      dateOfPublication: item?.dataPubblicazioneIvg,
      addressLocality: item?.main?.ubicazioneLotto?.citta,
      addressRegion: item?.main?.ubicazioneLotto?.provincia,
      price: item?.main?.originalPrezzoBase,
      domain: domain,
    };

    return { ...meta, ...immobileJson(immobileRD) };
  }

  if (item?.main.genere === "MOBILI" && item?.main?.categoria === "AUTOVEICOLI E CICLI" && item?.main?.subcategory === "AUTOVETTURE") {
    const carRD: CarRD = {
      name: title,
      logo: logo,
      domain: domain,
      price: item?.main?.originalPrezzoBase,
      description: description,
      image: image,
    };
    return { ...meta, ...carJson(carRD) };
  }

  if (item?.main.genere === "MOBILI") {
    const mobileRD: MobileRD = {
      name: title,
      domain: domain,
    };
    return { ...meta, ...mobileJson(mobileRD) };
  }

  return meta;
}
