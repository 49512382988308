
import { Vue, Component, Getter } from "nuxt-property-decorator";

@Component({ layout: "empty", middleware: "default" })
export default class LoginPage extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  @Getter("tenant/currentDomain") domain: string;
  @Getter("tenant/seo") seo: any;

  public canonical(): string {
    return "https://" + this.domain + "/reserved/login";
  }

  public redirect() {
    this.$router.push({ path: "/myAsta" });
  }
  head() {
    return {
      title: this.seo.areariservata.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.areariservata.description,
        },

        { hid: "og:title", property: "og:title", content: this.seo.areariservata.title },
        { hid: "og:description", property: "og:description", content: this.seo.areariservata.description },
        { hid: "og:url", property: "og:url", content: this.canonical() },
      ],
      link: [{ rel: "canonical", href: this.canonical() }],
    };
  }
}
