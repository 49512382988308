
import { Component, Getter, Vue, Watch } from "nuxt-property-decorator";
import { expiringInDaysOptions } from "@/types/Filters";
import { BIconPlus, BIconPlusCircle, BIconPencilSquare } from "bootstrap-vue";

@Component({ components: { BIconPlus, BIconPlusCircle, BIconPencilSquare } })
export default class TheExpiringInDays extends Vue {
  @Getter("filter/countDaysInDate") countDaysInDate: any;
  public selected: any = null;
  public options: Array<any> = expiringInDaysOptions;
  public active = false;
  public modal: any = { visible: false, title: "Ricerca per data" };
  public value: any = { from: "", to: "" };

  mounted() {
    // build an array of options with unix timestamp from days as integer
    this.makeOptions();
    // set selected value
    this.makeSelected();
    // set value for modal
    if (this.countDaysInDate.length) {
      if (this.countDaysInDate[0] === "<") {
        // prima del setto solo il to
        this.value.to = this.$dayjs(this.countDaysInDate[1] * 1000).format("YYYY-MM-DD");
      } else if (this.countDaysInDate[0] === ">") {
        // dopo il setto solo il from
        this.value.from = this.$dayjs(this.countDaysInDate[1] * 1000).format("YYYY-MM-DD");
      } else {
        this.value.from = this.$dayjs(this.countDaysInDate[0] * 1000).format("YYYY-MM-DD");
        this.value.to = this.$dayjs(this.countDaysInDate[1] * 1000).format("YYYY-MM-DD");
      }
    }
  }

  @Watch("countDaysInDate")
  reMountSelected() {
    if (!this.active) {
      this.makeSelected();
    }
  }

  public makeSelected() {
    this.selected = [0, 0];
    // se la differenza fra le due date è un numero
    if (!isNaN(this.$store.$dayjs.unix(this.countDaysInDate[1]).diff(this.$store.$dayjs.unix(this.countDaysInDate[0]), "d"))) {
      // setto il valore selezionato (apparira' come attivo se esiste una gap data settato nella classe di definizione dei filtri)
      this.selected = [parseInt(this.countDaysInDate[0]), parseInt(this.countDaysInDate[1])];

      // controllo se nelle opzioni esiste il valore selezionato
      const optionExists = this.options.find((el) => {
        return el.value[0] === Number(this.countDaysInDate[0]) && el.value[1] === Number(this.countDaysInDate[1]);
      });

      // se non esiste allore deve essere un custom e attivo il bottone data custom
      if (!optionExists) {
        this.active = true;
      }
    } else if (typeof this.countDaysInDate[0] === "string") {
      // se il valore è una stringa allora si tratta di un prima di/ dopo il e attivo il bottone
      this.active = true;
      this.selected = [];
    }
  }

  // trasformo i valori da giorni interi a unix timestamp
  public makeOptions() {
    this.options = this.options.map((el) => {
      return {
        text: el.text,
        value: [
          parseInt(el.value) !== 0 ? this.$store.$dayjs().add(1, "day").startOf("day").unix() : 0,
          parseInt(el.value) !== 0 ? this.$store.$dayjs().add(parseInt(el.value), "day").endOf("day").unix() : 0,
        ],
      };
    });
  }

  // passo l'array per la ricerca e resetto il bottone dalla data custom
  public emitUpdate(e: Array<number>) {
    this.$emit("set-value", e);
    this.reset();
  }

  public toggleModal() {
    this.modal.visible = !this.modal.visible;

    if (this.selected[0] && this.value.from === "") {
      this.value.from = this.$dayjs(this.selected[0] * 1000).format("YYYY-MM-DD");
      this.value.to = this.$dayjs(this.selected[1] * 1000).format("YYYY-MM-DD");
    }
  }

  // rimetto i falori a default e rimouvo l'attivo
  public reset() {
    this.value.from = "";
    this.value.to = "";
    this.active = false;
  }

  public setFilterValue(arr: (string | number)[]) {
    // trasformo l'array per typesense
    if (typeof arr[0] === "number" && isNaN(arr[0])) {
      arr[0] = "<";
    } else if (typeof arr[1] === "number" && isNaN(arr[1])) {
      arr[1] = arr[0];
      arr[0] = ">";
    }

    this.$emit("set-value", arr);
    this.modal.visible = false;

    if (arr.length) {
      // metto attivo se ha settato e cercato
      this.active = true;
      this.selected = [];
    } else {
      //rimetto a default se ha resettato e chiuso
      this.active = false;
      this.selected = [0, 0];
    }
  }

  // helpers functions

  public makeItActive() {
    this.active = !this.active;
  }

  public timestampToDate(ts: number) {
    return this.$dayjs(ts * 1000).format("DD/MM/YYYY");
  }

  public dateFrom() {
    return this.timestampToDate(this.countDaysInDate[0]);
  }

  public dateTo() {
    return this.timestampToDate(this.countDaysInDate[1]);
  }

  public closeModal() {
    this.modal.visible = false;
  }
}
