
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class Empty extends Vue {
  @Prop() error!: any;

  public title = "Problema con le credenziali";

  mounted() {
    if (this.error.statusCode === 404) {
      this.title = "Pagina non trovata";
    } else if (this.error.statusCode === 500) {
      this.title = " Ops.... Ci scusiamo per il disagio....";
    }
  }

  head() {
    return {
      title: this.$store.getters["tenant/siteName"] + " | " + this.title,
    };
  }
}
