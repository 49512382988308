
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { debounce } from "@/helpers/debounce";
import { BIcon, BIconThreeDots } from "bootstrap-vue";
import { GenreCollection } from "@/types/Filters";

@Component({ components: { BIcon, BIconThreeDots } })
export default class TheText extends Vue {
  @Prop() query!: any;
  @Prop() items!: any;
  @Prop() genre!: string;
  @Prop({ default: "px-2 bblr-0 btlr-0" }) componentClass!: string;
  @Prop({ default: false }) isModal: boolean;
  public dropdownIsVisible: boolean = false;
  public debouncing: boolean = false;

  printPlaceholder() {
    const genres = new GenreCollection();
    return genres.getPlaceholder(this.genre);
  }

  public debouncer: any = debounce((e: any) => {
    if (e !== "" && e.length > 2) {
      this.dropdownIsVisible = true;
    }
    if (e === "" || e.length > 2) {
      this.$emit("query-text-has-changed", e);
    }
    this.setFalse();
  }, 500);

  setTrue() {
    this.debouncing = true;
  }

  setFalse() {
    this.debouncing = false;
  }

  public focusIn() {
    this.dropdownIsVisible = true;
    this.isDropdownVisible();
  }

  mounted() {
    if (this.isModal && window.innerWidth <= 768) {
      document.body.addEventListener("click", this.unfixTheSearchText);
    }
  }
  beforeDestroy() {
    if (this.isModal && window.innerWidth <= 768) {
      document.body.removeEventListener("click", this.unfixTheSearchText);
    }
  }

  public unfixTheSearchText(event: any) {
    if ((<HTMLElement>event?.target).id === "modal-search-text-input") {
      this.$emit("mobile-is-typing", true);
    } else {
      this.$emit("mobile-is-typing", false);
    }
  }

  public hideDropdown() {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
    this.dropdownIsVisible = false;
    this.isDropdownVisible();
  }

  public isDropdownVisible() {
    return this.query?.length > 2 && this.dropdownIsVisible && !this.isModal;
  }
}
