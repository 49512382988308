import { render, staticRenderFns } from "./show.vue?vue&type=template&id=4333b761"
import script from "./show.vue?vue&type=script&lang=ts"
export * from "./show.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,ResultsCardButtonShareModal: require('/usr/src/nuxt-app/components/results/card/ButtonShareModal.vue').default,ElementsModalShare: require('/usr/src/nuxt-app/components/elements/ModalShare.vue').default})
