
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import axios from "axios";

@Component({
  middleware: ["default"],
})
export default class ServiziPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("staticpage/currentPage") data: any;
  @Getter("staticpage/mainImage") mainImage: Array<any>;
  @Getter("tenant/currentToken") token: string;
  @Getter("tenant/completeDomain") domain: string;

  public lightBoxMedia: any = [];
  public toggleLightBoxMedia: boolean = false;

  public startAt: number = 0;

  public head(): any {
    return {
      title: this.data.label + " | " + this.siteName,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.data.label,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  fetchOnServer() {
    return true;
  }

  async fetch() {
    try {
      const { data } = await axios.get<any>(this.$config.astaEndpoint + "servizi", {
        headers: { Authorization: "Bearer " + this.token },
      });

      await this.$store.commit("staticpage/setCurrentPage", data.data);
    } catch (e) {
      this.$errorHandler(e);
    }
  }

  openGallery() {
    this.lightBoxMedia = this.data.gallery.map((item: any) => {
      return { src: item["1024"], type: "image" };
    });
    this.openLightBoxMedia();
  }

  public openLightBoxMedia() {
    this.toggleLightBoxMedia = true;
  }
  public onLightboxClosed() {
    this.toggleLightBoxMedia = false;
  }
}
