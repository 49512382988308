
import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";

@Component
export default class OnlyOne extends Vue {
  @Prop() gallery!: Array<any>;
  public fallBackGallery: Array<any> = [];
  public aspect: string | null = null;

  @Watch("gallery")
  onGalleryChange() {
    if (this.gallery.length === 0) {
      this.fallBackGallery = [{ "1024": "https://library.astagiudiziaria.com/tappi/tappo.png", video: null }];
    } else {
      this.fallBackGallery = this.gallery;
    }
  }

  mounted() {
    if (process.client) {
      this.aspect = window.innerWidth > 768 ? "16:9" : null;
    }
  }
  toggleGalleryModal() {
    if (this.gallery.length) {
      this.$emit("toggle-gallery-modal", 0);
    }
  }
}
