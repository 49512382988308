
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { Genre } from "@/types/Genre";
import { GenreCollection } from "~/types/Filters";

@Component
export default class TheBreadbrumbs extends Vue {
  @Prop() genreValue!: string;
  @Prop() title!: any;
  public genre = {} as Genre;
  public genres = new GenreCollection().defaultGenres;

  created() {
    //@ts-ignore
    this.genre = this.genres.find((g) => g.value === this.genreValue);
  }
}
