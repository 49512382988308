
import { Component, Vue, Prop } from "nuxt-property-decorator";
@Component
export default class TheDescription extends Vue {
  @Prop({ default: "" }) text: string;
  public toggleHeightActive: boolean = false;
  public readMoreVisibility: boolean = false;
  public toggleHeight() {
    this.toggleHeightActive = !this.toggleHeightActive;
  }

  mounted() {
    if (process.client) {
      const description = document?.getElementById("description");
      if (description !== null && description.clientHeight > 105) {
        this.readMoreVisibility = true;
        this.toggleHeightActive = true;
      }
    }
  }
}
