
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIconImages } from "bootstrap-vue";

@Component({
  components: {
    BIconImages,
  },
})
export default class TheMedia extends Vue {
  @Prop() item!: any;
}
