
import { Component, Prop } from "nuxt-property-decorator";
import Extractors from "@/helpers/validations/extractors";

@Component
export default class InputWithValidation extends Extractors {
  @Prop() value!: any;
  @Prop() privacy!: any;
  @Prop({ default: "accettazione_privacy" }) fieldName!: string;
}
