
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";
import { debounce } from "@/helpers/debounce";
import { BIcon, BIconHeart, BIconHeartFill } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconHeart,
    BIconHeartFill,
  },
})
export default class ButtonFavourite extends Vue {
  @Prop() id!: string;
  @Prop({ default: "text-white" }) componentClass!: string;
  @Getter("wishlist/getWishlistToString") preferred!: Array<string>;

  public isLoading: boolean = false;

  public debouncer: any = debounce((e: any) => {
    const queryBit = "action=addToWishlist&id=" + this.id;
    const url = new URL(window.location.href);
    let href = url.origin + url.pathname + decodeURI(url.search);

    const obj = {
      id: this.id,
      status: e,
      redirect: window.location.href.includes("?") ? href + "&" + queryBit : href + "?" + queryBit,
    };

    if (!this.$auth.loggedIn) {
      this.$emit("toggle-login-modal", obj);
      return;
    }
    this.toggleLoading();

    setTimeout(() => {
      this.toggleLoading();
      this.$emit("toggle-favourite", obj);
    }, 750);
  }, 250);

  public toggleLoading() {
    this.isLoading = !this.isLoading;
  }
}
