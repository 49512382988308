import { render, staticRenderFns } from "./LuogoVendita.vue?vue&type=template&id=0acfaa68"
import script from "./LuogoVendita.vue?vue&type=script&lang=ts"
export * from "./LuogoVendita.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowTabsDettaglioVenditaPrintOrX: require('/usr/src/nuxt-app/components/show/tabs/dettaglioVendita/PrintOrX.vue').default})
