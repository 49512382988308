
import { Component, Prop, Vue, Watch } from "nuxt-property-decorator";
import { SummaryFilterLabel } from "@/types/Filters";
import { BIcon, BIconXCircle } from "bootstrap-vue";

@Component({ components: { BIcon, BIconXCircle } })
export default class TheFilterSummary extends Vue {
  @Prop() filters!: any;
  @Prop() filtersCount!: number;
  @Prop() query!: string;
  @Prop({ default: false }) removable: boolean;
  @Prop({ default: true }) showSave: boolean;

  public activeFilters: Array<any> = [];
  public summaryFilterLabels: any = new SummaryFilterLabel();

  @Watch("$route.query", { immediate: false })
  redoActiveFilters() {
    this.doActiveFilters({ ...this.filters });
  }

  mounted() {
    this.doActiveFilters({ ...this.filters });
  }

  public doActiveFilters(filters: any) {
    this.activeFilters = Object.keys(filters)
      .map((item: any) => {
        return filters[item]?.length > 0
          ? {
              filter: item,
              values: this.summaryFilterLabels.getValue(item, filters[item]),
              label: this.summaryFilterLabels.getLabel(item),
            }
          : null;
      })
      .filter((item: any) => item?.label !== undefined && item?.values?.length > 0);

    // Special per il campo di testo query
    if (this.query) {
      this.activeFilters.push({
        filter: "query",
        values: this.summaryFilterLabels.getValue("query", this.query),
        label: this.summaryFilterLabels.getLabel("query"),
      });
    }
  }

  public removeFilter(str: string) {
    if (this.removable) {
      this.$emit("remove-filter", this.summaryFilterLabels.getFilter(str).reset_mutation);
    }
  }
}
