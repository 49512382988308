
import { Component, Vue } from "nuxt-property-decorator";

@Component
export default class TheDocuments extends Vue {
  public items: Array<any> = [];
  public fields: Array<any> = [
    { key: "url", label: "Azione" },
    { key: "title", label: "Descrizione" },
  ];

  mounted() {
    this.$fetch();
  }
  async fetch() {
    const { data } = await this.$axios.$get(this.$config.astaEndpoint + "reserved/attachments");
    this.items = data;
  }
}
