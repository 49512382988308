
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";

@Component
export default class IvgPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/isAsta") isAsta: boolean;

  public head() {
    return {
      title: "Iscrizione Newsletter | " + this.siteName,
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Registrati alla newsletter per poter essere costantemente aggiornato sulle iniziative e sulle speciali occasioni proposte dell'Istituto Vendite",
        },
      ],
    };
  }
}
