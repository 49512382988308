import Vuex from "vuex";
import Vue from "vue";
import { Store } from "vuex";
import { Context } from "@nuxt/types";
import { config } from "vuex-module-decorators";

Vue.use(Vuex);
config.rawError = true;
export const store = new Vuex.Store({});

export const actions = {
  async nuxtServerInit(store: Store<any>, context: Context) {
    await store.dispatch("typesense/fetchTSKey", context);
    // @ts-ignore
    store.dispatch("tenant/getToken", context.req.hostname);
    // @ts-ignore
    store.dispatch("tenant/getDomain", context.req.hostname);
    // @ts-ignore
    store.dispatch("tenant/getMain", context.req.hostname);
    await store.dispatch("tenant/getAvailableGenre", context);
    // await store.dispatch("tenant/getMainMenu", context);

    await store.dispatch("preset/updateGenres");
    // @ts-ignore
    await store.dispatch("preset/updateStyle", context.req.hostname);

    await store.dispatch("tenant/fetchData", context);
    await store.dispatch("tenant/getSeoData", context);

    await store.dispatch("wishlist/getFavouritesFromAsta", context);

    store.dispatch("filter/setFilterVisibileSu");
    store.dispatch("filter/setIvgPayload");
    store.dispatch("filter/makeFilterMutation", context);

    if ("page" in context.route.query) {
      await store.dispatch("paginator/initSetPage", context.route.query.page ?? 1);
    }
  },
};
