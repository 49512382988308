import { render, staticRenderFns } from "./BeneImmobile.vue?vue&type=template&id=69a1853c"
import script from "./BeneImmobile.vue?vue&type=script&lang=ts"
export * from "./BeneImmobile.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowTabsDettaglioPvpGoodsImmobiliDatiCatastali: require('/usr/src/nuxt-app/components/show/tabs/dettaglioPvp/goods/immobili/DatiCatastali.vue').default,ShowTabsDettaglioPvpGoodsImmobiliDatiDenuncia: require('/usr/src/nuxt-app/components/show/tabs/dettaglioPvp/goods/immobili/DatiDenuncia.vue').default})
