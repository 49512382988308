
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { BIcon, BIconChevronLeft } from "bootstrap-vue";
import { ResponseNewsDetail, DataNewsDetail } from "~/types/responses/news/ResponseNewsDetailInterface";
import axios from "axios";

@Component({
  components: {
    BIcon,
    BIconChevronLeft,
  },
})
export default class ShowNewsPage extends SearchMainComponent {
  public news = {} as DataNewsDetail;
  public mainImage: string | null = null;
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/currentToken") token: string;
  @Getter("tenant/completeDomain") domain: string;
  @Getter("tenant/completeDomain") logoSrc: string;

  public shareData = { visible: false, item: null, title: null, id: null, url: null };
  public toggleShareModal(obj: any) {
    this.shareData = { ...obj };
  }

  public head() {
    return {
      title: this.news.title?.substring(0, 50) + ".. | " + this.siteName,
      meta: [
        { hid: "description", name: "description", content: this.news.abstract },
        { hid: "og:title", property: "og:title", content: this.news.title },
        { hid: "og:description", property: "og:description", content: this.news.abstract },
        { hid: "og:url", property: "og:url", content: this.domain + this.$router.currentRoute.fullPath },
        { hid: "og:image", property: "og:image", content: this.getSocialImage() },
        { hid: "twitter:card", name: "twitter:card", content: "summary" },
        { hid: "twitter:image", name: "twitter:image", content: this.getSocialImage() },
        { hid: "twitter:title", name: "twitter:title", content: this.news.title },
        { hid: "twitter:description", name: "twitter:description", content: this.news.abstract },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  async fetch() {
    try {
      const { data } = await axios.get<ResponseNewsDetail>(
        this.$config.astaEndpoint + "news/get-by-permalink?permalink=news/" + this.$route.params.permalink,
        {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        }
      );
      this.news = data.data;
    } catch (e) {
      throw e;
      // this.$errorHandler(e);
    }
  }

  fetchOnServer() {
    return true;
  }

  mounted() {
    this.mainImage = this.getMainImage();
  }

  public getSocialImage() {
    if (!this.news?.mainImage) return this.logoSrc;
    return this.news?.mainImage["1024"];
  }

  public getMainImage() {
    if (!this.news?.mainImage) return null;
    if (process.client) {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        return this.news?.mainImage["512"];
      }
      return this.news?.mainImage["1024"];
    }
    return this.news?.mainImage["512"];
  }
}
