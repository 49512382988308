
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class TheHasGaraOnline extends Vue {
  @Prop() value!: any;

  public selected: boolean = false;

  mounted() {
    this.selected = this.value.hasGaraOnline[0] ? true : false;
  }

  emitUpdate(e: any) {
    this.$emit("set-value", !e ? [] : [true]);
  }
}
