import { Vue, Component } from "nuxt-property-decorator";
//@ts-ignore
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { Validations } from "vuelidate-property-decorators";
//@ts-ignore
import { required, email, helpers, minLength } from "vuelidate/lib/validators";

const atLeastOneIsUpper = helpers.regex("atLeastOneIsUpper", /(?=.*[A-Z])/);
const atLeastOneIsNumeric = helpers.regex("atLeastOneIsNumeric", /(?=.*[0-9])/);
const atLeastOneSpecialChar = helpers.regex("atLeastOneSpecialChar", /(?=.*[-\#\$\.\%\&\@\!\+\=\<\>\*])/);

@Component({})
export default class SignInValidator extends Vue {
  public errors: Array<string> = [];

  // definisco le regole di validazione
  @Validations()
  validations = {
    formLogin: {
      email: { required, email },
      password: {
        required,
        atLeastOneIsUpper,
        atLeastOneIsNumeric,
        atLeastOneSpecialChar,
        minLength: minLength(8),
      },
    },
  };
  // rendo input reactive
  public validateState(name: string) {
    //@ts-ignore
    const { $dirty, $error } = this.$v.formLogin[name];
    return $dirty ? !$error : null;
  }
}
