import { isEmpty } from "@/helpers/isEmpty";

export default async function (context: any): Promise<void> {
  const { store, route } = context;

  if (process.client) {
    if (!isEmpty(route.query)) {
      store.commit("filter/resetFilterMutation", store.getters["tenant/ivgId"]);
      store.commit("filter/commitIvgPayload", store.getters["tenant/ivgPayload"]);
      store.commit("filter/setFilterMutation", route.query);
      // controllo per i paramentri che hanno un default
      if (route.query?.filter?.status) {
        store.commit("filter/setStatusFilter", route.query.filter.status);
      }
      if (route.query?.filter?.visibile_su) {
        store.commit("filter/setVisibileSuFilter", route.query.filter.visibile_su);
      }
    }
  }
}
