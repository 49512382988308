
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class TheModalFooter extends Vue {
  @Prop() totale_preview!: number;
  @Prop() multiSearchError!: boolean;
  @Prop({ default: false }) hideReset: boolean;
  @Prop({ default: "" }) style_genre: string;
  @Prop({ default: false }) searchMapIsOpen: boolean;

  public emitClear() {
    this.$emit("reset-filter-has-been-clicked");
  }

  public searchAndTrigger() {
    this.$emit("search-has-been-clicked");
  }
}
