export default class FormWorkWithUs {
  nome: string = "";
  cognome: string = "";
  email: string = "";
  telefono: string = "";
  partita_iva: string = "";
  codice_fiscale: string = "";
  in_qualita_di: string = "";
  accettazione_privacy: boolean = false;
  recaptcha?: string = "";
}
