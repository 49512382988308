import { Vue, Component } from "nuxt-property-decorator";
//@ts-ignore
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  sameAs,
  helpers,
  minLength, //@ts-ignore
} from "vuelidate/lib/validators";

const atLeastOneIsUpper = helpers.regex("atLeastOneIsUpper", /(?=.*[A-Z])/);
const atLeastOneIsNumeric = helpers.regex("atLeastOneIsNumeric", /(?=.*[0-9])/);
const atLeastOneIsASymbol = helpers.regex("atLeastOneIsASymbol", /(?=.*[!@#$%^&*])/);

@Component({})
export default class SignUpValidator extends Vue {
  public errors: Array<string> = [];

  // definisco le regole di validazione
  @Validations()
  validations = {
    formNewPassword: {
      password: {
        required,
        atLeastOneIsUpper,
        atLeastOneIsNumeric,
        atLeastOneIsASymbol,
        minLength: minLength(8),
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  };
  // rendo input feedback reactive
  public validateState(name: string) {
    //@ts-ignore
    const { $dirty, $error } = this.$v.formNewPassword[name];
    return $dirty ? !$error : null;
  }
}
