import { render, staticRenderFns } from "./reset.vue?vue&type=template&id=c4222df8"
import script from "./reset.vue?vue&type=script&lang=ts"
export * from "./reset.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthNewPassword: require('/usr/src/nuxt-app/components/auth/NewPassword.vue').default,AuthTheWrapper: require('/usr/src/nuxt-app/components/auth/TheWrapper.vue').default})
