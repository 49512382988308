
import { Component, Prop } from "nuxt-property-decorator";
import { BIconGeoFill } from "bootstrap-vue";
import TheWrapper from "~/components/elements/lightbox/TheWrapper.vue";

@Component({
  components: {
    BIconGeoFill,
  },
})
export default class TheTour extends TheWrapper {
  @Prop() tours!: any;

  openTourGallery() {
    this.lightBoxMedia = [
      this.tours.map((iframeSrc: string) => {
        return {
          type: "iframe",
          src: iframeSrc,
        };
      }),
    ];
    this.openLightBoxMedia();
  }
}
