import { Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "./index";

@Module({
  name: "layout",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class LayoutStore extends VuexModule {
  public isScrolled: boolean = false;
  public position: number = 0;
  public searchWrapperHeight: number = 0;
  public headerHeight: number = 112;
  public searchBarHeight: number = 69;
  public filterSummaryHeight: number = 0;
  public cardSummaryHeight: number = 55;
  public openSearchMap: boolean = false;

  @Mutation
  setScrolled() {
    this.isScrolled = true;
  }

  @Mutation
  setNotScrolled() {
    this.isScrolled = false;
  }

  @Mutation
  setPosition(value: number) {
    this.position = value;
  }

  @Mutation
  setSearchWrapperHeight(value: number) {
    this.searchWrapperHeight = value;
  }

  @Mutation
  setFilterSummaryHeight(value: number) {
    this.filterSummaryHeight = value;
  }

  @Mutation
  setCardSummaryHeight(value: number) {
    this.cardSummaryHeight = value;
  }

  @Mutation
  setSearchBar(value: number) {
    this.searchBarHeight = value;
  }

  @Mutation
  setHeaderHeight(value: number) {
    this.headerHeight = value;
  }

  @Mutation
  setOpenSearchMap(value: boolean) {
    this.openSearchMap = value;
  }

  public get isScroll() {
    return this.isScrolled;
  }

  public get offset() {
    return this.position;
  }

  public get searchWrapper() {
    return this.searchWrapperHeight;
  }

  public get header() {
    return this.headerHeight;
  }

  public get searchBar() {
    return this.searchBarHeight;
  }

  public get filterSummary() {
    return this.filterSummaryHeight;
  }

  public get cardSummary() {
    return this.cardSummaryHeight;
  }

  public get topSearchBarHeight() {
    return this.searchBarHeight + this.headerHeight + this.filterSummaryHeight + this.cardSummaryHeight;
  }

  public get topSearchBarNoSummaryHeight() {
    return 69 + this.headerHeight;
  }

  public get searchMapIsOpen() {
    return this.openSearchMap;
  }
}
