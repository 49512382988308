
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";

@Component
export default class ButtonShareModal extends Vue {
  @Prop({ default: false }) sticky: boolean;
  @Prop() item!: any;
  @Prop() title!: string;
  @Prop() id!: string;
  @Prop() url!: string;
  @Prop({ default: "text-white" }) componentClass!: string;
  @Getter("tenant/completeDomain") domain!: string;

  public toggleShareModal() {
    const obj = {
      visible: true,
      item: this.item,
      title: this.title,
      id: this.id,
      url: this.url === undefined ? this.domain + "/" + this.item.permalink : this.domain + this.url,
    };
    this.$emit("toggle-share-modal", obj);
  }
}
