
import { Component, Getter, Watch } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { InserzioneResponse, InserzioneData } from "@/types/responses/inserzione/InserzioneInterface";
import { MetaData, Banner } from "@/types/components";
import meta from "@/helpers/ld-json/default";
import { LoginModalValue } from "@/types/components";
import axios from "axios";

@Component
export default class ShowPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/currentDomain") domain: string;
  @Getter("tenant/currentToken") token: string;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/additionalBanners") banners: Array<Banner>;
  @Getter("tenant/logoSrc") logoSrc: string;
  @Getter("modal/modalInserzioneMap") mapModalVisible: boolean;

  public banner!: Banner | undefined;
  public item = null as InserzioneData | null;
  public meta = null as MetaData | null;

  public tabDocumenti: boolean = false;
  public mapSrc: string = "";

  public loginModalData: LoginModalValue = {
    model: false,
    title: "Autenticazione richiesta",
    text: "Registrati o esegui il login poter salvare la tue inserzioni preferite",
    icon: "heart",
    payload: null,
  };
  public itemToFavourite: any = {};
  public shareData = { visible: false, item: null, title: null, id: null, url: null };

  public closeModalAndSetFavourite() {
    this.loginModalData.model = false;
    this.toggleFavourite(this.itemToFavourite);
  }

  public toggleLoginModal(event: any) {
    this.loginModalData.model = !this.loginModalData.model;
    this.loginModalData.payload = {
      type: "saveWishlist",
      id: event.id,
      redirectUri: event.redirect,
    };
    this.itemToFavourite = event;
  }

  public toggleShareModal(obj: any) {
    this.shareData = { ...obj };
  }

  changeSeeAllDocument(value: boolean) {
    this.tabDocumenti = value;
  }

  public openSafariModal(e: string) {
    this.$store.commit("modal/openInserzioneMapModal");
    this.mapSrc = e;
  }

  public head() {
    let logo = this.isAsta ? this.$config.cdnEndpoint + "loghi/default.svg" : this.logoSrc;
    return meta(
      this.siteName + " | " + this.meta?.title, // title
      this.meta?.description, // description
      this.meta?.image, // image per la ogImage
      this.domain, // domain usato per costruire il canonical e url
      this.$route.params.permalink, // slug usata per costruire il canonical e url
      logo, // logo per le ld-json
      this.meta?.item // item per le ld-json
    );
  }

  public mounted() {
    this.$scrollOntop();
  }

  @Watch("item", { deep: false, immediate: false })
  async triggerGTagEvent() {
    if (this.item && process.client) {
      try {
        await axios.get<any>(this.$config.astaEndpoint + "inserzioni/L64nAGFQTv/" + this.item.id, {
          headers: { Authorization: "Bearer " + this.token },
        });
      } catch (e: any) {
        this.$errorHandler(e);
      }
    }
  }

  fetchOnServer() {
    return true;
  }

  async fetch() {
    try {
      const { data } = await axios.get<InserzioneResponse>(
        this.$config.astaEndpoint + "inserzioni/get-inserzione-by-permalink?permalink=inserzioni/" + this.$route.params.permalink,
        {
          headers: { Authorization: "Bearer " + this.token },
        }
      );
      await this.$store.dispatch("preset/updateCurrentGenre", data.data.main.genere);
      this.$store.commit("filter/setGenreFilter", [data.data.main.genere]);

      this.item = data.data;

      this.meta = {
        title: this.item?.main?.titolo?.value ? this.item.main.titolo.value?.substring(0, 65) : "",
        description: this.item?.main?.descrizioneLotto?.value
          ? this.item?.main?.descrizioneLotto?.value?.substring(0, 200)?.replace(/<[^>]*>?/gm, "")
          : "",
        image: this.item?.mainImage ? this.item?.mainImage["1024"] : "",
        item: data.data,
      };
    } catch (e: any) {
      if (e.response?.status == 301) {
        this.$nuxt.context.redirect({ path: "/" + e.response?.data.data.redirectsTo });
      } else {
        this.$errorHandler(e);
      }
    }
  }
}
