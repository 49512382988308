import { Component } from "nuxt-property-decorator";
import ResponseHandler from "~/helpers/validations/ResponseHandler";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  sameAs, // @ts-ignore
} from "vuelidate/lib/validators";

@Component({})
export default class BookingValidator extends ResponseHandler {
  @Validations()
  validations = {
    form: {
      nome: { required },
      cognome: { required },
      ragione_sociale: { required },
      tipo_persona: { required },
      indirizzo: { required },
      civico: { required },
      cap: { required },
      citta: { required },
      provincia: { required },
      telefono: { required },
      fax: {},
      email: { required, email },
      accettazione_privacy: {
        sameAs: sameAs(() => true),
      },
    },
  };
}
