import { render, staticRenderFns } from "./servizi.vue?vue&type=template&id=4018ab76&scoped=true"
import script from "./servizi.vue?vue&type=script&lang=ts"
export * from "./servizi.vue?vue&type=script&lang=ts"
import style0 from "./servizi.vue?vue&type=style&index=0&id=4018ab76&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4018ab76",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,StaticTheBreadcrumbs: require('/usr/src/nuxt-app/components/static/TheBreadcrumbs.vue').default,ShowGalleryImageWrapper: require('/usr/src/nuxt-app/components/show/gallery/ImageWrapper.vue').default,ElementsMyLightBox: require('/usr/src/nuxt-app/components/elements/MyLightBox.vue').default})
