
import { Vue, Component, Getter } from "nuxt-property-decorator";

@Component({ layout: "empty", middleware: "default" })
export default class ActivatePage extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  public token: string = this.$router.currentRoute.params?.token;
  public error!: string;
  public loading: boolean = true;

  mounted() {
    setTimeout(async () => {
      try {
        await this.$axios.post(this.$config.astaEndpoint + "myAsta/user-activation", { token: this.token });
        window.location.href = "/myAsta/login?status=activated";
      } catch (err: any) {
        this.error = err.response.data.message;
      }
      this.loading = false;
    }, 2000);
  }

  head() {
    return {
      title: this.siteName + " | Pagina di attivazione account",
    };
  }
}
