
import { Component, Vue, Prop } from "nuxt-property-decorator";
//@ts-ignore
import Swiper from "@/node_modules/swiper/swiper-bundle.min";
import "swiper/swiper-bundle.min.css";
import { BIcon, BIconCameraFill, BIconShare } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconCameraFill,
    BIconShare,
  },
})
export default class SwiperInserzione extends Vue {
  @Prop() item!: any;
  @Prop() images!: Array<any>;

  @Prop({ default: true }) cardWithSwiper: boolean;
  @Prop() clickdisabled!: boolean;

  public swiper: any;
  public card_images: Array<any> = [];
  public current: number = 1;

  public visibilityChanged(event: boolean) {
    if (event) {
      this.init();
    }
  }

  async init() {
    this.swiper = await new Swiper(this.$refs.swiper, {
      // Accendo il lazy settando di caricare solo l'immagine successiva
      lazy: {
        loadPrevNext: true,
        checkInView: true,
        enable: true,
        loadOnTransitionStart: true,
        loadPrevNextAmount: 1,
      },
      slidesPerView: 1,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      preloadImages: false,
      observer: true,
      observeParents: true,
      observeSlideChildren: true,
      shortSwipe: false,
      on: {
        paginationUpdate: this.update,
      },
    });

    this.swiper.lazy.load();
  }

  /**
   * Avanti
   */
  prev() {
    this.swiper.slidePrev();
  }

  /**
   * Indietro
   */
  next() {
    this.swiper.slideNext();
  }

  /**
   * Metodo di aggiornamento paginatore
   */
  update(event: any) {
    // Setto l'indice corrente
    this.current = event.realIndex + 1;
  }

  public goToDetail(item: any) {
    if (!this.clickdisabled) {
      this.$emit("is-loading");
      this.$emit("card-clicked", this.current - 1);
    }
  }
}
