
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { ResponseIvgChiSiamo, DataChiSiamo } from "@/types/responses/chisiamo/ResponseChiSiamoInterface";
import { ClientMap } from "~/types/responses/inserzione/ResponseInserzioneInterface";
import axios from "axios";
import { isEmpty } from "~/helpers/isEmpty";

@Component
export default class IvgPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/sedi") sedi: Array<any>;
  @Getter("tenant/currentToken") token: string;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/clientMap") clientMap: ClientMap;
  @Getter("tenant/seo") seo: any;
  @Getter("tenant/completeDomain") domain: string;

  public data = {} as DataChiSiamo;
  public ivg_short_name = "";

  public head() {
    // se non sei asta, usa il seo chi siamo dell'IVG
    return {
      title: this.isAsta ? "IVG " + this.data.name + " | " + this.siteName : this.seo?.chisiamo?.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.isAsta ? "IVG " + this.data.name + ". " + this.siteName : this.seo?.chisiamo?.description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.isAsta ? "IVG " + this.data.name + " | " + this.siteName : this.seo?.chisiamo?.title,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.isAsta ? "IVG " + this.data.name + ". " + this.siteName : this.seo?.chisiamo?.description,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  // in client side costruisci l'ivg_short_name per la ricerca in typesense
  async mounted() {
    try {
      const { data } = await this.$axios.$get<any>(this.$config.astaEndpoint + "IVG/dropdown-list");
      const ivg = data.filter((i: any) => i.id === Number(this.$route.query.ivg));
      if (ivg.length > 0) this.ivg_short_name = ivg[0].description;
    } catch (e) {
      this.$errorHandler(e);
    }
  }

  async fetch() {
    try {
      let apiUrl = this.$config.astaEndpoint + "IVG/chi-siamo";
      if (this.isAsta && isEmpty(this.$route.query)) {
        // sei asta e non hai query, redirect alla pagina chi siamo
        this.$nuxt.context.redirect("/chi-siamo");
      } else if (this.isAsta) {
        // sei asta e hai query, usa la query per ottenere l'IVG
        apiUrl = this.$route.query.ivg ? apiUrl + "?ivg=" + this.$route.query.ivg : apiUrl;
      } else {
        // non sei asta, usa il token per ottenere l'IVG
        apiUrl = this.$config.astaEndpoint + "IVG/chi-siamo";
      }

      const { data } = await axios.get<ResponseIvgChiSiamo>(apiUrl, {
        headers: {
          Authorization: "Bearer " + this.token,
        },
      });
      this.data = data.data;
    } catch (e) {
      this.$errorHandler(e);
    }
  }

  fetchOnServer() {
    return true;
  }

  async searchAndGo() {
    this.$store.commit("filter/resetFilterMutation", this.$route.query.ivg);
    this.$store.commit("filter/setIvgFilter", [this.ivg_short_name]);
    this.$store.commit("filter/commitIvgPayload", this.$store.getters["tenant/ivgPayload"]);
    await this.$store.dispatch("filter/setQueryParams");
    this.$store.commit("typesense/clearResults");
    await this.$store.dispatch("typesense/getData");
  }
}
