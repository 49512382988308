import ChiSiamo from "@/pages/statics/chi-siamo.vue";
import Home from "@/pages/home.vue";
import Inserzione from "@/pages/inserzione.vue";
import Ivg from "@/pages/statics/ivg.vue";
import Error from "@/pages/statics/error.vue";
import Newsletter from "@/pages/statics/newsletter.vue";
import NewsletterActivate from "@/pages/statics/newsletter-activate.vue";
import NewsletterUnsubscribe from "@/pages/statics/newsletter-unsubscribe.vue";

export let MainRoutes = [
  {
    name: "error",
    path: "/error",
    component: Error,
  },
  {
    name: "404",
    path: "/404",
    component: Error,
  },
  {
    name: "home",
    path: "/",
    component: Home,
  },
  {
    name: "chisiamo",
    path: "/chi-siamo",
    component: ChiSiamo,
  },
  {
    name: "ivg",
    path: "/ivg",
    component: Ivg,
  },

  {
    name: "newsletter",
    path: "/newsletter-subscription",
    component: Newsletter,
  },

  {
    name: "newsletter-activate",
    path: "/newsletter-confirm/:token",
    component: NewsletterActivate,
  },

  {
    name: "inserzione",
    path: "/inserzioni/:permalink",
    component: Inserzione,
  },

  {
    name: "newsletter-unsubscribe",
    path: "/newsletter-unsubscribe",
    component: NewsletterUnsubscribe,
  },
];
