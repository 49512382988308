
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";
import { ClientMap } from "@/types/responses/inserzione/ResponseInserzioneInterface";
import { BIcon, BIconColumns, BIconRulers } from "bootstrap-vue";

@Component({ components: { BIcon, BIconColumns, BIconRulers } })
export default class TheLotto extends Vue {
  @Prop() data!: any;
  @Prop() clientMap!: ClientMap;
  @Prop() loadMapLazly!: boolean;
  @Getter("preset/currentGenre") currentGenre!: string;
}
