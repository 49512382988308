import Platform from "@/types/domains/platform";

export interface MobileRD {
  name: string | undefined;
  domain: string;
}

export function mobileJson(data: MobileRD) {
  return {
    script: [
      {
        hid: "schema-breadcrumb",
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            { "@type": "ListItem", position: 1, item: { "@id": new Platform(data.domain).getDomain(), name: data.domain } },
            {
              "@type": "ListItem",
              position: 2,
              item: { "@id": new Platform(data.domain).getDomain() + "/ricerca/mobili", name: "Mobili" },
            },
            { "@type": "ListItem", position: 3, name: data.name },
          ],
        },
      },
    ],
  };
}
