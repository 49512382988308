
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BIcon, BIconEmojiFrown } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconEmojiFrown,
  },
})
export default class TheAlert extends Vue {
  @Prop() type!: string;
}
