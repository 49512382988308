
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { StoricoAste } from "@/types/components";
import "chartist/dist/index.css";
import { LineChart } from "chartist";

@Component
export default class TheChart extends Vue {
  @Prop() storicoAste!: Array<StoricoAste>;

  clear(n: string): string {
    n = n.slice(0, -3);
    return n.split(".").join("");
  }

  mounted() {
    let labels = [];
    let data = [];
    for (let item of this.storicoAste) {
      labels.push(item.dataOraVendita);
      data.push(parseInt(this.clear(item.prezzoValoreBase)));
    }
    new LineChart(
      "#chart",
      {
        labels: labels,
        series: [data],
      },
      {
        low: 0,
        showArea: true,
        fullWidth: true,
        axisX: {
          labelOffset: {
            y: 0,
            x: -50,
          },
        },
      }
    );
  }
}
