
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { Term } from "@/types/components";

@Component
export default class TheCategory extends Vue {
  @Prop() value!: any;
  @Prop() options!: Array<Term>;
  @Prop() componentClass!: string;
  @Prop({ default: false }) isloading: boolean;

  public showCheckbox: boolean = false;

  mounted() {
    this.showCheckbox = this.$store.getters["filter/genre"] !== "default";
  }

  emitUpdate(e: any) {
    this.$emit("set-value", e);
  }
}
