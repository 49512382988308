import Vue from "vue";

Vue.filter("toCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("it-IT", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return "€ " + formatter.format(value);
});

Vue.filter("toIntCurrency", function (value) {
  if (typeof value !== "number") {
    return value;
  }
  var formatter = new Intl.NumberFormat("it-IT", {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
  return "€ " + formatter.format(value);
});
