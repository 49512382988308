
import { Component, Getter, Watch } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { ResponseBollettini } from "~/types/responses/bollettini/ResponseBollettiniInterface";
import { BIcon, BIconXLg } from "bootstrap-vue";

@Component({
  middleware: ["default"],
  components: {
    BIcon,
    BIconXLg,
  },
})
export default class IndexPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/isAsta") isAsta: boolean;
  @Getter("tenant/seo") seo: any;
  @Getter("tenant/completeDomain") domain: string;

  public value: any = {};
  public options: Array<any> = [];
  public bollettini!: any;
  public data: any = {};
  public pagination: any = {};
  public visibleFilter: boolean = false;

  public head() {
    return {
      title: this.seo.bollettini.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.bollettini.description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.seo.bollettini.description,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.seo.bollettini.description,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  public openFilter() {
    if (this.value?.id) {
      this.value = {};
      this.filter(this.value);
      this.$fetch();
    } else {
      this.visibleFilter = true;
    }
  }

  public closeFilter() {
    this.visibleFilter = false;
  }

  public selectedIvg(value: any) {
    this.value = value;
    this.filter(value);
  }

  // preparo la pagina server side
  async fetch() {
    await this.fetchBollettini();
  }

  fetchOnServer() {
    return false;
  }

  // preparo le props per il multiselect
  async mounted() {
    if (this.isAsta) {
      let ivg = this.getIvg();
      await this.$axios.get(this.$config.astaEndpoint + "IVG/dropdown-list").then((res) => {
        this.options = res.data.data;
        this.value = this.options.filter((e) => {
          return ivg.includes(e.id.toString());
        });
      });
    }
  }

  async fetchBollettini() {
    try {
      const { data } = await this.$axios.post(this.$config.astaEndpoint + "IVG/bollettini/search?page=" + this.$route.query.page, {
        type: this.getType(),
        ivg: this.$route.query.ivg,
      });

      this.data = (data as ResponseBollettini).data;
      this.pagination = (data as ResponseBollettini).meta;
      if (process.client) {
        window.scrollTo(0, 0);
      }
    } catch (e) {
      this.$errorHandler(e);
    }
  }

  public getIvg(): Array<any> {
    if (Array.isArray(this.$route.query.ivg)) {
      return this.$route.query.ivg;
    }
    return [];
  }

  public getType(): string {
    if (this.$route.query.type) {
      return String(this.$route.query.type);
    }

    if (this.$route.name === "Bollettini Immobiliari") {
      return "I";
    }

    if (this.$route.name === "Bollettini Mobiliari") {
      return "M";
    }

    if (this.$route.name === "Avvisi e Provvedimenti") {
      return "A";
    }
    return "";
  }

  @Watch("$route.query", { immediate: false })
  onQueryChange() {
    this.fetchBollettini();
  }

  // filtro per i valore del multiselect
  filter(value: any) {
    this.$store.$router.push({ path: this.$route.path, query: value.id ? { ivg: [value.id] } : {} });
  }

  // aggiorno l'URL e prendo i risultati
  changePage(page: number) {
    let obj: any = { page: page, ivg: this.getIvg() };
    this.$store.$router.push({ path: this.$route.path, query: obj });
  }
}
