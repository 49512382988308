import { render, staticRenderFns } from "./TheWishlist.vue?vue&type=template&id=3991169a"
import script from "./TheWishlist.vue?vue&type=script&lang=ts"
export * from "./TheWishlist.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonTheCard: require('/usr/src/nuxt-app/components/skeleton/TheCard.vue').default,CoreErrorsAlertAction: require('/usr/src/nuxt-app/components/core/errors/AlertAction.vue').default,ResultsTheCard: require('/usr/src/nuxt-app/components/results/TheCard.vue').default,ElementsModalShare: require('/usr/src/nuxt-app/components/elements/ModalShare.vue').default})
