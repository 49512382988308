import { render, staticRenderFns } from "./BasePrice.vue?vue&type=template&id=670beae5&scoped=true"
import script from "./BasePrice.vue?vue&type=script&lang=ts"
export * from "./BasePrice.vue?vue&type=script&lang=ts"
import style0 from "./BasePrice.vue?vue&type=style&index=0&id=670beae5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "670beae5",
  null
  
)

export default component.exports