import { Vue, Component } from "nuxt-property-decorator";
//@ts-ignore
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { Validations } from "vuelidate-property-decorators";
//@ts-ignore
import { required, email } from "vuelidate/lib/validators";

@Component({})
export default class SignInValidator extends Vue {
  public errors: Array<string> = [];

  // definisco le regole di validazione
  @Validations()
  validations = {
    formRequestPassword: {
      email: { required, email },
    },
  };
  // rendo input reactive
  public validateState(name: string) {
    //@ts-ignore
    const { $dirty, $error } = this.$v.formRequestPassword[name];
    return $dirty ? !$error : null;
  }
}
