import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "./index";
import { $axios } from "~/utils/api";

@Module({
  name: "staticpage",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class StaticPageStore extends VuexModule {
  public current_page: any = {};

  @Mutation
  setCurrentPage(page: any) {
    this.current_page = page;
  }

  @Action
  async getStaticPage(pageUrl: string) {
    try {
      const res = await $axios.$get(pageUrl);
      if (res?.data) {
        await this.context.commit("setCurrentPage", res.data);
      }
    } catch (error: any) {
      throw error;
    }
  }

  public get currentPage() {
    return this.current_page;
  }

  public get mainImage() {
    // if (!this.current_page.hasOwnProperty("gallery")) {
    //   return [];
    // }
    // if (this.current_page.gallery.length) {
    //   return this.current_page.gallery;
    // }
    return [];
  }
}
