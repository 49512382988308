
import { Vue, Component, Prop } from "nuxt-property-decorator";

@Component
export default class TheLoginSlot extends Vue {
  @Prop({ default: false }) public justActivated!: boolean;
  @Prop({ default: {} }) public privacy!: any;

  public isLogin: boolean = true;
  public isSignup: boolean = false;
  public isRequestNewPassword: boolean = false;

  public toggle(component: string) {
    this.isLogin = component === "isLogin" ? !this.isLogin : false;
    this.isSignup = component === "isSignup" ? !this.isSignup : false;
    this.isRequestNewPassword = component === "isRequestNewPassword" ? !this.isRequestNewPassword : false;
  }

  mounted() {
    if (this.$router.currentRoute?.hash && this.$router.currentRoute?.hash.length < 100) {
      this.toggle(this.$router.currentRoute?.hash.replace("#", ""));
    }
  }
}
