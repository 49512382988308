
import { Component } from "nuxt-property-decorator";
import RequestPasswordValidator from "@/helpers/validations/requestpassword";
import FormRequestPassword from "~/types/auth/FormRequestPassword";
import { BIcon, BIconArrowClockwise } from "bootstrap-vue";

@Component({ components: { BIcon, BIconArrowClockwise } })
export default class RequestNewPassword extends RequestPasswordValidator {
  public formRequestPassword: FormRequestPassword = new FormRequestPassword();
  public inError: boolean = false;
  public success: boolean = false;
  public errors: Array<string> = [];
  public loading: boolean = false;

  async mounted() {
    try {
      await this.$recaptcha.init();
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
    }
  }

  public async onRequestNewPassword() {
    this.inError = false;
    this.loading = true;

    this.$v.formRequestPassword.$touch();

    if (this.$v.formRequestPassword.$anyError) {
      this.loading = false;
      return;
    }
    const token = await this.$recaptcha.execute("requestPassword");
    const data = { ...this.formRequestPassword, recaptcha: token };

    try {
      await this.$axios.post(this.$config.astaEndpoint + "myAsta/request-reset", data);
      this.success = true;
    } catch (err: any) {
      console.error(err);
    }

    this.formRequestPassword = new FormRequestPassword();

    this.$v.$reset();
    this.loading = false;
  }
}
