
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BIcon, BIconEmojiFrown } from "bootstrap-vue";

@Component({ components: { BIcon, BIconEmojiFrown } })
export default class AlertAction extends Vue {
  @Prop({ default: false }) show: boolean;
  @Prop({ default: false }) call_to_action: boolean;
  @Prop({ default: "" }) message: string;
  @Prop({ default: "" }) action_message: string;

  public reload() {
    this.$emit("reload");
  }
}
