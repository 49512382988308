
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { Genre } from "@/types/Genre";
import { BIcon, BIconHouse, BIconWatch, BIconWallet2, BIconBuilding, BIconThreeDots } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconHouse,
    BIconWatch,
    BIconWallet2,
    BIconBuilding,
    BIconThreeDots,
  },
})
export default class TheGenre extends Vue {
  @Prop() componentClass!: string;
  @Prop() value!: any;

  public genres: Array<Genre> = [];

  mounted() {
    this.genres = this.$store.getters["preset/allGenres"];
  }
  selected(e: string) {
    this.$emit("emitChange", [e]);
  }
}
