import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Genre } from "@/types/Genre";
import { GenreCollection } from "~/types/Filters";
import Platform from "@/types/domains/platform";
import { store } from "./index";

@Module({
  name: "preset",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class PresetStore extends VuexModule {
  public genres: Array<Genre> = new GenreCollection().defaultGenres;
  public current_genre: string = "default";

  @Mutation
  setCurrentGenre(value: string) {
    this.current_genre = value === "valoricrediti" || value === "VALORI/CREDITI" ? "crediti" : value.toLowerCase();
  }

  @Mutation
  setGenres(value: string[]) {
    this.genres = new GenreCollection(value).defaultGenres;
  }

  @Action({ commit: "setCurrentGenre" })
  updateCurrentGenre(value?: string) {
    if (!this.context.rootGetters["tenant/isAsta"]) {
      value = "ivg";
    }

    if (value === "valoricrediti" || value === "VALORI/CREDITI") {
      value = "crediti";
    }
    return value?.toLowerCase();
  }

  @Action({ commit: "setCurrentGenre" })
  updateStyle(value: string) {
    if (!new Platform(value).isAsta) {
      return "ivg";
    }
    return "default";
  }

  @Action({ commit: "setGenres" })
  async updateGenres() {
    return this.context.rootGetters["tenant/genres"];
  }

  public get currentGenre(): string {
    return this.current_genre;
  }

  public get style(): string {
    return this.current_genre;
  }

  public get allGenres(): Array<Genre> {
    return this.genres;
  }
}
