
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { ClientMap } from "~/types/responses/inserzione/ResponseInserzioneInterface";

@Component
export default class TheMap extends Vue {
  @Prop() loadMapLazly!: boolean;
  @Prop() dataMap!: any;
  @Prop() clientMap!: ClientMap;
  @Prop({ default: 15 }) zoom!: number;
  @Prop() mapType!: string;

  public modalShow: boolean = false;
  public currentMapIsStreetView: boolean = false;

  toggleModal() {
    this.modalShow = !this.modalShow;
  }

  openSafariModal(src: string) {
    this.$emit("open-safari-modal", src);
  }
}
