
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconXLg, BIconHeart, BIconShare } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconHeart,
    BIconShare,
    BIconXLg,
  },
})
export default class TheAsta extends Vue {
  @Prop() item!: any;
  @Prop({ default: false }) sticky: boolean;
  public openContactModal: boolean = false;

  public showContactForm() {
    return !this.item.tabs?.pvp && this.item.tabs?.info_vendita?.contattaIvg;
  }
}
