import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c95ea50e&scoped=true"
import script from "./index.vue?vue&type=script&lang=ts"
export * from "./index.vue?vue&type=script&lang=ts"
import style0 from "./index.vue?vue&type=style&index=0&id=c95ea50e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c95ea50e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,StaticTheMoreDetailsBtn: require('/usr/src/nuxt-app/components/static/TheMoreDetailsBtn.vue').default})
