import { render, staticRenderFns } from "./SearchWrapper.vue?vue&type=template&id=4593e1cf"
import script from "./SearchWrapper.vue?vue&type=script&lang=ts"
export * from "./SearchWrapper.vue?vue&type=script&lang=ts"
import style0 from "./SearchWrapper.vue?vue&type=style&index=0&id=4593e1cf&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchTheModal: require('/usr/src/nuxt-app/components/core/search/TheModal.vue').default,CoreSearchTheModalFooter: require('/usr/src/nuxt-app/components/core/search/TheModalFooter.vue').default,CoreSearchTheFilterSummary: require('/usr/src/nuxt-app/components/core/search/TheFilterSummary.vue').default})
