
import { Component, Vue, Prop, Watch } from "nuxt-property-decorator";

@Component
export default class FormResponse extends Vue {
  @Prop() errors: string[];
  @Prop() success: boolean;
  @Prop({ default: "Grazie per averci contattato. Riceverai una risposta a breve." }) message!: string;
  @Prop({ default: "Form inviata correttamente" }) successTitle!: string;
  @Prop({ default: "180px" }) height!: string;

  @Watch("success", { immediate: true, deep: false })
  onWatchSuccess(value: boolean) {
    if (!value) return;
    this.$swal({
      html:
        `<div class="d-flex flex-column align-items-center justify-content-center" style="height:${this.height}"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/success.svg" width="60">` +
        `<h4 class="mb-2">${this.successTitle}</h4>` +
        `<p class="mt-1 mb-0"><small>${this.message}</small></p></div>`,
      showCloseButton: true,
      showCancelButton: false,
      confirmButtonText: "Ok",
    });
  }
}
