
import { Component, Prop, Vue } from "nuxt-property-decorator";

interface FacetItem {
  value: string;
  count: number;
  highlighted: string;
}

@Component
export default class TheTextDropdown extends Vue {
  @Prop() items!: Array<FacetItem>;
  @Prop() query!: string;
  @Prop() dropdownIsVisible!: boolean;

  sendQueryToParents(item: any, query: string) {
    this.$store.commit("filter/resetFilterMutation", this.$store.getters["tenant/ivgId"]);
    this.$store.commit("filter/commitIvgPayload", this.$store.getters["tenant/ivgPayload"]);
    this.$emit("set-array-of-filter-value", [
      { filter: "filter/setQueryText", value: query },
      { filter: "filter/setCategoryFilter", value: item ? Array(item.value) : [] },
    ]);
    this.$emit("search-has-been-clicked");
  }

  mounted() {
    if (process.client) {
      document.body.addEventListener("click", this.closeDropdownSuggestions);
    }
  }

  beforeDestroy() {
    if (process.client) {
      document.body.removeEventListener("click", this.closeDropdownSuggestions);
    }
  }

  public closeDropdownSuggestions(event: any) {
    if ((<HTMLElement>event?.target).id === "search-text-addon") {
      return;
    }
    this.$emit("hide-dropdown");
  }

  hasOneActive(items: HTMLCollection) {
    for (let i = 0; i < items.length; i++) {
      if (items[i].classList.contains("active")) {
        return true;
      }
    }
    return false;
  }
}
