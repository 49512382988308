
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import { ResponseNewsInterface, NewsModel } from "~/types/responses/news/ResponseNewsInterface";
import { BIcon, BIconChevronRight } from "bootstrap-vue";

@Component({ components: { BIcon, BIconChevronRight } })
export default class IndexNews extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("tenant/completeDomain") domain: string;
  @Getter("tenant/seo") seo: any;

  public news: Array<NewsModel> = [];
  public skeleton: Array<any> = [0, 1, 2, 3, 4, 5, 6, 7];

  public head() {
    return {
      title: this.seo.news.title,
      meta: [
        {
          hid: "description",
          name: "description",
          content: this.seo.news.description,
        },
        {
          hid: "og:title",
          property: "og:title",
          content: this.seo.news.description,
        },
        {
          hid: "og:description",
          property: "og:description",
          content: this.seo.news.description,
        },
      ],
      link: [
        {
          hid: "canonical",
          rel: "canonical",
          href: this.domain + this.$router.currentRoute.fullPath,
        },
      ],
    };
  }

  fetchOnServer() {
    return false;
  }

  public getPage(): string {
    if (typeof this.$route.query.page === "string") {
      return this.$route.query.page;
    }
    return "1";
  }

  async fetch() {
    try {
      const { data } = await this.$axios.get(this.$config.astaEndpoint + "news/get/20?page=" + this.getPage());

      this.news = (data as ResponseNewsInterface).data;
      this.newsItems();
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      throw e;
    }
  }

  public newsItems() {
    let arr = [];
    for (const [key, value] of Object.entries(this.news)) {
      arr.push(value);
    }
    return arr;
  }

  public bg(index: number) {
    const bgs = ["bg-default", "bg-mobili", "bg-immobili", "bg-crediti", "bg-aziende", "bg-secondary", "bg-altro"];
    return bgs[index % 6];
  }

  public maxLength(str: string, max: number) {
    if (str.length < 100) {
      return str;
    }
    return str.slice(0, max) + " ...";
  }
}
