export default function (context: any): void {
  const { store, route } = context;
  if (route.name === "index") {
    store.dispatch("preset/updateCurrentGenre", "default");
    store.commit("filter/setGenreFilter", []);
  } else {
    store.dispatch("preset/updateCurrentGenre", route.name);
    store.commit("filter/setGenreFilter", [route.name.toUpperCase()]);
  }
}
