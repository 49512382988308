
import { Vue, Component, Getter } from "nuxt-property-decorator";

@Component({ layout: "empty", middleware: "default" })
export default class ActivateNewsletterPage extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  public token: string = this.$router.currentRoute.params?.token;
  public error: string = "";
  public loading: boolean = true;

  mounted() {
    setTimeout(async () => {
      try {
        await this.$axios.post(this.$config.astaEndpoint + "newsletter/activate", { token: this.token });
        this.loading = false;
      } catch (err: any) {
        this.error = err.response.data.message;
        this.loading = false;
      }
    }, 500);
  }

  head() {
    return {
      title: "Pagina di attivazione newsletter | " + this.siteName,
    };
  }
}
