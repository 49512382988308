
import { Vue, Component, Getter } from "nuxt-property-decorator";

@Component({ layout: "empty", middleware: "default" })
export default class UnsubscribeNewsletter extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  public loading: boolean = true;
  public errors: string[] = [];
  public message: string = "";
  public success: boolean = false;

  mounted() {
    const token = this.$router.currentRoute.query?.token;

    setTimeout(async () => {
      try {
        const response = await this.$axios.post(this.$config.astaEndpoint + "newsletter/unsubscribe", { token });
        this.loading = false;
        this.success = true;
        this.message = response.data.message;
      } catch (e: any) {
        this.message = e.response.data.errors.token[0];
        this.loading = false;
      }
    }, 500);
  }
}
