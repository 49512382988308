
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconChevronRight } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconChevronRight,
  },
})
export default class TheMoreDetailsBtn extends Vue {
  @Prop({ default: "#" }) href: string;
  public effect: boolean = false;
  public toggleHover(bool: boolean) {
    this.effect = bool;
  }
}
