import { render, staticRenderFns } from "./TheLotto.vue?vue&type=template&id=1035d234&scoped=true"
import script from "./TheLotto.vue?vue&type=script&lang=ts"
export * from "./TheLotto.vue?vue&type=script&lang=ts"
import style0 from "./TheLotto.vue?vue&type=style&index=0&id=1035d234&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1035d234",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowDetailsTheAttachmentBtn: require('/usr/src/nuxt-app/components/show/details/TheAttachmentBtn.vue').default,ShowTabsDettaglioLottoTheMap: require('/usr/src/nuxt-app/components/show/tabs/dettaglioLotto/TheMap.vue').default})
