
import { Component, Prop, Vue, Watch, Getter } from "nuxt-property-decorator";

@Component
export default class TheBanned extends Vue {
  @Prop() value!: any;
  @Getter("filter/getStatus") status!: any;
  public bandite = ["Estinto", "In aggiornamento", "Non aggiudicato", "Sospeso", "Venduto"];
  public invendita = ["In vendita"];
  public selected: boolean = false;

  mounted() {
    this.selected = JSON.stringify(this.value.status) === JSON.stringify(this.bandite);
  }
  @Watch("status")
  statusLikeBandite(newVal: string[]) {
    const status = [...newVal];
    this.selected = JSON.stringify(status.sort()) === JSON.stringify(this.bandite);
  }

  emitUpdate(e: boolean) {
    this.$emit("set-array-of-filter-value", [
      { filter: "filter/setStatusFilter", value: !e ? this.invendita : this.bandite },
      { filter: "filter/setLatestSearch", value: [] },
    ]);
  }
}
