
import { Component, Prop, Vue } from "nuxt-property-decorator";

@Component
export default class TheRange extends Vue {
  @Prop() value!: any;
  @Prop() options!: number[];
  @Prop() title!: string;
  @Prop() icon!: string;
  @Prop({ default: false }) isPrice!: boolean;
  @Prop({ default: false }) unit!: boolean | string;

  public modalStatus: boolean = false;

  public update(value: any) {
    this.$emit("update", value);
  }

  public reset() {
    this.$emit("reset");
  }

  public openModal() {
    this.$emit("get-options");
    this.modalStatus = true;
  }

  public closeModal() {
    this.modalStatus = false;
  }

  public btnText() {
    if (this.value?.length) {
      if (this.isPrice) {
        const f = new Intl.NumberFormat("it-IT", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });
        if (!isNaN(Number(this.value?.[0])) && !isNaN(Number(this.value?.[1]))) {
          return "€ " + f.format(this.value[0]) + " - € " + f.format(this.value[1]);
        } else {
          if (this.value[0] === ">") {
            return "Più di € " + f.format(this.value[1]);
          }
          return "Meno di € " + f.format(this.value[1]);
        }
      }
      if (typeof this.value?.[0] === "number" && typeof this.value?.[1] === "number") {
        return this.value[0] + " - " + this.value[1] + " " + this.unit;
      } else {
        if (this.value[0] === ">") {
          return "Più di " + this.value[1] + " " + this.unit;
        }
        return "Meno di " + this.value[1] + " " + this.unit;
      }
    }

    return this.title;
  }
}
