
import { Component, Vue, Prop, Getter } from "nuxt-property-decorator";
import { ClientMap, Item } from "@/types/responses/inserzione/ResponseInserzioneInterface";

@Component
export default class DettaglioLotto extends Vue {
  @Prop() item!: Item;
  @Prop() loadMapLazly!: boolean;
  @Getter("tenant/clientMap") clientMap: ClientMap;
}
