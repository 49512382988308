import { Vue, Component } from "nuxt-property-decorator";
//@ts-ignore
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

@Component
export default class ResponseHandler extends Vue {
  public errors: string[] = [];
  public success: boolean = false;

  public setErrors(error: any) {
    if (Array.isArray(error)) {
      this.errors = [...error[0]];
    } else if (typeof error === "string") {
      this.errors = [error];
    } else {
      for (const [key, value] of Object.entries(error)) {
        if (Array.isArray(value)) {
          this.errors.push(value[0]);
        }
      }
    }
  }

  public resetErrors() {
    this.errors = [];
  }
}
