
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { StoricoAste } from "@/types/components";

@Component
export default class TheTable extends Vue {
  @Prop() storicoAste!: Array<StoricoAste>;
  public items: Array<any> = [];

  mounted() {
    this.items = this.storicoAste.map((r: StoricoAste) => {
      return {
        Data: r.dataOraVendita,
        Stato: r.status,
        Prezzo: r.prezzoValoreBase,
        permalink: r.isMe
          ? `<span class="text-success font-weight-bolder">Corrente</span>`
          : r.permalink
          ? `<a target="_BLANK" class="text-reset" href="/${r.permalink}"><u>Vedi Scheda</u></a>`
          : null,
      };
    });
  }
}
