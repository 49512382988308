import Platform from "@/types/domains/platform";

export interface CarRD {
  name: string | undefined;
  logo: string;
  description: string | undefined;
  image: string | undefined;
  domain: string;
  price: number;
}

export function carJson(data: CarRD) {
  return {
    script: [
      {
        hid: "schema-car",
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org",
          "@type": "Organization",
          url: new Platform(data.domain).getDomain(),
          logo: data.logo,
          makesOffer: {
            "@type": "Offer",
            priceSpecification: {
              "@type": "UnitPriceSpecification",
              priceCurrency: "EUR",
              price: data.price,
            },
            itemOffered: {
              "@type": "Car",
              name: data.name,
              description: data.description,
              image: data.image,
            },
          },
        },
      },
      {
        hid: "schema-breadcrumb",
        type: "application/ld+json",
        json: {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            { "@type": "ListItem", position: 1, item: { "@id": new Platform(data.domain).getDomain(), name: data.domain } },
            {
              "@type": "ListItem",
              position: 2,
              item: { "@id": new Platform(data.domain).getDomain() + "/ricerca/mobili", name: "Mobili" },
            },
            { "@type": "ListItem", position: 3, name: data.name },
          ],
        },
      },
    ],
  };
}
