
import { Component, Vue } from "nuxt-property-decorator";
import { BIcon, BIconChevronLeft } from "bootstrap-vue";

@Component({
  components: {
    BIcon,
    BIconChevronLeft,
  },
})
export default class TheBreadcrumbs extends Vue {}
