import { Component, Vue } from "nuxt-property-decorator";

@Component
export default class SearchMainComponent extends Vue {
  /**
   * Al mount scarico i dati di preview
   */
  public mounted() {}

  /**
   * Metodo Fetch di Nuxt
   */
  public async fetch() {}

  /**
   * Setto il fetch on server a true
   */
  fetchOnServer() {
    return false;
  }

  public async toggleFavourite(obj: any) {
    await this.$store.dispatch("wishlist/changeFavourite", obj.id);

    this.$swal({
      html:
        '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/success.svg" width="60">' +
        `<h4 class="mb-2">Preferiti aggiornati correttamente</h4>` +
        '<p class="mt-1 mb-0"><small>Inserzione ' +
        (obj.status ? "rimossa dalle " : "aggiunta alle ") +
        "preferite</small></p></div>",
      showCloseButton: true,
      showCancelButton: false,
      confirmButtonText: "Ok",
      timer: 3000,
    });
  }

  /**
   * Resetto i filtri
   */
  public async reset() {
    // Pulisco tutto il pulibile
    this.$store.commit("typesense/clearResults");
    this.$store.commit("paginator/resetToPage");
    this.$store.commit("filter/resetCircle");
    this.$store.commit("filter/resetPolygon");
    this.$store.commit("filter/resetMapBounds");
    this.$store.commit("filter/resetQueryParams", { route: this.$router, ivgId: this.$store.getters["tenant/ivgId"] });
    this.$store.commit("filter/commitIvgPayload", this.$store.getters["tenant/ivgPayload"]);
    await this.$fetch();
  }

  /**
   * Effettuo la ricerca
   */
  public async search(pageNumber: number) {
    this.$scrollOntop();
    // Svuoto i risultati
    this.$store.commit("typesense/clearResults");
    // Risetto Pagina 1
    this.$store.commit("paginator/resetToPage");
    // Setto il query
    await this.$store.dispatch("filter/setQueryParams");
    // // Chiudo la modale
    this.$store.commit("modal/closeModal");
  }

  public openSearchMap() {
    this.$store.commit("layout/setOpenSearchMap", true);
  }
}
