import { Vue, Component } from "nuxt-property-decorator";
//@ts-ignore
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

import { Validations } from "vuelidate-property-decorators";
//@ts-ignore
import { required } from "vuelidate/lib/validators";

@Component({})
export default class SignInReservedValidator extends Vue {
  public errors: Array<string> = [];

  // definisco le regole di validazione
  @Validations()
  validations = {
    formLogin: {
      email: { required },
      password: { required },
    },
  };
  // rendo input reactive
  public validateState(name: string) {
    //@ts-ignore
    const { $dirty, $error } = this.$v.formLogin[name];
    return $dirty ? !$error : null;
  }
}
