import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { SearchParameters } from "~/types/Filters";
import { store } from "./index";
import { $axios } from "~/utils/api";
import qs from "qs";
import Platform from "@/types/domains/platform";
import axios from "axios";

type RetryArgs = {
  e: any;
  filters?: any;
  request?: any;
};

@Module({
  name: "typesense",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class TypesenseStore extends VuexModule {
  public total_results: number = 0;
  public results: any[] = [];
  public tskey = "RecCEQAm629aTVXKnf2XHrnIKatP8u2w";
  public facets: any[] = [];
  public total_preview: number = 0;
  public preview: any[] = [];

  public isLoading: boolean = false;

  @Mutation
  setTotalPreview(value: number) {
    this.total_preview = value;
  }

  @Mutation
  setPreview(data: any[]) {
    this.preview = data;
  }

  @Mutation
  clearPreview() {
    this.preview = [];
    this.total_preview = 0;
  }

  @Mutation
  setTotal(value: number) {
    this.total_results = value;
  }

  @Mutation
  clearResults() {
    this.results = [];
    this.total_results = 0;
  }

  @Mutation
  setResults(data: any[]) {
    this.results = [...data];
  }

  @Mutation
  setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  setKey(value: string) {
    this.tskey = value;
  }

  @Action
  async fetchTSKey(context: any) {
    const token = context?.req ? new Platform(context.req.headers.host).getToken() : context.rootGetters["tenant/currentToken"];

    const { data } = await axios.get(process.env.ASTA_ENDPOINT + "typesense/search-key", {
      headers: { Authorization: "Bearer " + token },
    });

    this.context.commit("setKey", data);
    return data;
  }

  @Mutation
  setFacets(data: any) {
    this.facets = data;
  }

  @Action
  commitGetData(data: any) {
    this.context.commit("setTotal", data?.found);
    this.context.commit("setResults", data?.hits);

    this.context.dispatch(
      "filter/updateOptions",
      { category: data?.facet_counts?.find((f: any) => f.field_name === "category")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { subcategory: data?.facet_counts?.find((f: any) => f.field_name === "subcategory")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { ivg_short_name: data?.facet_counts?.find((f: any) => f.field_name === "ivg_short_name")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { controparte: data?.facet_counts?.find((f: any) => f.field_name === "controparte")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { tipo_procedura: data?.facet_counts?.find((f: any) => f.field_name === "tipo_procedura")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { province: data?.facet_counts?.find((f: any) => f.field_name === "province")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { city: data?.facet_counts?.find((f: any) => f.field_name === "city")?.counts },
      { root: true }
    );

    this.context.commit("setIsLoading", false);
    this.context.commit("paginator/setLastPage", data.page, { root: true });
  }

  @Action
  commitGetPreview(value: any) {
    const { data, lastFilterChanged } = value;
    this.context.commit("setTotalPreview", data?.results[0].found);
    this.context.commit("setPreview", data?.results[0].hits);

    this.context.dispatch(
      "filter/updateOptions",
      { category: data?.results[1].facet_counts?.find((f: any) => f.field_name === "category")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { province: data?.results[0].facet_counts?.find((f: any) => f.field_name === "province")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { city: data?.results[0].facet_counts?.find((f: any) => f.field_name === "city")?.counts },
      { root: true }
    );

    if (lastFilterChanged !== "category" && lastFilterChanged !== "subcategory") {
      this.context.dispatch(
        "filter/updateOptions",
        { subcategory: data?.results[0].facet_counts?.find((f: any) => f.field_name === "subcategory")?.counts },
        { root: true }
      );
    }

    if (this.context.rootGetters["filter/getSubcategories"].length === 0) {
      this.context.dispatch(
        "filter/updateOptions",
        { subcategory: data?.results[0].facet_counts?.find((f: any) => f.field_name === "subcategory")?.counts },
        { root: true }
      );
    }

    this.context.dispatch(
      "filter/updateOptions",
      { ivg_short_name: data?.results[0].facet_counts?.find((f: any) => f.field_name === "ivg_short_name")?.counts },
      { root: true }
    );

    this.context.dispatch(
      "filter/updateOptions",
      { controparte: data?.results[0].facet_counts?.find((f: any) => f.field_name === "controparte")?.counts },
      { root: true }
    );
    this.context.dispatch(
      "filter/updateOptions",
      { tipo_procedura: data?.results[0].facet_counts?.find((f: any) => f.field_name === "tipo_procedura")?.counts },
      { root: true }
    );

    this.context.commit("setIsLoading", false);
  }

  @Action
  async getGenreFromSubcategory() {
    const { hits } = await $axios.$get(
      `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
        (this.context.state as TypesenseStore).tskey
      }&` + qs.stringify(new SearchParameters("", "subcategory:" + this.context.rootGetters["filter/getSubcategories"][0], 1, 1))
    );

    if (hits.length) {
      await this.context.dispatch(
        "filter/setFilter",
        {
          filter: { genre: [hits[0].document.genre] },
        },
        { root: true }
      );
      return hits[0].document.genre;
    }
  }

  @Action
  async getDataWithoutGenre() {
    this.context.commit("setIsLoading", true);

    const sp = new SearchParameters(
      this.context.rootGetters["filter/getQuery"],
      "status: [" +
        this.context.rootGetters["filter/getStatus"].join(",") +
        "] && visibile_su: [" +
        this.context.rootGetters["tenant/ivgId"] +
        "]",
      1,
      1
    );

    const data = await $axios.$get(
      `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
        (this.context.state as TypesenseStore).tskey
      }&` + qs.stringify(sp)
    );
    this.context.commit("setTotalPreview", data.found);
    this.context.commit("setPreview", data.hits);

    this.context.commit("setFacets", data.facet_counts.find((f: any) => f.field_name === "category").counts);

    this.context.commit("setIsLoading", false);
  }

  @Action
  async getPreview(lastFilterChanged?: string) {
    this.context.commit("setIsLoading", true);

    let query = this.context.rootGetters["filter/getQuery"];
    let searchParams = this.context.rootGetters["filter/searchParamsFilters"];
    let searchParamsNoCategory = this.context.rootGetters["filter/searchParamsFiltersNoCategoryAndTypology"];

    const request = {
      searches: [
        {
          collection: process.env.TS_COLLECTION,
          ...new SearchParameters(query, searchParams, 1, 0),
        },
        {
          collection: process.env.TS_COLLECTION,
          ...new SearchParameters(query, searchParamsNoCategory, 1, 0),
        },
      ],
    };

    if (lastFilterChanged !== undefined) {
      lastFilterChanged = lastFilterChanged.replace("filter/", "").replace("set", "").replace("Filter", "").toLowerCase();
    }

    try {
      const data = await $axios.$post(
        `${process.env.TS_ENDPOINT}multi_search?use_cache=true&cache_ttl=300&x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }`,
        request
      );
      this.context.dispatch("commitGetPreview", { data, lastFilterChanged });
    } catch (e: any) {
      const data = await this.context.dispatch("retryWithNewKey", { e, request });
      this.context.dispatch("commitGetPreview", { data, lastFilterChanged });
    }
  }

  @Action
  async getData() {
    // if (this.context.rootGetters["paginator/currentPage"] === this.context.rootGetters["paginator/lastPageVisited"]) {
    //   return;
    // }
    this.context.commit("setIsLoading", true);

    let cache = "";
    if (!this.context.rootGetters["filter/filterCount"]) {
      cache = "&use_cache=true&cache_ttl=120";
    }

    try {
      const data = await $axios.$get(
        `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }&` +
          qs.stringify(this.context.rootGetters["filter/searchParameters"]) +
          cache
      );
      this.context.dispatch("commitGetData", data);
      this.context.dispatch("filter/updateSavedSearch", false, { root: true });
    } catch (e: any) {
      const data = await this.context.dispatch("retryWithNewKey", { e, filters: this.context.rootGetters["filter/searchParameters"] });

      this.context.dispatch("commitGetData", data);
    }
  }

  @Action
  async retryWithNewKey(obj: RetryArgs): Promise<void | any> {
    if (obj?.e?.response.status === 401) {
      await this.context.dispatch("fetchTSKey", this.context);
      if (obj?.request) {
        return await $axios.$post(
          `${process.env.TS_ENDPOINT}multi_search?x-typesense-api-key=${(this.context.state as TypesenseStore).tskey}`,
          obj.request
        );
      }

      return await $axios.$get(
        `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }&` + qs.stringify(obj.filters)
      );
    }
  }

  @Action
  async getCorrelated(obj: any) {
    try {
      const { hits } = await $axios.$get(
        `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }&${qs.stringify(obj.correlatedFilter)}`
      );
      return hits.filter((i: any) => i.document.id !== String(obj.id));
    } catch (e: any) {
      const { hits } = await this.context.dispatch("retryWithNewKey", { e, filters: obj.correlatedFilter });
      return hits.filter((i: any) => i.document.id !== String(obj.id));
    }
  }

  @Action
  async getMyFavourites(obj: any) {
    const request = {
      searches: [
        {
          collection: process.env.TS_COLLECTION,
          ...obj,
        },
      ],
    };

    try {
      const { results } = await $axios.$post(
        `${process.env.TS_ENDPOINT}multi_search?use_cache=true&cache_ttl=300&x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }`,
        request
      );
      return results[0];
    } catch (e: any) {
      return await this.context.dispatch("retryWithNewKey", { e, request });
    }
  }

  @Action
  async getLatest() {
    const filters = new SearchParameters(
      "",
      "visibile_su: [" + this.context.rootGetters["tenant/ivgId"] + "] && status: In Vendita",
      1,
      15,
      "latest_search:desc"
    );

    try {
      return await $axios.$get(
        `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }&${qs.stringify(filters)}`
      );
    } catch (e: any) {
      return await this.context.dispatch("retryWithNewKey", { e, filters });
    }
  }

  @Action
  async getCalendar(dayFilter: any) {
    try {
      return await Promise.all([this.context.dispatch("getDataInDateRange", dayFilter[0])]);
    } catch (e: any) {
      await this.context.dispatch("fetchTSKey", this.context);
      return await Promise.all([this.context.dispatch("getDataInDateRange", dayFilter[0])]);
    }
  }

  @Action
  async getDataInDateRange(day: any): Promise<any> {
    let status = "status:[" + this.context.rootGetters["filter/getStatus"].join(",") + "]";

    let filters = `visibile_su: [${this.context.rootGetters["tenant/ivgId"]}] && ${status} && data_vendita_search: [${day.from} .. ${day.to}]`;
    if (isNaN(day.to)) {
      filters = `visibile_su: [${this.context.rootGetters["tenant/ivgId"]}] && ${status} && data_vendita_search: > ${day.from}`;
    } else if (isNaN(day.from)) {
      filters = `visibile_su: [${this.context.rootGetters["tenant/ivgId"]}] && ${status} && data_vendita_search: < ${day.to}`;
    }
    const filter_by = new SearchParameters("", filters, 1, 15, "data_vendita_search:asc");

    try {
      return await $axios.$get(
        `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }&` + qs.stringify(filter_by)
      );
    } catch (e) {
      return await this.context.dispatch("retryWithNewKey", { e, filters });
    }
  }

  @Action
  getDataInDateRangeAppenaBandite(day: any) {
    let filter_by =
      "visibile_su: [" +
      this.context.rootGetters["tenant/ivgId"] +
      "] && status: !=[In Vendita] && data_vendita_search: [" +
      day.from +
      " .. " +
      day.to +
      "]";
    const endpoint = `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
      (this.context.state as TypesenseStore).tskey
    }&`;
    return $axios.$get(endpoint + qs.stringify(new SearchParameters("", filter_by, 1, 15, "data_vendita_search:desc")));
  }

  @Action
  async getRangeOfGenre(genre: string | null) {
    let filters =
      "visibile_su: [" +
      this.context.rootGetters["tenant/ivgId"] +
      "]  && status: [" +
      this.context.rootGetters["filter/getStatus"].join(",") +
      "]";
    if (genre) {
      filters += "&& genre: [" + genre.toUpperCase() + "]";
    }

    const data = await $axios.$get(
      `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
        (this.context.state as TypesenseStore).tskey
      }&` + qs.stringify(new SearchParameters("", filters, 1, 1))
    );

    this.context.dispatch("filter/updatePriceStats", data?.facet_counts?.find((f: any) => f.field_name === "price")?.stats, {
      root: true,
    });
    // next ranges here
  }

  @Action
  async getControparte() {
    let filters = "visibile_su: [" + this.context.rootGetters["tenant/ivgId"] + "]  && status: [In Vendita]";

    const filter_by = new SearchParameters("", filters, 1, 1);
    filter_by.setFacetMaxValues(100);

    try {
      const data = await $axios.$get(
        `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
          (this.context.state as TypesenseStore).tskey
        }&` + qs.stringify(filter_by)
      );
      this.context.dispatch(
        "filter/updateOptions",
        { controparte: data?.facet_counts?.find((f: any) => f.field_name === "controparte")?.counts },
        { root: true }
      );
    } catch (e: any) {
      const data = await this.context.dispatch("retryWithNewKey", { e, filters: filter_by });
      this.context.dispatch(
        "filter/updateOptions",
        { controparte: data?.facet_counts?.find((f: any) => f.field_name === "controparte")?.counts },
        { root: true }
      );
    }
  }

  @Action
  async findGenreFromCategory(category: string): Promise<void> {
    let filter_by = "category: [" + category + "] && visibile_su: [" + this.context.rootGetters["tenant/ivgId"] + "]";
    filter_by += " && status:[" + this.context.rootGetters["filter/getStatus"].join(",") + "]";
    const filters = new SearchParameters(this.context.rootGetters["filter/getQuery"], filter_by, 1, 1);
    const { hits } = await $axios.$get(
      `${process.env.TS_ENDPOINT}collections/${process.env.TS_COLLECTION}/documents/search?x-typesense-api-key=${
        (this.context.state as TypesenseStore).tskey
      }&` + qs.stringify(filters)
    );

    if (hits.length) {
      await this.context.dispatch(
        "filter/setFilter",
        {
          filter: { genre: [hits[0].document.genre] },
        },
        { root: true }
      );
    }
  }

  public get total(): number {
    return this.total_results;
  }

  public get resultData(): Array<any> {
    return this.results;
  }

  public get totalPreview(): number {
    return this.total_preview;
  }

  public get resultPreview(): Array<any> {
    return this.preview;
  }
  public get loading(): boolean {
    return this.isLoading;
  }
  public get key(): string {
    return this.tskey;
  }

  public get getFacets(): Array<any> {
    return this.facets;
  }
}
