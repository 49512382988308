
import { Component, Prop, Getter, Vue } from "nuxt-property-decorator";
import ContactValidator from "@/helpers/validations/contact";
import FormContact from "~/types/forms/FormContact";
import { ResponseIvgChiSiamo } from "@/types/responses/chisiamo/ResponseChiSiamoInterface";
import { IvgIdentity } from "@/types/components";
import { saveConsent } from "@/types/forms/saveConsent";

@Component
export default class TheContact extends ContactValidator {
  @Prop() variant!: string;
  @Prop() itemId!: number;
  @Prop() ivgId!: number;
  @Getter("tenant/privacy") privacy!: any;
  public form: FormContact = new FormContact();
  public loading: boolean = false;
  public ivg = {} as IvgIdentity;

  async mounted() {
    this.form.iev_id = this.itemId;
    this.form.IVG_id = this.ivgId;
    try {
      await this.$recaptcha.init();
    } catch (e) {
      throw e;
    }
  }

  fetchOnServer() {
    return false;
  }

  async fetch() {
    try {
      const { data } = await this.$axios.$get<ResponseIvgChiSiamo>(this.$config.astaEndpoint + "IVG/chi-siamo?ivg=" + this.ivgId);
      this.ivg = { claim: data.claim, logo: data.logo, name: data.name, id: data.id };
    } catch (e) {
      throw e;
    }
  }

  public async onSubmit() {
    this.resetErrors();

    this.loading = true;

    this.$v.form.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const { ...data } = this.form;
    delete data.accettazione_privacy;

    try {
      const token = await this.$recaptcha.execute("richiediInfo");
      data.recaptcha = token;
    } catch (error) {
      throw error;
    }

    try {
      await this.$axios.$post(this.$config.astaEndpoint + "forms/richiediInfo", data);
      this.success = true;
      this.$emit("close-modal");
      this.resetForm();
      // invio i dati consent a iubenda
      saveConsent(
        "contact-form",
        "contact-submit",
        { first_name: "nome", last_name: "cognome", email: "email" },
        {
          legal_documents: "accettazione_privacy",
        },
        [{ identifier: "privacy_policy", version: "15/05/2018" }],
        ["telefono", "note"]
      );
    } catch (error: any) {
      console.error(error);
    }
  }

  public resetForm() {
    this.form = new FormContact();
    this.form.iev_id = this.itemId;
    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }
}
