
import { Vue, Component, Getter } from "nuxt-property-decorator";
import qs from "qs";

@Component({ layout: "empty", middleware: "default" })
export default class LoginPage extends Vue {
  @Getter("tenant/siteName") siteName!: string;
  public loading: boolean = true;
  public redirect!: any;
  public action!: any;
  public id!: any;
  public token: string | null = null;

  head() {
    return {
      title: "Attendi ... " + this.siteName,
    };
  }

  async mounted() {
    this.loading = true;

    if (this.$router.currentRoute.hash) {
      this.token = String(qs.parse(this.$router.currentRoute.hash.replace("#", "")).access_token);
      // se l'hash query contiene "state=http" vene da una modale con azione (addToWishlist o saveSearch)
      if (this.$router.currentRoute.hash.includes("state=http")) {
        // prendo il redirect dalla hashed query
        this.filterQuery();
      }
    }

    // @ts-ignore - se ha il token fa il login
    if (this.token) {
      try {
        await this.socialLogin();
      } catch (e: any) {
        if (e?.response?.status === 422) {
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
        console.error(e);
      }
    } else {
      this.$router.push({ path: "/myAsta" });
    }
  }

  // redirect dalla hashed query nei casi di google e facebook
  public filterQuery(): void {
    if (this.$router.currentRoute.params.social === "google") {
      const splitString = this.$router.currentRoute.hash.replace("#", "").split("&access_token");
      this.redirect = splitString[0].replace("state=", "");
    }
    if (this.$router.currentRoute.params.social === "facebook") {
      const splitString = this.$router.currentRoute.hash.replace("#", "").split("state=");
      this.redirect = splitString[1];
    }
  }

  public async socialLogin(): Promise<void> {
    if (!this.token) return;
    if (this.$auth.strategy.options.name === "myAsta") return;
    if (this.$auth.strategy.options.name === "reserved") return;

    // prendo il token dal social e lo invio al backend che mi risponde il suo token
    const { access_token } = await this.$axios.$post(this.$config.astaEndpoint + "login/social/" + this.$auth.strategy.options.name, {
      access_token: this.token.replace("Bearer ", ""),
    });

    // setto il token e la strategy con quella di default
    await this.$auth.setStrategy("myAsta");
    await this.$auth.setUserToken("Bearer " + access_token);
    await this.$store.dispatch("wishlist/getFavouritesFromAsta");

    // se c'è un redirect lo faccio altrimenti vado alla pagina di default dell'utente
    if (this.redirect) {
      this.completeLoginActions(this.redirect);
    } else {
      this.$router.push({ path: "/myAsta" });
    }
  }

  public async completeLoginActions(redirect: string) {
    if (this.redirect.includes("action=saveSearch")) {
      const url = new URL(this.redirect.replace("action=saveSearch", ""));
      const filters = qs.parse(url.search.replace("?", ""));
      await this.$store.dispatch("filter/makeFilterMutationUsingQuery", filters);
      await this.$store.dispatch("filter/saveSearch");

      this.$swal({
        html:
          '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/success.svg" width="60">' +
          `<h4 class="mb-2">Ricerca salvata</h4>` +
          `<p class="mt-1 mb-0"><small>Ora potrai recuperare la tua ricerca dalla pagina myAsta</small></p></div>`,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
        timer: 3000,
      });

      this.$router.push({ path: url.pathname + qs.stringify(filters, { addQueryPrefix: true }) });
    }

    if (this.redirect.includes("action=addToWishlist")) {
      const href = this.redirect.split("action=addToWishlist");
      const url = new URL(href[0]);
      const filters = qs.parse(url.search.replace("?", ""));

      await this.$store.dispatch("wishlist/changeFavourite", qs.parse(this.redirect).id);

      this.$swal({
        html:
          '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/success.svg" width="60">' +
          `<h4 class="mb-2">Preferiti aggiornati correttamente</h4>` +
          '<p class="mt-1 mb-0"><small>Inserzione aggiunta fra i preferiti</small></p></div>',
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
        timer: 3500,
      });

      this.$router.push({ path: url.pathname + qs.stringify(filters, { addQueryPrefix: true }) });
    }
  }
}
