export default class FormBooking {
  iev_id: number = 0;
  IVG_id: number = 0;
  nome: string = "";
  cognome: string = "";
  ragione_sociale: string = "";
  tipo_persona: string = "";
  indirizzo: string = "";
  civico: string = "";
  cap: string = "";
  citta: string = "";
  provincia: string = "";
  telefono: string = "";
  fax: string = "";
  email: string = "";
  accettazione_privacy?: boolean = false;
  recaptcha?: string = "";
}
