
import { Component, Prop, Getter } from "nuxt-property-decorator";
import { ResponseIvgChiSiamo } from "@/types/responses/chisiamo/ResponseChiSiamoInterface";
import BookingValidator from "@/helpers/validations/booking";
import FormBooking from "~/types/forms/FormBooking";
import { IvgIdentity } from "@/types/components";
import { saveConsent } from "@/types/forms/saveConsent";

@Component
export default class TheBooking extends BookingValidator {
  @Prop() variant!: string;
  @Prop() itemId!: number;
  @Prop() ivgId!: number;

  @Getter("tenant/privacy") privacy!: any;

  public form: FormBooking = new FormBooking();
  public loading: boolean = false;
  public selected: any = {};
  public ivg = {} as IvgIdentity;
  public options: Array<any> = [
    { text: "Persona Giuridica", value: "Persona Giuridica" },
    { text: "Persona Fisica", value: "Persona Fisica" },
  ];

  async mounted() {
    this.selected = this.options.find((o) => o.value === this.form.tipo_persona);
    this.form.iev_id = this.itemId;
    this.form.IVG_id = this.ivgId;
    try {
      await this.$recaptcha.init();
    } catch (e) {
      throw e;
    }
  }

  fetchOnServer() {
    return false;
  }

  async fetch() {
    const { data } = await this.$axios.$get<ResponseIvgChiSiamo>(this.$config.astaEndpoint + "IVG/chi-siamo?ivg=" + this.ivgId);
    this.ivg = { claim: data.claim, logo: data.logo, name: data.name, id: data.id };
  }

  public async onSubmit() {
    this.resetErrors();

    this.loading = true;

    this.$v.form.$touch();

    if (this.$v.$invalid) {
      return;
    }

    const { ...data } = this.form;

    try {
      const token = await this.$recaptcha.execute("prenotazioneVisita");
      data.recaptcha = token;
    } catch (error) {
      throw error;
    }

    try {
      await this.$axios.$post(this.$config.astaEndpoint + "forms/prenotazioneVisita", data);
      this.success = true;
      // invio i dati consent a iubenda
      saveConsent(
        "booking-form",
        "booking-submit",
        { first_name: "nome", last_name: "cognome", email: "email" },
        {
          legal_documents: "accettazione_privacy",
        },
        [{ identifier: "privacy_policy", version: "15/05/2018" }],
        ["telefono", "fax", "cap", "provincia", "citta", "civico", "indirizzo", "ragione_sociale"]
      );

      this.$emit("close-modal");
      setTimeout(() => {
        this.resetForm();
      }, 200);
    } catch (error: any) {
      console.error(error);
    }
  }

  public resetForm() {
    this.form = new FormBooking();
    this.form.iev_id = this.itemId;
    this.form.IVG_id = this.ivgId;
    setTimeout(() => {
      this.$v.$reset();
    }, 10);
  }
}
