
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class CardFooterData extends Vue {
  @Prop() item!: any;
  @Prop() iscard!: boolean;

  getDataVendita() {
    if (this.item.isOnline) {
      // ha sia inizio che fine
      if (this.item.fine_gara) {
        return { label: "DATE ASTA", item: "da " + this.item.inizio_gara.slice(0, -5) + " a " + this.item.fine_gara.slice(0, -5) };
      }
      // ha solo inizio
      if (this.item.inizio_gara) {
        return { label: "DATA ASTA", item: this.item.inizio_gara.slice(0, -5) };
      }
    }

    if (this.item.sellStartDate) {
      return { label: "DATA ASTA", item: this.item.sellStartDate };
    }

    return { label: "DATA ASTA", item: null };
  }
}
