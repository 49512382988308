import { render, staticRenderFns } from "./FiveAndMore.vue?vue&type=template&id=1ade8af3"
import script from "./FiveAndMore.vue?vue&type=script&lang=ts"
export * from "./FiveAndMore.vue?vue&type=script&lang=ts"
import style0 from "./FiveAndMore.vue?vue&type=style&index=0&id=1ade8af3&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowGalleryMultipleImageWrapper: require('/usr/src/nuxt-app/components/show/gallery/MultipleImageWrapper.vue').default,ShowGalleryImageWrapper: require('/usr/src/nuxt-app/components/show/gallery/ImageWrapper.vue').default})
