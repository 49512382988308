import { Component } from "nuxt-property-decorator";
import ResponseHandler from "~/helpers/validations/ResponseHandler";
import { Validations } from "vuelidate-property-decorators";
import {
  required,
  email,
  requiredIf,
  integer,
  sameAs,
  maxLength,
  minLength, //@ts-ignore
} from "vuelidate/lib/validators";

@Component({})
export default class WorkWithUsValidator extends ResponseHandler {
  // definisco le regole di validazione
  @Validations()
  validations = {
    form: {
      nome: { required, minLength: minLength(2) },
      cognome: { required, minLength: minLength(2) },
      //codice_fiscale: { required },
      codice_fiscale: {
        requiredIf: requiredIf(function () {
          // @ts-ignore
          return this.$v.form.partita_iva.$model === "";
        }),
        minLength: minLength(11),
        maxLength: maxLength(16),
      },
      partita_iva: {
        requiredIf: requiredIf(function () {
          // @ts-ignore
          return this.$v.form.codice_fiscale.$model === "";
        }),
        minLength: minLength(11),
        maxLength: maxLength(13),
      },
      email: { required, email },
      telefono: { required, integer },
      in_qualita_di: { required, minLength: minLength(5) },
      accettazione_privacy: {
        sameAs: sameAs(() => true),
      },
    },
  };
}
