import { render, staticRenderFns } from "./TheMedia.vue?vue&type=template&id=45914076&scoped=true"
import script from "./TheMedia.vue?vue&type=script&lang=ts"
export * from "./TheMedia.vue?vue&type=script&lang=ts"
import style0 from "./TheMedia.vue?vue&type=style&index=0&id=45914076&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45914076",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowMediaTheVideo: require('/usr/src/nuxt-app/components/show/media/TheVideo.vue').default,ShowMediaTheTour: require('/usr/src/nuxt-app/components/show/media/TheTour.vue').default,ShowMediaTheNewTour: require('/usr/src/nuxt-app/components/show/media/TheNewTour.vue').default})
