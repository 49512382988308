import { render, staticRenderFns } from "./SavedSearches.vue?vue&type=template&id=4d73f1d7&scoped=true"
import script from "./SavedSearches.vue?vue&type=script&lang=ts"
export * from "./SavedSearches.vue?vue&type=script&lang=ts"
import style0 from "./SavedSearches.vue?vue&type=style&index=0&id=4d73f1d7&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d73f1d7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkeletonMyAstaSavedSearch: require('/usr/src/nuxt-app/components/skeleton/MyAstaSavedSearch.vue').default,CoreErrorsAlertAction: require('/usr/src/nuxt-app/components/core/errors/AlertAction.vue').default,MyastaStaticFilterSummary: require('/usr/src/nuxt-app/components/myasta/StaticFilterSummary.vue').default})
