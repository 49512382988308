import { render, staticRenderFns } from "./ThePrice.vue?vue&type=template&id=401709e8"
import script from "./ThePrice.vue?vue&type=script&lang=ts"
export * from "./ThePrice.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ResultsCardPriceTheDefault: require('/usr/src/nuxt-app/components/results/card/price/TheDefault.vue').default,ResultsCardPriceFormattedPrice: require('/usr/src/nuxt-app/components/results/card/price/FormattedPrice.vue').default,ResultsCardPriceMinimumOffer: require('/usr/src/nuxt-app/components/results/card/price/MinimumOffer.vue').default})
