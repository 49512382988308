import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { store } from "./index";
import { $axios } from "~/utils/api";

@Module({
  name: "wishlist",
  store,
  dynamic: true,
  namespaced: true,
  stateFactory: true,
})
export default class WishlistStore extends VuexModule {
  public preferred: string[] = [];

  @Mutation
  toggleFavourite(id: string) {
    const preferred = [...this.preferred];
    if (this.preferred.includes(id)) {
      preferred.splice(preferred.indexOf(id), 1);
      this.preferred = [...preferred];
    } else {
      preferred.push(id);
      this.preferred = [...preferred];
    }
  }

  @Action({ commit: "toggleFavourite" })
  async changeFavourite(id: string) {
    await $axios.$post(process.env.ASTA_ENDPOINT + "myAsta/favourite/" + id, {});
    return id;
  }

  @Mutation
  setFavourites(arr: string[]) {
    this.preferred = arr;
  }

  @Action({ commit: "setFavourites" })
  getFavouritesFromAsta(ctx: any): string[] {
    if (ctx?.$auth?.$state?.loggedIn) {
      return ctx?.$auth?.$state.user.wishlist.map((num: number) => {
        return String(num);
      });
    }
    return [];
  }

  @Action({ commit: "setFavourites" })
  async getFavouritesFromUserApi(): Promise<string[]> {
    try {
      const { data } = await $axios.$get(process.env.ASTA_ENDPOINT + "myAsta/user");
      return data.wishlist.map((num: number) => {
        return String(num);
      });
    } catch (e: any) {
      console.error(e);
      return [];
    }
  }

  public get getWishlist() {
    return this.preferred;
  }
  public get getWishlistToString() {
    return this.preferred.map((num) => {
      return String(num);
    });
  }
}
