// @ts-ignore
const Collection: Array<Matcher> = process.env.TENANTS.split(",").map((el: string) => {
  return {
    name: process.env[el + "_DOMAIN"],
    token: process.env[el + "_TOKEN"],
  } as Matcher;
});

interface Matcher {
  name: string;
  token: string | undefined;
}

export default class Platform {
  domain: string;
  isAsta: boolean;
  isFallback: boolean;

  constructor(domain: string) {
    this.isFallback = false;
    this.domain = new URL(domain.startsWith("https://") ? domain : "https://" + domain).hostname;
    this.isAsta = this.isAstaCheck();
  }

  isAstaCheck() {
    if (this.domain === "astagiudiziaria.local") {
      return true;
    }
    if (this.domain === "front-v3.astagiudiziaria.com") {
      return true;
    }
    if (this.domain === "www.astagiudiziaria.com") {
      return true;
    }
    if (this.domain === "astagiudiziaria.com") {
      return true;
    }
    if (this.domain === "stage.astagiudiziaria.com") {
      return true;
    }

    return false;
  }

  setIsFallback() {
    this.isFallback = true;
  }

  getToken(): string | undefined {
    return this.findToken();
  }

  getDomain(): string {
    if (this.domain.endsWith(".local")) {
      return "http://" + this.domain + ":3000";
    }
    return "https://" + this.domain;
  }

  findToken() {
    const envHost = Collection.find((i) => {
      if (new URL(i.name).hostname === this.domain) {
        return i;
      }
    });
    if (envHost) {
      return "" + envHost.token;
    }

    return "" + process.env.ASTA_TOKEN;
  }
}
