
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";
import axios from "axios";

@Component({
  middleware: ["default"],
})
export default class AnyPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;
  @Getter("staticpage/currentPage") data: any;
  @Getter("tenant/currentToken") token: string;

  public head(): any {
    return {
      title: "Privacy Policy | " + this.siteName,
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Informativa per trattamento di dati personali per gli utilizzatori del presente sito web e cookie policy sito web " +
            this.siteName,
        },
      ],
    };
  }

  async fetch() {
    try {
      const { data } = await axios.get<any>(this.$config.astaEndpoint + "IVG/privacy-policy", {
        headers: { Authorization: "Bearer " + this.token },
      });

      await this.$store.commit("staticpage/setCurrentPage", data.data);
    } catch (e) {
      this.$errorHandler(e);
    }
  }
}
