import { Genre } from "@/types/Genre";
import dayjs from "dayjs";
export default class Filters {
  public filter: any = {
    genre: [] as string[],
    category: [] as string[],
    subcategory: [] as string[],
    status: ["In vendita"],
    inserzioneEspVendita: [] as string[],
    hasGaraOnline: [] as string[],
    price: [] as number[],
    data_vendita_search: [] as number[],
    latest_search: [] as number[],
    ivg_short_name: [] as number[],
    controparte: [] as string[],
    tipo_procedura: [] as string[],
    tags: [] as string[],
    visibile_su: [] as number[],
    id: [] as number[],
    position: "",
    province: [] as string[],
    city: [] as string[],
    ivg_number: [] as string[],
  };

  constructor(ivgId: number | null = null) {
    this.filter.visibile_su = ivgId ? [ivgId] : [];
  }

  public query: string | null = "";
  public page: number = 1;
  public rpp: number = 20;

  public sort: any = {};

  toJSON() {
    return { ...this };
  }
}

export class GenreCollection {
  public defaultGenres: Genre[] = [
    {
      text: "Immobili",
      alias: "immobili",
      value: "IMMOBILI",
      disabled: false,
      icon: "house",
      placeholder: "appartamento, terreno, villa, comune, numero PVP, numero Procedura, ecc...",
    },
    { text: "Mobili", alias: "mobili", value: "MOBILI", disabled: false, icon: "watch", placeholder: "auto bmw, divano, computer, ecc..." },
    {
      text: "Aziende",
      alias: "aziende",
      value: "AZIENDE",
      disabled: false,
      icon: "building",
      placeholder: "quota societaria, cessione d’azienda, affitto d’azienda, ecc...",
    },
    {
      text: "Crediti",
      alias: "valoricrediti",
      value: "VALORI/CREDITI",
      disabled: false,
      icon: "wallet2",
      placeholder: "titoli, quota societaria, crediti, ecc...",
    },
    { text: "Altro", alias: "altro", value: "ALTRO", disabled: false, icon: "three-dots", placeholder: "marchi, strutture, ecc..." },
  ];

  constructor(visible?: string[]) {
    this.setVisible(visible);
  }

  setVisible(visible?: string[]) {
    if (visible) {
      this.defaultGenres = this.defaultGenres.filter((g: any) => visible.includes(g.value));
    }
    return this;
  }

  getText(str: string | null | undefined) {
    if (str) {
      return this.findGenreFromAlias(str)?.text;
    }
    return "";
  }

  getAlias(str: string | null | undefined) {
    if (str) {
      return this.findGenreFromAlias(str)?.alias;
    }
    return "";
  }

  getPlaceholder(str: string | null | undefined) {
    if (str) {
      if (this.findGenreFromAlias(str)) {
        return this.findGenreFromAlias(str)?.placeholder;
      }
      return "appartamento, cucina, bmw, numero procedura, numero PVP...";
    }
    return "";
  }

  findGenreFromAlias(str: string): Genre | undefined {
    str = str.replace("/", "");
    str = str.replace("%2F", "");
    str = str.toLowerCase();
    return this.defaultGenres.find((g: any) => g.alias === str);
  }
}

export class FilterMutations {
  public data: any = [
    {
      name: "status",
      mutation: "setStatusFilter",
      reset_mutation: "resetStatusFilter",
      type: "array",
      values: ["In vendita"],
    },
    {
      name: "category",
      mutation: "setCategoryFilter",
      reset_mutation: "resetCategoryFilter",
      type: "array",
      values: [],
    },
    {
      name: "subcategory",
      mutation: "setSubcategoryFilter",
      reset_mutation: "resetSubcategoryFilter",
      type: "array",
      values: [],
    },
    {
      name: "genre",
      mutation: "setGenreFilter",
      reset_mutation: "resetGenreFilter",
      type: "array",
      values: [],
    },
  ];
}

export class FilterDictionary {
  public dictionary: Record<string, string[]> = {
    exactTerms: ["ivg_number"],
    terms: [
      "genre",
      "category",
      "subcategory",
      "status",
      "inserzioneEspVendita",
      "hasGaraOnline",
      "visibile_su",
      "id",
      "ivg_short_name",
      "controparte",
      "tipo_procedura",
      "province",
      "city",
      "tags",
    ],
    range: ["data_vendita_search", "price", "latest_search"],
    geo: ["position"],
  };
}

export const expiringInDaysOptions: any[] = [
  { text: "Qualsiasi", value: "0" },
  { text: "Domani", value: "1" },
  { text: "3 giorni", value: "3" },
  { text: "1 settimana", value: "7" },
  { text: "2 settimane", value: "14" },
  { text: "1 mese", value: "30" },
];

export const latestDaysOptions: any[] = [
  { text: "Qualsiasi", value: "0" },
  { text: "Oggi", value: "1" },
  { text: "Ieri", value: "2" },
  { text: "2 giorni", value: "3" },
  { text: "3 giorni", value: "4" },
  { text: "4 giorni", value: "5" },
  { text: "5 giorni", value: "6" },
];

export const coutableFilters = [
  "status",
  "category",
  "subcategory",
  "inserzioneEspVendita",
  "hasGaraOnline",
  "price",
  "data_vendita_search",
  "position",
  "ivg_short_name",
  "controparte",
  "tipo_procedura",
  "province",
  "city",
  "latest_search",
  "tags",
  "ivg_number",
];

export class Sorting {
  public options: any[] = [
    // { key: "title", value: "asc", label: "Titolo A - Z", icon: "sort-alpha-down" },
    // { key: "title", value: "desc", label: "Titolo Z - A", icon: "sort-alpha-down-alt" },
    { key: "latest_search", value: "asc", label: "Dal meno recente", icon: "sort-up" },
    { key: "latest_search", value: "desc", label: "Dal più recente", icon: "sort-down" },
    { key: "price", value: "asc", label: "Prezzo Basso/Alto", icon: "sort-numeric-down" },
    { key: "price", value: "desc", label: "Prezzo Alto/Basso", icon: "sort-numeric-down-alt" },
    { key: "data_vendita_search", value: "asc", label: "Scadenza Crescente", icon: "sort-up" },
    { key: "data_vendita_search", value: "desc", label: "Scadenza Descrescente", icon: "sort-down" },
  ];

  getLabel(key: string, value: string) {
    if (!key) {
      return "";
    }
    return this.options.find((o: any) => o.key === key && o.value === value)?.label;
  }
}

export class SummaryFilterLabel {
  public labels: any = [
    { name: "genre", label: "Settore", visible: false, reset_mutation: "filter/resetGenreFilter" },
    { name: "category", label: "Categoria", visible: true, reset_mutation: "filter/resetCategoryFilter" },
    { name: "subcategory", label: "Tipologia", visible: true, reset_mutation: "filter/resetSubcategoryFilter" },
    { name: "status", label: "Stato", visible: true, reset_mutation: "filter/resetStatusFilter" },
    { name: "inserzioneEspVendita", label: "Todo", visible: false },
    { name: "hasGaraOnline", label: "Gara online", visible: true, type: "boolean", reset_mutation: "filter/resetHasGaraOnlineFilter" },
    { name: "price", label: "Prezzo", visible: true, type: "price", reset_mutation: "filter/resetPrice" },
    { name: "data_vendita_search", label: "Scadenza", visible: true, reset_mutation: "filter/resetDataVenditaSearch", type: "data" },
    {
      name: "latest_search",
      label: "Aggiudicazioni delle ultime",
      visible: true,
      reset_mutation: "filter/resetLatestSearch",
      type: "data",
    },

    { name: "visibile_su", label: "Visibile Su", visible: false },
    { name: "position", label: "Ricerca su mappa", visible: true, reset_mutation: "filter/resetPosition" },
    { name: "query", label: "Ricerca su testo", visible: true, reset_mutation: "filter/resetQuery" },
    { name: "ivg_short_name", label: "Nome IVG", visible: true, reset_mutation: "filter/resetIvg" },
    { name: "controparte", label: "Controparte", visible: true, reset_mutation: "filter/resetControparte" },
    { name: "tipo_procedura", label: "Tipo procedura", visible: true, reset_mutation: "filter/resetTipoProcedura" },
    { name: "province", label: "Provincia", visible: true, reset_mutation: "filter/resetProvince" },
    { name: "city", label: "Comune", visible: true, reset_mutation: "filter/resetCity" },
    { name: "tags", label: "Tags", visible: true, reset_mutation: "filter/resetTags" },
    { name: "ivg_number", label: "Numero IVG", visible: true, reset_mutation: "filter/resetIvgNumber" },
  ];

  getLabel(str: string) {
    return this.findLabel(str)?.label;
  }

  getFilter(str: string) {
    return this.labels.find((l: any) => l.name === str && l.visible);
  }

  getValue(str: string, arr: any[] | string) {
    const label = this.findLabel(str);
    if (label?.type === "data") {
      const days = String(Math.round((arr[1] - arr[0]) / 60 / 60 / 24));
      let text = expiringInDaysOptions.find((o) => o.value === days)?.text;

      // from and to are the same date
      if (days === "1") {
        if (arr[1] > Date.now() / 1000 && arr[0] < Date.now() / 1000) {
          return ["Oggi"];
        }
        return [dayjs(arr[1] * 1000).format("DD/MM/YYYY")];
      }

      if (text) {
        return [text];
      }
      if (arr[0] === ">") {
        return ["da dopo il " + dayjs(arr[1] * 1000).format("DD/MM/YYYY")];
      } else if (arr[0] === "<") {
        return ["prima del " + dayjs(arr[1] * 1000).format("DD/MM/YYYY")];
      } else {
        return ["dal " + dayjs(arr[0] * 1000).format("DD/MM/YYYY") + " al " + dayjs(arr[1] * 1000).format("DD/MM/YYYY")];
      }
    }

    if (label?.type === "price") {
      var f = new Intl.NumberFormat("it-IT");
      if (isNaN(Number(arr[0]))) {
        if (arr[0] === ">") {
          return ["Più di € " + f.format(arr[1])];
        }
        return ["Meno di € " + f.format(arr[1])];
      }
      return ["Da € " + f.format(arr[0]) + " a € " + f.format(arr[1])];
    }

    if (label?.type === "boolean") {
      return arr ? ["Si"] : ["No"];
    }

    if (label?.name === "position") {
      return arr ? ["Attivo"] : null;
    }

    if (label?.name === "query") {
      return arr ? [arr] : null;
    }

    if (label?.name === "status") {
      if (arr.length === 1 && arr[0] === "In vendita") {
        return [];
      }
      return arr;
    }

    return arr;
  }

  findLabel(str: string) {
    return this.labels.find((l: any) => l.name === str && l.visible);
  }
}

export class SearchParameters {
  readonly query_by: string = "ivg_short_name,province,numero_procedura,category,subcategory,title,inserzioneEspVendita,tags,city";
  readonly num_typos: string = "1,1,0,1,1,1,1,1,1";
  readonly facet_by: string = "subcategory, category, price, ivg_short_name, province, controparte, tipo_procedura, city"; //city
  max_facet_values: number = 45;
  highlight_fields: string = "none";

  per_page: number = 20;
  sort_by: string = "_text_match:desc,data_vendita_search:asc";
  page: number = 1;
  q: string;
  filter_by: string;

  constructor(q: string, filter_by: string, page: number, per_page: number, sort_by: string = "", cache?: any) {
    this.q = q;
    this.filter_by = filter_by;
    this.page = page;
    this.per_page = per_page;
    if (sort_by !== "") {
      this.sort_by = sort_by;
    }
  }

  setFacetMaxValues(max_facet_values: number) {
    this.max_facet_values = max_facet_values;
  }
}
