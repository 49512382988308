import Login from "~/pages/auth/login.vue";
import Logout from "~/pages/auth/logout.vue";
import LoginReserved from "~/pages/auth/reserved.vue";
import Activate from "@/pages/auth/activate.vue";
import NewPassword from "~/pages/auth/reset.vue";
import MyAsta from "@/pages/myAsta.vue";
import SocialCallback from "@/pages/auth/social-callback.vue";

export let MyAstaRoutes = [
  {
    name: "MyAsta",
    path: "/myAsta",
    component: MyAsta,
  },
  {
    name: "loginMinistero",
    path: "/reserved/login",
    component: LoginReserved,
  },
  {
    name: "login",
    path: "/myAsta/login",
    component: Login,
  },

  {
    name: "logout",
    path: "/logout",
    component: Logout,
  },

  {
    name: "activate",
    path: "/myAsta/activate/:token",
    component: Activate,
  },
  {
    name: "reset",
    path: "/myAsta/reset/:token",
    component: NewPassword,
  },
  {
    name: "socialLogin",
    path: "/auth/:social/callback",
    component: SocialCallback,
  },
];
