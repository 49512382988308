
import { Component, Vue, Prop } from "nuxt-property-decorator";
import { BIcon, BIconXLg } from "bootstrap-vue";

@Component({ components: { BIcon, BIconXLg } })
export default class TheAsta extends Vue {
  @Prop() banners!: any;
  @Prop() item!: any;
  @Prop({ default: false }) sticky: boolean;
  public openBookingModal: boolean = false;
  public openInformazioniAstaModal: boolean = false;
  public pvpPrenotaVisitaLoading: boolean = false;

  public showBookingForm() {
    // if (this.item.tabs.hasOwnProperty("pvp")) {
    //   return false;
    // }
    return this.item?.tabs?.info_vendita?.tipoPrenotaVisita === "IVG" || this.item?.tabs?.info_vendita?.tipoPrenotaVisita === "BOTH";
  }

  public showInformazioniAsta() {
    return this.item?.tabs?.info_vendita?.linkSitoVendite;
  }

  public informazioniAstaModal() {
    this.openInformazioniAstaModal = true;
  }
}
