
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { debounce } from "@/helpers/debounce";

@Component
export default class TheIvgNumber extends Vue {
  @Prop() value!: any;
  public number: string = "";

  mounted() {
    this.number = this.value.ivg_number.length ? this.value.ivg_number[0] : "";
  }

  public debouncer: any = debounce((e: any) => {
    if (e === "" || e.length > 2) {
      this.$emit("set-value", e === "" ? [] : [e]);
    }
  }, 500);
}
