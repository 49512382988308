
import { Component, Prop } from "nuxt-property-decorator";
import { BIconGeoFill } from "bootstrap-vue";
import TheWrapper from "~/components/elements/lightbox/TheWrapper.vue";

@Component({
  components: {
    BIconGeoFill,
  },
})
export default class TheTour extends TheWrapper {
  @Prop() tours!: any;

  openTourGallery() {
    this.lightBoxMedia = this.tours.map((tour: any) => {
      let link = "";
      // se inizia con // aggiungo https:
      if (tour.link.startsWith("//")) {
        link = "https:" + tour.link;
      } else {
        link = tour.link;
      }

      return {
        type: "iframe",
        src: link,
      };
    });
    this.openLightBoxMedia();
  }
}
