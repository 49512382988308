import { render, staticRenderFns } from "./TheLoginSlot.vue?vue&type=template&id=680ba08a&scoped=true"
import script from "./TheLoginSlot.vue?vue&type=script&lang=ts"
export * from "./TheLoginSlot.vue?vue&type=script&lang=ts"
import style0 from "./TheLoginSlot.vue?vue&type=style&index=0&id=680ba08a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680ba08a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthSignIn: require('/usr/src/nuxt-app/components/auth/SignIn.vue').default,AuthSignUp: require('/usr/src/nuxt-app/components/auth/SignUp.vue').default,AuthRequestNewPassword: require('/usr/src/nuxt-app/components/auth/RequestNewPassword.vue').default,AuthSignInReserved: require('/usr/src/nuxt-app/components/auth/SignInReserved.vue').default})
