import Search from "@/pages/search.vue";

export let SearchRoutes = [
  {
    name: "index",
    path: "/ricerca",
    component: Search,
  },
  {
    name: "immobili",
    path: "/ricerca/immobili",
    component: Search,
  },
  {
    name: "mobili",
    path: "/ricerca/mobili",
    component: Search,
  },
  {
    name: "aziende",
    path: "/ricerca/aziende",
    component: Search,
  },
  {
    name: "valoricrediti",
    path: "/ricerca/valoricrediti",
    component: Search,
  },
  {
    name: "altro",
    path: "/ricerca/altro",
    component: Search,
  },
];
