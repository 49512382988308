import NewsIndex from "@/pages/news/index.vue";
import NewsShow from "@/pages/news/show.vue";

export let NewsRoutes = [
    {
        name: "news.index",
        path: "/news",
        component: NewsIndex,
    },
    {
        name: "news.show",
        path: "/news/:permalink",
        component: NewsShow,
    }
];  