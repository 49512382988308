
import { Component, Getter } from "nuxt-property-decorator";
import SearchMainComponent from "@/mixins/search";

@Component
export default class WorkWithUsPage extends SearchMainComponent {
  @Getter("tenant/siteName") siteName: string;

  public head() {
    return {
      title: "Pubblica con noi | " + this.siteName,
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "I professionisti che desiderano pubblicare inserzioni di pubblicità di vendite giudiziarie possono compilare questo form di richiesta e verranno ricontattati al più presto dallo staff di Astagiudiziaria.com",
        },
      ],
    };
  }
}
