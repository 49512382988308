
import { Vue, Component, Prop } from "nuxt-property-decorator";
import { BIconStarFill } from "bootstrap-vue";

@Component({
  components: {
    BIconStarFill,
  },
})
export default class TheWrapper extends Vue {
  @Prop({ default: "" }) public siteName!: string;
  @Prop() public isAsta!: boolean;
}
