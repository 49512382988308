
import { Component, Vue } from "nuxt-property-decorator";

import { Genre } from "@/types/Genre";
import { GenreCollection } from "@/types/Filters";
import { BIcon, BIconHouse, BIconWatch, BIconWallet2, BIconBuilding, BIconBook, BIconList, BIconPen, BIconThreeDots } from "bootstrap-vue";

interface Search {
  id: number;
  front_user_id: number;
  payload: any;
  created_at: string;
  updated_at: string;
}

@Component({ components: { BIcon, BIconHouse, BIconWatch, BIconWallet2, BIconBuilding, BIconBook, BIconList, BIconPen, BIconThreeDots } })
export default class SavedSearches extends Vue {
  public items: Array<Search> = [];
  public fields: Array<any> = [
    { key: "payload", label: "Filtri della ricerca" },
    { key: "created_at", label: "Creata il" },
    { key: "action", label: "Azioni" },
  ];

  getGenre(genre: string | undefined): Genre | undefined {
    if (genre === undefined) {
      return undefined;
    }
    return new GenreCollection().findGenreFromAlias(genre);
  }

  mounted() {
    this.$fetch();
  }
  async fetch() {
    const { data } = await this.$axios.$get(this.$config.astaEndpoint + "myAsta/ricerche");
    this.items = data;
  }

  public deleteSearch(id: string) {
    this.$swal({
      html:
        '<div class="d-flex flex-column align-items-center justify-content-center" style="height:203px"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/info.svg" width="60">' +
        `<h4 class="mb-2">Confermi di voler eliminare questa ricerca?</h4>` +
        `<p class="mt-1 mb-0"><small>Premendo "Sì elimina", non sarà piu' possibile accedere a questa ricerca</small></p></div>`,
      showDenyButton: true,
      confirmButtonText: "Sì, elimina",
      confirmButtonColor: "#226ce0",
      denyButtonText: `No`,
      denyButtonColor: "#dc3545",
      showCloseButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.$swal({
          html:
            '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4" src="https://library.astagiudiziaria.com/front/icons/success.svg" width="60">' +
            `<h4 class="mb-2">Ricerca eliminata</h4>` +
            `<p class="mt-1 mb-0"><small>La tua ricerca è stata eliminata con successo</small></p></div>`,
          showCloseButton: true,
          showCancelButton: false,
          confirmButtonText: "Ok",
        });
        await this.$axios.$delete(this.$config.astaEndpoint + "myAsta/ricerche/" + id);
        this.$fetch();
      }
    });
  }

  public async seeResults(id: string) {
    const newFilters = this.items.find((s) => s.id === parseInt(id))?.payload;
    if (!newFilters?.searchLink) {
      this.$swal({
        html:
          '<div class="d-flex flex-column align-items-center justify-content-center" style="height:180px"><img class="my-4"src="https://library.astagiudiziaria.com/front/icons/warning.svg" width="60">' +
          `<h4 class="mb-2">Errore</h4>` +
          `<p class="mt-1 mb-0"><small>Non siamo riusciti a ripristinare la ricerca salvata"</small></p></div>`,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: "Ok",
      });
    }
    const url = new URL(newFilters.searchLink);
    this.$store.commit("typesense/clearResults");
    this.$router.push({
      path: url.pathname + url.search,
    });
  }
}
