import { render, staticRenderFns } from "./reserved.vue?vue&type=template&id=66ef65b2"
import script from "./reserved.vue?vue&type=script&lang=ts"
export * from "./reserved.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AuthTheLoginSlot: require('/usr/src/nuxt-app/components/auth/TheLoginSlot.vue').default,AuthTheWrapper: require('/usr/src/nuxt-app/components/auth/TheWrapper.vue').default})
