
import { Component, Vue, Prop } from "nuxt-property-decorator";

@Component
export default class TheWishlist extends Vue {
  @Prop() wishlist!: Array<number>;
  public items: Array<any> = [];
  public shareData = { visible: false, item: null, title: null, id: null, url: null };

  public toggleShareModal(obj: any) {
    this.shareData = { ...obj };
  }

  fetchOnServer() {
    return false;
  }

  async fetch() {
    const params = {
      page: 1,
      per_page: 200,
      q: "",
      sort_by: "data_vendita_search:desc",
      filter_by: "id:= [" + this.wishlist.join(",") + "]",
      query_by: "title",
    };

    const { hits } = await this.$store.dispatch("typesense/getMyFavourites", params);

    try {
      const hitsWithFavourites = hits?.map((i: any) => {
        i.document.favourite = true;
        return i;
      });
      this.items = hitsWithFavourites || [];
    } catch (e) {
      if (e instanceof Error) {
        console.error(e);
      }
      throw e;
    }
  }

  public async toggleFavourite(id: string) {
    await this.$store.dispatch("wishlist/changeFavourite", id);
    this.items = this.items.filter((i: any) => i.document.id !== id);
  }
}
