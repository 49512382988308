import Bollettini from "@/pages/bollettini/index.vue";

export let BollettiniRoutes = [
  {
    name: "Bollettini Immobiliari",
    path: "/bollettini/immobiliari",
    component: Bollettini,
  },
  {
    name: "Avvisi e Provvedimenti",
    path: "/bollettini/avvisi-e-provvedimenti",
    component: Bollettini,
  },

  {
    name: "Bollettini Mobiliari",
    path: "/bollettini/mobiliari",
    component: Bollettini,
  },

  {
    path: "/bollettini/search",
    beforeEnter: (to, from, next) => {
      if (to.query?.title === "Bollettino Immobiliare") {
        next({ name: "Bollettini Immobiliari" });
      } else if (to.query?.title === "Bollettino Mobiliare") {
        next({ name: "Bollettini Mobiliari" });
      } else if (to.query?.title === "Avvisi e Provvedimenti") {
        next({ name: "Avvisi e Provvedimenti" });
      } else {
        next({ name: "error" });
      }
    },
  },
];
