
import { Component, Prop } from "nuxt-property-decorator";
import TheWrapper from "~/components/elements/lightbox/TheWrapper.vue";

@Component
export default class TheGallery extends TheWrapper {
  @Prop() items!: any;
  @Prop() item!: any;
  public gallery: any = [];
  public startAt: number = 0;
  public bodyClass: string = "";

  head() {
    return {
      bodyAttrs: {
        class: this.bodyClass,
      },
    };
  }

  mounted() {
    this.gallery = [...this.items.gallery];
  }

  closeModal() {
    this.toggleLightBoxMedia = false;
    this.bodyClass = "";
    this.onLightboxClosed();
  }

  openGallery(i: number = 0) {
    this.lightBoxMedia = this.gallery.map((item: any) => {
      return { src: item["1024"], type: "image" };
    });
    this.startAt = i;
    this.openLightBoxMedia();
    this.bodyClass = "overflow-hidden";
  }
}
