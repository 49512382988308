
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BIconXLg } from "bootstrap-vue";

@Component({ components: { BIconXLg } })
export default class TheDateModal extends Vue {
  @Prop() modal!: any;
  @Prop() value!: any;

  public min: Date = new Date();

  public minTo() {
    if (this.value.from === "") {
      return new Date();
    }
    if (typeof this.value.from === "string") {
      return new Date(this.value.from);
    }
    return new Date();
  }

  public minFrom() {
    if (this.value.to === "") {
      return new Date();
    }

    return new Date();
  }

  public reset() {
    this.$emit("reset");
  }

  public search() {
    if (this.value.from !== "" || this.value.to !== "") {
      this.$emit("set-filter-value", [this.$dayjs(this.value.from).startOf("day").unix(), this.$dayjs(this.value.to).endOf("day").unix()]);
    } else {
      this.$emit("close-modal");
      this.$emit("set-filter-value", []);
    }
  }

  public closeModal() {
    this.$emit("close-modal");
  }

  public check() {
    if (this.value.from === "") return;
    if (this.value.to === "") return;
    if (this.$dayjs(this.value.from).isAfter(this.$dayjs(this.value.to))) {
      this.value.to = "";
    }
  }
}
