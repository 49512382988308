import { render, staticRenderFns } from "./ivg.vue?vue&type=template&id=2a34d8bc&scoped=true"
import script from "./ivg.vue?vue&type=script&lang=ts"
export * from "./ivg.vue?vue&type=script&lang=ts"
import style0 from "./ivg.vue?vue&type=style&index=0&id=2a34d8bc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a34d8bc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CoreSearchWrapper: require('/usr/src/nuxt-app/components/core/SearchWrapper.vue').default,ChiSiamoTheMapIvg: require('/usr/src/nuxt-app/components/chi-siamo/TheMapIvg.vue').default})
