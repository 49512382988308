import axios from "axios";
import { Plugin } from "@nuxt/types";

declare module "vue/types/vue" {
  interface Vue {
    $errorHandler: any;
  }
}

declare module "@nuxt/types" {
  // nuxtContext.app.$errorHandler inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $errorHandler: any;
  }
  // nuxtContext.$errorHandler
  interface Context {
    $errorHandler: any;
  }
}

function errorHandler(error: any) {
  if (axios.isAxiosError(error)) {
    if (error.response?.status) {
      // @ts-ignore ritorno l'errore al client con il codice di errore corretto (es. 404)
      if (this.$nuxt) {
        if (error.response?.status >= 500) {
          console.error(error);
        }
        // @ts-ignore
        this.$nuxt.error({ statusCode: error.response?.status });
      }
      if (process.server) {
        return;
      }
    }
  } else if (error instanceof Error) {
    console.error(error);
    // @ts-ignore errore generico dovuto al codice (es. undefined)
    if (this.$nuxt) {
      //@ts-ignore
      this.$nuxt.error({ statusCode: 500 });
    }
  }

  // rimando l'errore al client per gestirlo (esempio: $fetch)
  throw error;
}

const errorHandlerPlugin: Plugin = (context, inject) => {
  inject("errorHandler", errorHandler);
};

export default errorHandlerPlugin;
