import { render, staticRenderFns } from "./TheTabs.vue?vue&type=template&id=50ccf01a&scoped=true"
import script from "./TheTabs.vue?vue&type=script&lang=ts"
export * from "./TheTabs.vue?vue&type=script&lang=ts"
import style0 from "./TheTabs.vue?vue&type=style&index=0&id=50ccf01a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50ccf01a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ShowTabsInformazioniVendita: require('/usr/src/nuxt-app/components/show/tabs/InformazioniVendita.vue').default,ShowTabsDettaglioLotto: require('/usr/src/nuxt-app/components/show/tabs/DettaglioLotto.vue').default,ShowTabsPvp: require('/usr/src/nuxt-app/components/show/tabs/Pvp.vue').default,ShowTabsModalitaPartecipazione: require('/usr/src/nuxt-app/components/show/tabs/ModalitaPartecipazione.vue').default,ShowTabsDocumenti: require('/usr/src/nuxt-app/components/show/tabs/Documenti.vue').default,ShowTabsDettaglioStoricoTheTable: require('/usr/src/nuxt-app/components/show/tabs/dettaglioStorico/TheTable.vue').default,ShowTabsDettaglioStoricoTheChart: require('/usr/src/nuxt-app/components/show/tabs/dettaglioStorico/TheChart.vue').default})
